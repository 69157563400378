import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import L from "leaflet";
import GISToolbarLeaflet from "./GISToolbarLeaflet";
import { useCompareAssetsL } from "../../../Features/GIS/hooks/leafletHooks/useCompareAssetsL";
import ComparerUIL from "./ComparerUIL";
import {
  defaultMarker,
  highMarker,
  lowMarker,
  mediumMarker,
} from "../../../Assets/icons/gisIcons/icons";
import MetaDataModalLeaflet from "./MetaDataModalLeaflet";
import "leaflet.markercluster";
import "Leaflet.Deflate";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { useParams } from "react-router-dom";
import hmelChainageMarkers from "../../../Data/geoData/hmel_chainage_markers.json";
import { v4 as uuidv4 } from "uuid";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import { Measure } from "../../../Utils/Leaflet/LeafletUtils";
const GISLeaflet = ({ setLoader, mapRef }) => {
  const measure = new Measure();
  const map = mapRef.current;
  const { asset, observationsBucket, observationsVisibilityStatus } =
    useSelector((state) => state.gisLeaflet);
  const dispatch = useDispatch();
  const [tileLayer, setTileLayer] = useState(null);
  const [bounds, setBounds] = useState("");
  const { showComparer } = useSelector((state) => state.gisTools);
  const { userPermissions, refCounter } = useSelector((state) => state.gis);
  const [isMetaModalOpen, setIsMetaModalOpen] = useState(false);
  const [clickedMarker, setClickedMarker] = useState(null);
  const { grp: clientName } = useParams();
  const { KmlsetArray } = useSelector((state) => state.gisLeaflet);
  // console.log(clientName.toLowerCase(), "clientNameclientName");

  // var imageryProvider = new WebMapServiceImageryProvider({
  //   url: "https://gis.indrones.com/geoserver/skyhigh/wms",
  //   layers: asset,

  //   parameters: {
  //     transparent: "true",
  //     format: "image/png",
  //   },
  // });
  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("kml_file", file);

      const response = await fetch(
        "http://192.168.0.181:8000/get-kml-coordinates",
        {
          method: "POST", // Change method to POST
          body: formData, // Set body to formData
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Error uploading file: " + error.message);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadFile(file);
    }
  };
  const getLayerById = (id) => {
    let targetLayer = null;
    map.eachLayer((layer) => {
      if (layer._leaflet_id === id) {
        targetLayer = layer;
      }
    });
    return targetLayer;
  };

  // hmel markers
  const [hmelMarkers, setHmelMarkers] = useState([]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    if (!map) return;

    const boundsChanged = debounce(() => {
      const bounds = map.getBounds();
      const southWest = bounds.getSouthWest();
      const northEast = bounds.getNorthEast();

      const markersInBounds = hmelChainageMarkers.points.filter((point) => {
        const lat = point[0];
        const lng = point[1];
        return (
          lat >= southWest.lat &&
          lat <= northEast.lat &&
          lng >= southWest.lng &&
          lng <= northEast.lng
        );
      });

      setHmelMarkers(markersInBounds);
    }, 500); // Adjust debounce delay as needed

    map.on("moveend", boundsChanged);
    return () => {
      map.off("moveend", boundsChanged);
    };
  }, [map]);

  useEffect(() => {
    if (!map || hmelMarkers.length === 0) return;

    const chainageMarkersCluster = L.markerClusterGroup().addTo(map);
    const chaingeMarkers = L.featureGroup().addTo(map);
    const zoomLevel = map.getZoom();

    if (hmelMarkers.length < 550) {
      chaingeMarkers.clearLayers();
      map.createPane("labels");
      map.getPane("labels").style.zIndex = 650;

      // here label[3]) is a string containg chainage value like 96.1 , 96.2 ....
      // zoomLevel <15 only km labels like 96 , 97 , 98
      // zoomLevel 16 show only divisible by 5 like 96 , 96.5 , 97
      // zoomLevel >16 show all
      // return label

      const zoomWiseFiltered = hmelMarkers.map((label) => {
        const chainageValue = parseFloat(label[3]);

        let shouldKeepLabel = true;

        if (zoomLevel <= 15) {
          shouldKeepLabel = chainageValue % 1 === 0;
        } else if (zoomLevel === 16 || zoomLevel === 17) {
          const integerPart = Math.floor(chainageValue);
          const fractionalPart = chainageValue - integerPart;
          shouldKeepLabel = fractionalPart === 0 || fractionalPart === 0.5;
        } else {
          shouldKeepLabel = true;
        }

        return shouldKeepLabel ? label : null;
      });

      zoomWiseFiltered.forEach((point) => {
        if (point !== null) {
          const circleMarker = L.circleMarker([point[0], point[1]], {
            radius: 2,
            color: "blue",
            fillColor: "#0000ff",
            fillOpacity: 0.5,
          }).addTo(chaingeMarkers);

          const label = L.marker([point[0], point[1]], {
            icon: L.divIcon({
              className: "leaflet-div-label",
              html: `<div class="text-[10px] absolute bg-black bg-opacity-50 text-white py-0.5 px-1 rounded w-max">${point[3]} km</div>`,
              iconAnchor: [0, -10],
            }),
            zIndexOffset: 20, // Ensure the label appears above markers
            pane: "labels", // Assign the label to the custom pane
          }).addTo(chaingeMarkers);

          // Adjust label position
          label.setLatLng([point[0], point[1]]);
        }
      });

      return () => {
        map.removeLayer(chaingeMarkers);
      };
    }
  }, [map, hmelMarkers]);

  useEffect(() => {
    if (asset && map && clientName) {
      if (!showComparer) {
        const geoServerClientReferenace = clientName.toLowerCase();
        const wmsUrl = `https://gis.indrones.com/geoserver/${geoServerClientReferenace}/wms`;

        // const wmsUrl = "https://gis.indrones.com/geoserver/skyhigh/wms";
        // layers: "SKY_HIGH_GOA_DTM",
        const wmsParams = {
          layers: asset.Name,
          format: "image/png",
          transparent: true,
          version: "1.1.0",
          maxZoom: 23,
          zIndex: 10,
          detectRetina: true,
          keepBuffer: 6,
          // tileSize: 2000,
        };

        tileLayer && map.removeLayer(tileLayer);

        const newTileLayer = L.tileLayer.wms(wmsUrl, wmsParams);

        newTileLayer.on("loading", function () {
          dispatch(gisLeafletActions.setIsWMSAssetLoading(true));
        });

        newTileLayer.on("load", function () {
          dispatch(gisLeafletActions.setIsWMSAssetLoading(false));
        });

        newTileLayer.addTo(map);

        setTileLayer(newTileLayer);

        const {
          westBoundLongitude,
          eastBoundLongitude,
          southBoundLatitude,
          northBoundLatitude,
        } = asset.EX_GeographicBoundingBox;

        let bounds = L.latLngBounds(
          L.latLng(
            parseFloat(southBoundLatitude),
            parseFloat(westBoundLongitude)
          ),
          L.latLng(
            parseFloat(northBoundLatitude),
            parseFloat(eastBoundLongitude)
          )
        );
        setBounds(bounds);

        map.flyToBounds(bounds);
        // map.flyTo([15.63299599493898, 73.87548810555688]);

        return () => {
          map.removeLayer(newTileLayer);
        };
      } else {
        tileLayer && map.removeLayer(tileLayer);
      }
    }
  }, [asset, showComparer]);

  useCompareAssetsL(mapRef);

  /* UseEffect to remove leaflets defualt ZoomControls */
  useEffect(() => {
    map.removeControl(map._controlCorners.topleft);
    let zoomControl = null;
    return () => {
      if (zoomControl !== null) {
        map.removeControl(zoomControl);
        zoomControl = null;
      }
    };
  }, [map]);

  useEffect(() => {
    const kmlFiles = [
      {
        file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/HMEL_50m%20Buffer_GREEN_ZONE.kml",
        name: "RoU",
      },
      {
        file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/hmel_centerline.kml",
        name: "Center Line",
      },
      {
        file: "https://d2boh9caghjgas.cloudfront.net/hmel/KML/SV_Markers.kml",
        name: "SV Stations",
      },
    ];
    // Function to load KML files
    const loadKML = (kmlFile) => {
      const kmlLayer = {
        id: uuidv4(),
        KmlName: kmlFile.name,
        kmlFilePath: kmlFile.file,
        visible: true,
      };
      dispatch(gisLeafletActions.setKmlsetArray(kmlLayer));
    };
    kmlFiles.forEach(loadKML);
    return () => {
      // Cleanup function to remove the loaded KML layers and associated actions
      dispatch(gisLeafletActions.clearKmlsetArray());
    };
  }, []);

  // useEffect(() => {
  //   const kmlFiles = [kmlFile];

  //   // Function to load KML files
  //   const loadKML = (kmlFile) => {
  //     fetch(kmlFile)
  //       .then((response) => response.text())
  //       .then((kmlData) => {
  //         const parser = new DOMParser();
  //         const kmlDocument = parser.parseFromString(
  //           kmlData,
  //           "application/xml"
  //         );

  //         const name = kmlDocument.querySelector("name").textContent;

  //         // Filter out only the polylines from the KML data
  //         const polylines = Array.from(
  //           kmlDocument.querySelectorAll("LineString")
  //         ).map((lineString) => {
  //           const coordinates = lineString
  //             .querySelector("coordinates")
  //             .textContent.trim()
  //             .split(/\s+/)
  //             .map((coord) => coord.split(",").map(parseFloat));
  //           return L.polyline(coordinates, { color: "red" }); // Customize the polyline properties as needed
  //         });

  //         // Create a layer group with all the polylines and add it to the map
  //         const polylineLayer = L.layerGroup(polylines).addTo(map);
  //         polylineLayer.name = name;

  //         // Adjust map to show the polylines layer
  //         const bounds = polylineLayer.getBounds();
  //         if (bounds.isValid()) {
  //           map.fitBounds(bounds);
  //         }

  //         // If you still want to show names alongside markers, you can create a separate layer for that
  //         const placemarks = Array.from(
  //           kmlDocument.querySelectorAll("Placemark")
  //         ).map((placemark) => {
  //           const name = placemark.querySelector("name").textContent;
  //           const coordinates = placemark
  //             .querySelector("coordinates")
  //             .textContent.trim()
  //             .split(",")
  //             .map(parseFloat);
  //           const marker = L.marker(coordinates).bindPopup(name); // Attach the name as a popup to each marker
  //           return marker;
  //         });

  //         // Create a layer group with all the markers and add it to the map
  //         const markersLayer = L.layerGroup(placemarks).addTo(map);
  //       })
  //       .catch((error) => {
  //         console.error("Error loading KML:", error);
  //       });
  //   };

  //   kmlFiles.forEach(loadKML);
  // }, []);

  const getPolylineColor = (severity) => {
    severity = severity.toLowerCase();

    switch (severity) {
      case "low":
        return "yellow";
      case "medium":
        return "orange";
      case "high":
        return "red";
      default:
        return "blue"; // Default color
    }
  };

  // Function to determine polygon color based on severity
  const getPolygonColor = (severity) => {
    severity = severity.toLowerCase();
    switch (severity) {
      case "low":
        return "yellow";
      case "medium":
        return "orange";
      case "high":
        return "red";
      default:
        return "blue"; // Default color
    }
  };

  const highMarkerIcon = new L.Icon({
    className: "custom-marker-icon",
    iconUrl: highMarker,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const mediumMarkerIcon = new L.Icon({
    className: "custom-marker-icon",
    iconUrl: mediumMarker,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const lowMarkerIcon = new L.Icon({
    className: "custom-marker-icon",
    iconUrl: lowMarker,
    iconSize: [32, 32],
  });

  const defaultMarkerIcon = new L.Icon({
    className: "custom-marker-icon",
    iconUrl: defaultMarker,
    iconSize: [32, 32],
  });

  // Function to determine which icon to use based on severity
  function getMarkerIcon(severity) {
    severity = severity.toLowerCase();
    switch (severity) {
      case "high":
        return highMarkerIcon;
      case "medium":
        return mediumMarkerIcon;
      case "low":
        return lowMarkerIcon;
      default:
        return defaultMarkerIcon;
    }
  }

  // useEffect(() => {
  //   const getAnno = async () => {
  //     dispatch(gisActions.setPolygonAnnotationsData([]));
  //     dispatch(gisActions.setMarkerArray([]));

  //     const polygonObjects = userPermissions.includes("annotation_polygon_view")
  //       ? observationsBucket?.filter((item) => item?.filter_type === "polygon")
  //       : [];

  //     const polylineObjects = userPermissions.includes(
  //       "annotation_polyline_view"
  //     )
  //       ? observationsBucket?.filter((item) => item?.filter_type === "polyline")
  //       : [];

  //     const markerObjects = userPermissions.includes("annotation_marker_view")
  //       ? observationsBucket?.filter(
  //           (item) =>
  //             item?.filter_type !== "polygon" &&
  //             item?.filter_type !== "polyline"
  //         )
  //       : [];

  //     // setAllMarkersArray(allObservations);
  //     dispatch(gisActions.setMarkerArray(markerObjects));
  //     dispatch(gisActions.setPolygonAnnotationsData(polygonObjects));
  //     dispatch(gisActions.setPolylineAnnotationsData(polylineObjects));
  //   };

  //   getAnno();
  // }, [observationsBucket]);
  //  { name: "SKY_HIGH_GOA_ORI" },
  // { name: "SKY_HIGH_GOA_DSM" },
  // { name: "SKY_HIGH_GOA_DTM" },

  // Include Leaflet.markercluster library in your project

  useEffect(() => {
    // Create marker cluster group for markers
    const masterClusterGroup = L.markerClusterGroup().addTo(map);
    const markerClusterGroup = L.markerClusterGroup().addTo(map);

    // Create marker cluster group for polygons
    const polygonClusterGroup =
      L.markerClusterGroup().addTo(masterClusterGroup);

    const polygonCdeflateFeatures = L.deflate({
      minSize: 40,
      markerOptions: { icon: defaultMarkerIcon },
      markerLayer: polygonClusterGroup,
    });
    polygonCdeflateFeatures.addTo(map);

    // Create marker cluster group for polylines
    const polylineClusterGroup =
      L.markerClusterGroup().addTo(masterClusterGroup);

    const polylineCdeflateFeatures = L.deflate({
      minSize: 10,
      markerOptions: { icon: defaultMarkerIcon },
      markerLayer: polylineClusterGroup,
    });

    polylineCdeflateFeatures.addTo(map);

    const addMarkersToMap = (markerObjects) => {
      // Clear existing markers before adding new ones
      markerClusterGroup.clearLayers();
      markerObjects.forEach((marker) => {
        const markerToAdd = L.marker(marker?.markerPosition, {
          icon: getMarkerIcon(marker.severity),
          zIndexOffset: 200,
          // Set the ID of the marker to marker.id
        });
        markerToAdd._leaflet_id = marker.id;
        markerToAdd.type = marker.filter_type;
        // Add onclick functionality to the marker
        markerToAdd.on("click", function () {
          setClickedMarker(marker);
          setIsMetaModalOpen(true);
        });

        markerClusterGroup.addLayer(markerToAdd);
      });
    };

    const addPolygonsToMap = (polygonObjects) => {
      // Clear existing polygons before adding new ones
      polygonCdeflateFeatures.clearLayers();
      polygonObjects.forEach((polygon) => {
        const polygonToAdd = L.polygon(polygon.markerPosition, {
          color: getPolygonColor(polygon.severity),
          smoothFactor: 0,
        });
        polygonToAdd._leaflet_id = polygon.id;
        polygonToAdd.type = polygon.filter_type;
        // Add onclick functionality to the polygon
        polygonToAdd.on("click", function () {
          setClickedMarker(polygon);
          setIsMetaModalOpen(true);
        });
        measure.handlePolygon(polygonToAdd, map, null);
        polygonCdeflateFeatures.addLayer(polygonToAdd);
      });
    };

    const addPolylinesToMap = (polylineObjects) => {
      // Clear existing polylines before adding new ones
      polylineCdeflateFeatures.clearLayers();
      polylineObjects.forEach((polyline) => {
        const polylineToAdd = L.polyline(polyline.markerPosition, {
          color: getPolylineColor(polyline.severity),
        });
        polylineToAdd._leaflet_id = polyline.id;
        polylineToAdd.type = polyline.filter_type;
        // Add onclick functionality to the polyline
        polylineToAdd.on("click", function () {
          setClickedMarker(polyline);
          setIsMetaModalOpen(true);
        });
        measure.handlePolyline(polylineToAdd, map, null);
        polylineCdeflateFeatures.addLayer(polylineToAdd);
      });
    };

    const markerObjects = observationsBucket?.filter(
      (item) =>
        item?.filter_type !== "polygon" &&
        item?.filter_type !== "polyline" &&
        observationsVisibilityStatus.some(
          (visItem) => visItem.id === item.id && visItem.visible
        )
    );

    const polygonObjects = userPermissions.includes("annotation_polygon_view")
      ? observationsBucket?.filter(
          (item) =>
            item?.filter_type === "polygon" &&
            observationsVisibilityStatus.some(
              (visItem) => visItem.id === item.id && visItem.visible
            )
        )
      : [];

    const polylineObjects = userPermissions.includes("annotation_polyline_view")
      ? observationsBucket?.filter(
          (item) =>
            item?.filter_type === "polyline" &&
            observationsVisibilityStatus.some(
              (visItem) => visItem.id === item.id && visItem.visible
            )
        )
      : [];

    // Add markers to the map
    addMarkersToMap(markerObjects);

    // Add polygons to the map
    addPolygonsToMap(polygonObjects);

    // Add polylines to the map
    addPolylinesToMap(polylineObjects);

    // Return cleanup function
    return () => {
      map.removeLayer(markerClusterGroup);
      map.removeLayer(polygonClusterGroup);
      map.removeLayer(polylineClusterGroup);
      map.removeLayer(masterClusterGroup);
    };
  }, [observationsBucket, observationsVisibilityStatus, refCounter]);

  /**
   * The function `handleZoom` takes a direction ('in' or 'out') as input and zooms the map in or out
   * accordingly. only for 2d i.e. leaflet
   *
   * @param {*} direction
   */
  const handleZoom = (direction) => {
    if (direction === "in") {
      map.zoomIn();
    } else if (direction === "out") {
      map.zoomOut();
    }
  };

  return (
    <>
      <div className="h-[auto] z-[1000]">
        {/* <input
          type="file"
          onChange={handleFileChange}
          className="z-[1000] absolute top-10 left-10 w-[10rem]"
        /> */}
        <GISToolbarLeaflet
          handleHomeButtonClick={() => map.flyToBounds(bounds, 15, "pan")}
          handleZoom={handleZoom}
          // imageryLabel={imageryLabel}
          className="z-[1000]"
          mapRef={mapRef}
        />
        {showComparer && <ComparerUIL />}
        {isMetaModalOpen && (
          <MetaDataModalLeaflet
            isOpen={isMetaModalOpen}
            onClose={() => setIsMetaModalOpen(false)}
            marker={clickedMarker}
            onDeleteMarker={() => {
              // handleDeleteMarker(selectedMarker?.id, selectedMarker?.timestamp)
            }}
          />
        )}
      </div>
    </>
  );
};

export default GISLeaflet;
