import React from "react";
import Tooltip from "@mui/material/Tooltip";

/**
 * TooltipButton component renders a button with a tooltip.
 * Takes props like title, onClick, isActive, childern for now it is getting mui icon.
 * @param {string} title - The title of the tooltip.
 * @param {Function} onClick - The function to call when the button is clicked.
 * @param {boolean} isActive - Indicates whether the button is active (tooltip is disabled and dynamic style).
 * @param {React.ReactNode} children - The content of the button (icon or text).
 * @returns {JSX.Element} - JSX element representing the TooltipButton.
 */

const SidebarNavItem = ({ title, onClick, isActive, children }) => {
  return (
    <>
      {isActive ? (
        <div
          onClick={onClick}
          className={`bg-white border sidebar-active-nav-item flex p-2 items-center justify-center rounded-[5px] cursor-pointer`}
        >
          <span className="transition-transform  hover:scale-110">
            {children}
          </span>
        </div>
      ) : (
        <Tooltip
          title={title}
          placement="right"
          arrow
          className="z-[100000000]"
        >
          <div
            onClick={onClick}
            className={` bg-white border border-[#f2f3f3] flex p-2 items-center justify-center rounded-[5px] cursor-pointer`}
          >
            <span className="transition-transform hover:scale-110">
              {children}
            </span>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default SidebarNavItem;
