import React from "react";
import ContentCutRoundedIcon from "@mui/icons-material/ContentCutRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import DownloadingRoundedIcon from "@mui/icons-material/DownloadingRounded";
import { IconButton, Popper, Slider, TextField } from "@mui/material";
import thumbImage from "../../Assets/images/frame_arrow.svg";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  formatTime,
  convertTimeToSeconds,
  filterDuplicates,
  filterMarks,
} from "../../Utils/OtherUtils";
import { HeartbeatApis } from "../../Services/SecondPartyApi/Heartbeat";
import { useSelector } from "react-redux";

const handleSnapShotDownload = (image_link) => {
  const filename = "Screenshot";
  const link = document.createElement("a");
  link.href = image_link;
  link.setAttribute("download", `${filename}.jpg`);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const Controls = (props) => {
  const videoRef = props.videoRef;
  const [playbackRate, setPlaybackRate] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [qualityAnchorEl, setQualityAnchorEl] = React.useState(null);
  const [startTime, setStartTime] = React.useState("");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const qualityOpen = Boolean(qualityAnchorEl);
  const qualityId = qualityOpen ? "simple-popover" : undefined;
  const chainges = useSelector((state) => state.project.chainages);
  const projectName = useSelector((state) => state.project.projectName);
  const videoSrc = useSelector((state) => state.project.clipSrc);
  // if (props.isIOCL) {
  //   return filterDuplicates(chainages_iocl_nrpl);
  // }

  const marks = React.useMemo(() => {
    return chainges?.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.label === item.label)
    );
  }, [chainges]);

  const handleChange = (e) => {
    setPlaybackRate(parseFloat(e.target.value));
    videoRef.current.playbackRate = parseFloat(e.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleQualityClose = () => {
    setQualityAnchorEl(null);
  };

  const handleClip = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setStartTime(formatTime(props.currentTime));
    // props.handlePlayPause();
  };

  const handleQuality = (event) => {
    setQualityAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleStartTimeChange = (event) => {
    const time = event.target.value;
    setStartTime(time);
  };

  const params = {};
  const clipVideo = async (event) => {
    event.preventDefault();
    const start_time = convertTimeToSeconds(event.target.start_time.value);
    const end_time = convertTimeToSeconds(event.target.end_time.value);

    if (start_time > end_time) {
      toast.error("End Time should be greater than Start Time!");
    } else {
      params.s3_input_link = videoSrc;
      params.start_time = event.target.start_time.value;
      params.end_time = event.target.end_time.value;
      params.clip_name = event.target.clip_name.value;
      params.project = projectName;
      const response = await HeartbeatApis.clipVideo(params);
      if (response.status === 200) {
        handleClose();
        toast.success("Clip Processing!");
      } else {
        toast.error("Clip Creation Failed!");
      }
    }
  };

  const snapShotParams = {};
  const createSnapshot = async (event) => {
    event.preventDefault();
    snapShotParams.project_name = projectName;
    snapShotParams.frame_sec = videoRef.current.currentTime;
    snapShotParams.video_time = formatTime(videoRef.current.currentTime);
    const response = await HeartbeatApis.createSnapshot(snapShotParams);
    if (response.success) {
      handleSnapShotDownload(response.data);
    } else {
      toast.error("Snapshot Creation Failed");
    }
  };

  const handleQualityLevelChange = (index) => {
    props.setLevel(index);
    handleQualityClose();
  };

  return (
    <div className="flex flex-col px-5 py-2  canvascontrol-mv">
      <div className="flex w-full items-center justify-between fordevice1000-1300">
        <div className="flex items-center gap-5 max-[500px]:gap-1">
          <div
            className={`flex items-center ${
              props.isIOCL2 && "text-gray-300"
            } gap-1 text-gray-300 cursor-pointer rounded-lg p-1 transition-c hover:border-[0px] hover:bg-[#f7f7f8]`}
            // onClick={handleClip}
          >
            {!props.compare && (
              <>
                <ContentCutRoundedIcon
                  style={{
                    fontSize: 17,
                    color: `${props.isIOCL2 ? "#d1d5db" : "#d1d5db"}`,
                  }}
                />
                <span className="text-md font-semibold action-name">Clip</span>
              </>
            )}
          </div>
          <div
            className={`flex items-center ${
              props.isIOCL2 && "text-gray-300"
            } gap-1 text-gray-300 cursor-pointer rounded-lg p-1 transition-c hover:border-[0px] hover:bg-[#f7f7f8]`}
            // onClick={createSnapshot}
          >
            {!props.compare && (
              <>
                <DownloadingRoundedIcon
                  style={{
                    fontSize: 18,
                    color: `${props.isIOCL2 ? "#d1d5db" : "#d1d5db"}`,
                  }}
                />
                <span className="text-md font-semibold action-name">
                  Snapshot
                </span>
              </>
            )}
          </div>
        </div>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          placement="right-start"
          className="z-[9999]"
        >
          <div className="bg-white rounded-[12px] border flex flex-col h-min">
            <div className="rounded-tl-[12px] bg-[#ffca0094] pt-[10px] pb-[10px] font-[500] rounded-tr-[12px] flex items-center justify-between">
              <h3 className="grow flex items-center justify-center">
                Clip Video
              </h3>
              <div className="mr-[10px]" onClick={handleClose}>
                <IconButton aria-label="delete" size="small">
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
            <form
              className="grow mt-5 flex flex-col justify-between m-2"
              onSubmit={clipVideo}
            >
              <div className="flex flex-col items-center w-[100%] h-full">
                <div className="flex items-center">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Start Time"
                    name="start_time"
                    value={startTime}
                    onChange={handleStartTimeChange}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="flex items-center mt-2">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="End Time"
                    name="end_time"
                    variant="outlined"
                    required
                  />
                </div>
                <div className="flex items-center mt-2">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    label="Clip Name"
                    name="clip_name"
                    variant="outlined"
                    required
                  />
                </div>
              </div>
              <div className="m-auto my-2">
                <button
                  aria-label="add"
                  className="!rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
                  type="submit"
                >
                  Clip
                </button>
              </div>
            </form>
          </div>
        </Popper>
        <div
          className="flex items-center gap-2"
          style={{ marginLeft: `${props.compare ? "3%" : "0px"}` }}
        >
          <div
            onClick={props.handlePlayPause}
            className="custom-iconsize text-[#5D647B] cursor-pointer rounded-lg hover:border hover:bg-[#f7f7f8]"
          >
            {props.isPlaying ? (
              <PauseRoundedIcon sx={{ fontSize: "26px", color: "#3a3a3c" }} />
            ) : (
              <PlayArrowRoundedIcon
                sx={{ fontSize: "26px", color: "#3a3a3c" }}
              />
            )}
          </div>
          <div className="text-[#717F87] text-md">
            {formatTime(props.currentTime)}
          </div>
        </div>
        <div className="w-[220px] flex gap-2 items-center">
          <div
            className="flex items-center mr-2 gap-1 cursor-pointer rounded-lg p-1 transition-c hover:border-[0px] hover:bg-[#f7f7f8]"
            onClick={handleQuality}
          >
            {!props.compare && (
              <>
                <SettingsIcon style={{ fontSize: 18, color: "#3a3a3c" }} />
                <span className="text-md font-semibold action-name">
                  Quality
                </span>
              </>
            )}
          </div>
          {!props.compare && (
            <Popper
              id={qualityId}
              open={qualityOpen}
              anchorEl={qualityAnchorEl}
              onClose={handleQualityClose}
              placement="top"
              className="z-[9999]"
            >
              <div className="bg-white rounded-[12px] border flex flex-col h-min">
                <div className="rounded-tl-[12px] bg-[#ffca0094] p-[10px] font-[500]  rounded-tr-[12px] flex items-center justify-between">
                  <h3 className="grow flex items-center justify-center">
                    Video Quality
                  </h3>
                </div>
                <div className="flex flex-col items-center w-[100%] h-full">
                  <div
                    className="cursor-pointer"
                    value="-1"
                    onClick={() => handleQualityLevelChange(-1)}
                  >
                    Auto
                  </div>
                  {props.qualityLevels.map((level, index) => {
                    return (
                      <div
                        key={index}
                        value={index}
                        className="py-[2px] cursor-pointer"
                        onClick={() => handleQualityLevelChange(index)}
                      >
                        {level.height}p
                      </div>
                    );
                  })}
                </div>
              </div>
            </Popper>
          )}
          <Slider
            min={1}
            max={4}
            step={1}
            aria-label="Volume"
            size="medium"
            value={playbackRate}
            onChange={handleChange}
            sx={{
              color: "#fff",
              "& .MuiSlider-thumb": {
                width: 16,
                height: 16,
                borderRadius: "50%",
                backgroundColor: "white",
                border: "3px solid #0098FD",
                "&:focus, &:hover, &.Mui-active": {
                  boxShadow: "0 0 8px rgba(0, 0, 0, 0.3) !important",
                },
                transition: "box-shadow 0.3s",
                boxShadow: "none",
              },
              "& .MuiSlider-track": {
                backgroundColor: "#67C0FE",
                height: 7,
              },
              "& .MuiSlider-rail": {
                backgroundColor: "#EEEEF0",
                height: 7,
              },
            }}
          />
          <span className="text-[#717F87] text-md ml-2">{playbackRate}x</span>
        </div>
      </div>
      <div className="w-full h-[30px] flex justify-center items-center rangeofvideo">
        <Slider
          min={0}
          max={props.duration}
          step={0.1}
          value={props.currentTime}
          onChange={props.handleSeek}
          marks={marks}
          valueLabelDisplay="off"
          className="!h-[90%] !rounded-md !mb-0 !p-0 mt-[24px] range-for-device"
          sx={{
            color: "#fff",
            "& .MuiSlider-thumb": {
              top: `${props.compare ? "17px" : "22px"}`,
              backgroundColor: "none",
              background: `url("${thumbImage}")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: `${props.compare ? "20px" : "25px"} !important`,
              height: `${props.compare ? "48px" : "48px"} !important`,
              zIndex: 1,
              border: 0,
              boxShadow: "none",
            },
            "@media screen and (max-width: 500px)": {
              "& .MuiSlider-thumb": {
                top: "16px",
                height: 67,
              },
            },
            "@media  screen and (min-width: 1000px) and (max-width: 1500px)": {
              "& .MuiSlider-thumb": {
                top: "39px",
                height: 92,
                width: 25,
              },
            },

            "& .MuiSlider-track": {
              display: "none",
            },
            "& .MuiSlider-rail": {
              display: "none",
            },
            "& .MuiSlider-markLabel": {
              color: "#929398",
              top: "40%",
              fontSize: "12px",
            },
            "& .MuiSlider-mark": {
              height: 5,
              width: 2,
              borderRadius: 20,
              top: "91%",
              marginTop: "4px",
              backgroundColor: "#dfe0e5",
            },
            "& .MuiSlider-thumb:before": {
              boxShadow: "none !important",
            },
          }}
        />
      </div>

      <div className="flex w-full items-center justify-center fordevice1000-1300 text-[#3a3a3c] font-[600] tracking-[1px] mt-[26px]">
        Chainage (km)
      </div>

      {/* {!props.compare && <Seekbar />} */}
      {/* <Toaster
        position="bottom-left"
        toastOptions={{
          duration: 2000,
          style: {
            background: "#4a4844",
            color: "#fff",
            border: "1px solid #4a4844",
          },
        }}
      /> */}
    </div>
  );
};

export default Controls;
