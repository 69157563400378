import CircularProgress from "@mui/material/CircularProgress";
import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { whiteLogo } from "../../../Assets/icons/gisIcons/icons";

const BrandingAndStatsBarLeaflet = ({
  isSidebaropen,
  openShapes,
  openLayers,
  map,
}) => {
  const [mousePosition, setMousePosition] = useState({
    formatted: false,
    lat: null,
    lng: null,
  });

  const [displayDMS, setDisplayDMS] = useState(true); // State to toggle between DMS and decimal degrees

  const { bottomReportToggle } = useSelector((state) => state.gisHome);
  const { showComparer } = useSelector((state) => state.gisTools);
  const { IsWMSAssetLoading, asset } = useSelector((state) => state.gisLeaflet);

  // effect for size of bottom bar
  useEffect(() => {
    const otherItem = document.getElementById("GISSidebarBodyLeaflet");

    if (otherItem) {
      const width = otherItem.offsetWidth;
      const component = document.getElementById("BrandingAndStatsBar");

      if (component) {
        showComparer
          ? (component.style.width = `calc(100vw)`)
          : (component.style.width = `calc(100vw - ${width}px)`);
      }
    }
  }, [isSidebaropen, openShapes, openLayers, bottomReportToggle, showComparer]);

  useEffect(() => {
    setMousePosition({ formatted: false, lat: null, lng: null });
  }, []);

  useEffect(() => {
    let mousemoveHandler;

    if (map) {
      mousemoveHandler = (e) => {
        const { lat, lng } = e.latlng;
        setMousePosition({ formatted: displayDMS, lat: lat, lng: lng });
      };

      map.on("mousemove", mousemoveHandler);
    }

    // Clean up event handler
    return () => {
      if (map && mousemoveHandler) {
        map.off("mousemove", mousemoveHandler);
      }
    };
  }, [map, displayDMS]);

  const handleClick = () => {
    setDisplayDMS(!displayDMS); // Toggle between DMS and decimal degrees
  };

  const formatLatitude = (latitude) => {
    const degrees = Math.floor(latitude);
    const minutes = Math.floor((latitude - degrees) * 60);
    const seconds = ((latitude - degrees) * 3600 - minutes * 60).toFixed(2);
    return `${degrees}° ${minutes}' ${seconds}" N`;
  };

  const formatLongitude = (longitude) => {
    const degrees = Math.floor(longitude);
    const minutes = Math.floor((longitude - degrees) * 60);
    const seconds = ((longitude - degrees) * 3600 - minutes * 60).toFixed(2);
    return `${degrees}° ${minutes}' ${seconds}" E`;
  };
  const scaleControlRef = useRef(null);

  const scaleDivRef = useRef(null);
  useEffect(() => {
    // Check if the scale control already exists on the map
    if (!map.scaleControl) {
      // Add Leaflet's scale control to the map
      const scaleControl = L.control.scale({
        position: "bottomright",
        imperial: false,
      });
      scaleControl.addTo(map);
      scaleControlRef.current = scaleControl;
    }

    // Append the scale control element to the scale div
    scaleDivRef.current.appendChild(scaleControlRef.current.getContainer());
    // Style the scale control
    const scaleElement = scaleControlRef.current.getContainer();
    // scaleElement.getElementsByClassName("leaflet-control-scale-line")[0].style.backgroundColor = "black";
    if (scaleElement) {
      // Change background color
      scaleElement.getElementsByClassName(
        "leaflet-control-scale-line"
      )[0].style.backgroundColor = "black";

      // Change text color
      const scaleTextElement = scaleElement.querySelector(
        ".leaflet-control-scale-line"
      );
      if (scaleTextElement) {
        scaleTextElement.style.color = "white";
        scaleTextElement.style.textShadow = "none"; // Remove text shadow
      }

      // Change border color
      const scaleLineElement = scaleElement.querySelector(
        ".leaflet-control-scale-line"
      );
      if (scaleLineElement) {
        scaleLineElement.style.borderColor = "white";
        scaleLineElement.style.backgroundColor = "transparent";
        scaleLineElement.style.borderWidth = "1px";
        scaleLineElement.style.fontSize = "10px";
      }
    }

    return () => {
      // Remove the scale control when the component unmounts
      map.removeControl(scaleControlRef.current);
    };
  }, []);

  if (!bottomReportToggle) {
    return (
      <footer
        id="BrandingAndStatsBar"
        className="z-50 absolute bottom-0 left-[100%] flex justify-between px-2 py-1 items-center w-full"
      >
        <div className="flex gap-4 ">
          <img
            src={whiteLogo}
            className="h-4"
            alt="Indrones white name logo"
            srcSet=""
          />
          {/* <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[11.2px] underline text-white cursor-pointer flex items-center"
          >
            Rights reserved
            <img
              src={openInNewIcon}
              alt="openInNew"
              className="ml-1 h-[12px]"
            />

          </a> */}
          <p className=" text-[10px]  text-white cursor-default flex items-center">
            Rights reserved
          </p>
        </div>
        <div>
          {
            asset &&
              // <Tooltip
              //   title="Progress towards loading current selected timeline"
              //   placement="top"
              //   arrow
              // >
              (IsWMSAssetLoading ? (
                <div className=" cursor-default flex gap-1 text-white ">
                  <CircularProgress
                    size={16}
                    color="primary"
                    thickness={6}
                    className=" h-8"
                  />
                  <span className="text-xs">Loading assets</span>
                </div>
              ) : (
                <div className="cursor-default flex gap-1 text-white hidden">
                  <CircularProgress
                    thickness={8}
                    size={16}
                    variant="determinate"
                    color="info"
                    value={100}
                  />
                  <span className="text-xs">Assets loaded</span>
                </div>
              ))
            // </Tooltip>
          }
        </div>
        {/* Add scale to the map */}

        <div className="statsSection flex gap-4 items-center mr-1">
          <div className="scale" ref={scaleDivRef}></div>
          <p
            className={`text-[11.2px] text-center text-white ${
              displayDMS ? "min-w-[11.5rem]" : "min-w-max"
            }`}
            onClick={handleClick}
          >
            {displayDMS
              ? `${formatLatitude(
                  mousePosition.lat
                )}\u00A0\u00A0${formatLongitude(mousePosition.lng)}`
              : `Lat ${mousePosition.lat.toFixed(
                  5
                )}\u00A0\u00A0Lng ${mousePosition.lng.toFixed(5)}`}
          </p>
        </div>
      </footer>
    );
  }
};

export default BrandingAndStatsBarLeaflet;
