import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";

import { AnnotationManager } from "../../../Services/ThirdPartyApi/AWS/DynamoDBL";
import { S3Apis } from "../../../Services/ThirdPartyApi/AWS/S3";

import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { copyIcon } from "../../../Assets/icons/gisIcons/icons";
import {
  observationCategories,
  observationTaskStatus,
  observationsAndCategories,
} from "../../../Data/config/ProjectConfig";
import { formatDate } from "../../../Features/GIS/Utils/otherUtils";
import { Measure } from "../../../Utils/Leaflet/LeafletUtils";
import { gisActions } from "../../../redux/slices/GIS/gis-slice";
import CommentBox from "../CommentBox";
import ImageSlider from "../ImageSlider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  boxShadow: 24,
  p: 4,
};

const dropzoneStyle = {
  borderRadius: "4px",
  cursor: "pointer",
  textAlign: "center",
  border: "2px dashed #cccccc",
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="h-full w-full">{children}</div>}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const MetaDataModalLeaflet = ({ isOpen, onClose, marker, onDeleteMarker }) => {
  const measureUtil = new Measure();
  const { userPermissions } = useSelector((state) => state.gis);
  const { observationsBucket } = useSelector((state) => state.gisLeaflet);
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = React.useState(true);
  const markerData = observationsBucket.find(
    (observation) => observation.id === marker.id
  );

  const [observationType, setObservationType] = useState(
    markerData?.observationType
  );
  const [category, setCategory] = useState(markerData?.category);
  const [severity, setSeverity] = useState(markerData?.severity);
  const [description, setDescription] = useState(markerData?.description);
  const [uploadedFile, setUploadedFile] = useState("");
  const [taskStatus, setTaskStatus] = useState(markerData?.taskStatus);
  const [addedBy, setAddedBy] = useState(markerData?.addedBy);
  const [verifiedBy, setVerifiedBy] = useState(markerData?.verifiedBy ?? "");
  const [reviewedBy, setReviewedBy] = useState(markerData?.reviewedBy ?? "");
  const [images, setImages] = useState(markerData.images);

  const [value, setValue] = useState(0);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    setObservationType(markerData?.observationType);
    setCategory(markerData?.category);
    setSeverity(markerData?.severity);
    setDescription(markerData?.description);
    setUploadedFile("");
    setTaskStatus(markerData?.taskStatus);
    setAddedBy(markerData?.addedBy);
    setVerifiedBy(markerData?.verifiedBy);
    setReviewedBy(markerData?.reviewedBy);
    setIsEditMode(!isEditMode);
  };

  const resetData = () => {
    setObservationType(markerData?.observationType);
    setCategory(markerData?.category);
    setSeverity(markerData?.severity);
    setDescription(markerData?.description);
    setUploadedFile("");
    setTaskStatus(markerData?.taskStatus);
    setAddedBy(markerData?.addedBy);
    setVerifiedBy(markerData?.verifiedBy);
    setReviewedBy(markerData?.reviewedBy);
    setIsEditMode(!isEditMode);
  };

  const updateAnnotation = async (id, timestamp) => {
    var imagedata = [];

    if (uploadedFile) {
      const imageData = await S3Apis.uploadData(uploadedFile, "images");
      imagedata = [{ path: imageData.s3Url.key, date: imageData.Imagedate }];
    }

    const key = {
      id: id,
      timestamp: timestamp,
    };

    const fieldsToUpdate = {
      observationType,
      category,
      severity,
      description,
      taskStatus,
      addedBy,
      verifiedBy,
      reviewedBy,
      updatedOn: formatDate(Date.now()),
      images: imagedata,
    };

    try {
      AnnotationManager.editAnnotations(key, fieldsToUpdate);
      toast.success("Changes Saved Successfully!");
      dispatch(gisActions.refCounterUpdate());
      setIsEditMode(true);
      onClose();
    } catch (error) {
      toast.error("Something went wrong!!");
    }
    // fetchData();
    // refCounterUpdate();
  };

  const onDrop = (acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  };
  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const copyToClipboard = () => {
    const idToCopy = markerData.id.split("-")[0];
    navigator.clipboard
      .writeText(idToCopy)
      .then(() => {
        toast.success(`Id ${idToCopy} copied successfully  `);
        // You can also provide user feedback here if needed
      })
      .catch((err) => {
        console.error("Error copying ID to clipboard:", err);
        // Handle the error or provide user feedback
      });
  };

  const handleDeleteMarker = (annotation) => {
    AnnotationManager.deleteAnnotations(annotation.id, annotation.timestamp);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        setIsEditMode(true);
      }}
    >
      <div
        style={style}
        className="flex flex-col items-center justify-between bg-white w-full h-max rounded-[12px] "
      >
        {/* Modal Header */}
        <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px] w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
          {markerData && (
            <>
              {markerData.filter_type === "polygon" &&
                userPermissions.includes("annotation_polygon_view") &&
                isEditMode && (
                  <IconButton
                    aria-label="delete"
                    onClick={handleEdit}
                    size="small"
                    className="text-[#ffffff]"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              {markerData.filter_type === "polyline" &&
                userPermissions.includes("annotation_polyline_view") &&
                isEditMode && (
                  <IconButton
                    aria-label="delete"
                    onClick={handleEdit}
                    size="small"
                    className="text-[#ffffff]"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              {markerData.filter_type === "marker" &&
                userPermissions.includes("annotation_marker_edit") &&
                isEditMode && (
                  <IconButton
                    aria-label="delete"
                    onClick={handleEdit}
                    size="small"
                    className="text-[#ffffff]"
                  >
                    <EditIcon />
                  </IconButton>
                )}
            </>
          )}

          <h3 className="text-[1.20rem] text-[#211f1c] grow flex items-center justify-center">
            Observation
          </h3>
          <div className="mr-[10px]">
            <IconButton
              onClick={() => {
                onClose();
                setIsEditMode(true);
              }}
              aria-label="Close"
              size="small"
              className="text-[#211f1c]"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="grow h-full w-full p-3 custom-scrollbar">
          <Tabs value={value} onChange={handleChange} className="w-full">
            <Tab label="Description" {...a11yProps(0)} className="w-[50%]" />
            <Tab
              label="Comments"
              {...a11yProps(1)}
              className={`w-[50%] ${
                userPermissions.includes("comments_view") ? "" : "hidden"
              }`}
            />
          </Tabs>
          <CustomTabPanel
            value={value}
            index={0}
            className="max-h-[60vh] overflow-y-auto"
          >
            <>
              <div className="grow w-full p-3 flex flex-col gap-3">
                {/* id  */}
                <div className="flex items-center justify-center w-full ">
                  <label className="w-[30%]">ID:</label>
                  <div className="flex flex-grow flex-col">
                    <p className="grow">{markerData?.id?.split("-")[0]}</p>
                  </div>
                  <button
                    onClick={copyToClipboard}
                    className="   text-white rounded"
                  >
                    <img
                      className="h-4"
                      src={copyIcon}
                      alt="copy id icon"
                      srcSet=""
                    />{" "}
                  </button>
                </div>

                {/* category  */}
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">
                        Category: <span className="text-red-500">*</span>
                      </label>
                      <Select
                        id="demo-simple-select-error"
                        name="category"
                        size="small"
                        value={category}
                        className="grow"
                        onChange={(e) => setCategory(e.target.value)}
                        inputProps={{ readOnly: isEditMode }}
                      >
                        {observationCategories.map((marker, index) => (
                          <MenuItem key={index} value={marker}>
                            {marker}
                          </MenuItem>
                        ))}

                        {/* <MenuItem value="polygon">Polygon</MenuItem> */}
                      </Select>
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Category :</p>{" "}
                      <p>{markerData?.category ? markerData?.category : "-"}</p>
                    </label>
                  )}
                </div>
                {/* observationType */}
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">
                        Type : <span className="text-red-500">*</span>
                      </label>
                      <Select
                        id="demo-simple-select-error"
                        name="category"
                        size="small"
                        value={observationType}
                        className="grow"
                        onChange={(e) => setObservationType(e.target.value)}
                        inputProps={{ readOnly: isEditMode }}
                      >
                        {observationsAndCategories
                          ?.filter((marker) => marker.category === category)
                          .map((marker, index) => (
                            <MenuItem key={marker.type} value={marker.type}>
                              {marker.type}
                            </MenuItem>
                          ))}

                        {/* <MenuItem value="polygon">Polygon</MenuItem> */}
                      </Select>
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Type :</p>{" "}
                      <p>
                        {markerData?.observationType
                          ? markerData?.observationType
                          : "-"}
                      </p>
                    </label>
                  )}
                </div>
                {/* observation risk  */}
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">
                        Risk : <span className="text-red-500">*</span>
                      </label>
                      <Select
                        className="grow"
                        size="small"
                        name="severity"
                        id="demo-simple-select-error"
                        value={severity}
                        onChange={(e) => setSeverity(e.target.value)}
                      >
                        <MenuItem value="Low">Low</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="High">High</MenuItem>
                      </Select>
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Risk :</p>{" "}
                      <p>{markerData?.severity ? markerData?.severity : "-"}</p>
                    </label>
                  )}
                </div>

                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Remark :</label>
                      <TextField
                        name="textNote"
                        size="small"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        InputProps={{
                          readOnly: isEditMode,
                        }}
                        className="grow"
                      />
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Remark :</p>{" "}
                      <p>
                        {markerData?.description
                          ? markerData?.description
                          : "-"}
                      </p>
                    </label>
                  )}
                </div>

                <div className="flex items-center justify-center w-full">
                  <label className="w-[30%]">Image :</label>
                  <div className="flex flex-col w-[70%] gap-2">
                    {images?.length > 0 ? (
                      <div className="h-[10%]">
                        <ImageSlider images={images} />
                      </div>
                    ) : (
                      <>{isEditMode && <p>No Images Available</p>}</>
                    )}

                    {!isEditMode && (
                      <div className="flex items-center justify-center w-full gap-2 grow">
                        {uploadedFile.length === 0 ? (
                          <>
                            <div
                              className="flex flex-col h-[30px] grow"
                              {...getRootProps()}
                              style={dropzoneStyle}
                            >
                              {/* <input {...getInputProps()} /> */}
                              <p className="m-auto text-xs">
                                Drag & Drop Image here or Click to upload
                              </p>
                            </div>
                          </>
                        ) : (
                          <div className="grow flex">
                            <p className="grow">{uploadedFile.name}</p>
                            <DeleteIcon
                              className=" cursor-pointer text-[#211f1c]"
                              onClick={() => setUploadedFile("")}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <hr />
                <p>Task Details</p>
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Status :</label>
                      <Select
                        id="demo-simple-select-error"
                        name="category"
                        size="small"
                        value={taskStatus}
                        className="grow"
                        onChange={(e) => setTaskStatus(e.target.value)}
                        inputProps={{ readOnly: isEditMode }}
                      >
                        {observationTaskStatus.map((status, index) => (
                          <MenuItem key={index} value={status}>
                            {status}
                          </MenuItem>
                        ))}

                        {/* <MenuItem value="polygon">Polygon</MenuItem> */}
                      </Select>
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Status :</p>{" "}
                      <p>
                        {markerData?.taskStatus ? markerData?.taskStatus : "-"}
                      </p>
                    </label>
                  )}
                </div>
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Added by :</label>
                      <p className="grow">
                        {markerData?.addedBy ? markerData?.addedBy : "-"}
                      </p>
                      {/* <TextField
                        name="textNote"
                        size="small"
                        value={addedBy}
                        // onChange={(e) => setAddedBy(e.target.value)}
                        // InputProps={{
                        //   readOnly: isEditMode,
                        // }}
                        className="grow"
                      /> */}
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Added by :</p>{" "}
                      <p>{markerData?.addedBy ? markerData?.addedBy : "-"}</p>
                    </label>
                  )}
                </div>
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Verified by :</label>
                      <TextField
                        name="textNote"
                        size="small"
                        value={verifiedBy}
                        onChange={(e) => setVerifiedBy(e.target.value)}
                        InputProps={{
                          readOnly: isEditMode,
                        }}
                        className="grow"
                      />
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Verified by :</p>{" "}
                      <p>
                        {markerData?.verifiedBy ? markerData?.verifiedBy : "-"}
                      </p>
                    </label>
                  )}
                </div>

                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Reviewed by :</label>
                      <TextField
                        name="textNote"
                        size="small"
                        value={reviewedBy}
                        onChange={(e) => setReviewedBy(e.target.value)}
                        InputProps={{
                          readOnly: isEditMode,
                        }}
                        className="grow"
                      />
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Reviewed by :</p>{" "}
                      <p>
                        {markerData?.reviewedBy ? markerData?.reviewedBy : "-"}
                      </p>
                    </label>
                  )}
                </div>
              </div>
              <div className="my-2 flex gap-2 items-center justify-center">
                {!isEditMode ? (
                  <>
                    <button
                      className="text-slate-700 bg-green-200 border hover:border-green-300 hover:bg-green-300 py-[5px] px-[18px] rounded  cursor-pointer my-2"
                      onClick={() => {
                        updateAnnotation(marker.id, marker.timestamp);
                        setIsEditMode(true);
                      }}
                    >
                      Save Changes
                    </button>
                    <button
                      className="text-slate-700 bg-red-200 border hover:border-red-300 hover:bg-red-300 py-[5px] px-[18px] rounded  cursor-pointer my-2"
                      onClick={resetData}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  marker && (
                    <>
                      {markerData.filter_type === "polygon" &&
                        userPermissions.includes(
                          "annotation_polygon_delete"
                        ) && (
                          <button
                            className="text-slate-700 bg-red-200 border hover:border-red-300 hover:bg-red-300 py-[5px] px-[18px] rounded cursor-pointer my-2"
                            id="deleteButton"
                            onClick={() => {
                              // onDeleteMarker(); // Call the delete callback
                              // refCounterUpdate();
                              handleDeleteMarker(markerData);

                              dispatch(gisActions.refCounterUpdate());
                              onClose();
                            }}
                          >
                            Delete Marker
                          </button>
                        )}
                      {markerData.filter_type === "polyline" &&
                        userPermissions.includes(
                          "annotation_polyline_delete"
                        ) && (
                          <button
                            className="text-slate-700 bg-red-200 border hover:border-red-300 hover:bg-red-300 py-[5px] px-[18px] rounded cursor-pointer my-2"
                            id="deleteButton"
                            onClick={() => {
                              // onDeleteMarker(); // Call the delete callback
                              // refCounterUpdate();
                              handleDeleteMarker(marker);

                              dispatch(gisActions.refCounterUpdate());
                              onClose();
                            }}
                          >
                            Delete Marker
                          </button>
                        )}
                      {markerData.filter_type === "marker" &&
                        userPermissions.includes(
                          "annotation_marker_delete"
                        ) && (
                          <button
                            className="text-slate-700 bg-red-200 border hover:border-red-300 hover:bg-red-300 py-[5px] px-[18px] rounded cursor-pointer my-2"
                            id="deleteButton"
                            onClick={() => {
                              // onDeleteMarker(); // Call the delete callback
                              // refCounterUpdate();
                              handleDeleteMarker(marker);
                              dispatch(gisActions.refCounterUpdate());
                              onClose();
                            }}
                          >
                            Delete Marker
                          </button>
                        )}
                    </>
                  )
                )}
              </div>
            </>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <CommentBox id={marker.id} />
          </CustomTabPanel>
        </div>
      </div>
    </Modal>
  );
};

export default MetaDataModalLeaflet;
