import axios from "axios";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const Permissions = () => {
  const [permissions, setPermissions] = React.useState([]);
  const [reloader, setReloader] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    const getPermissions = async () => {
      const response = await axios.get(
        "https://delivery.inspect.indrones.com/api/v1/role_permission/get_all_permssions",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //   console.log("mohit", response);
      setReloader(reloader + 1);
      setPermissions(response.data);
    };
    getPermissions();
  }, [reloader]);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleAddPermission = async (event) => {
    event.preventDefault();
    const dataToPush = {
      name: event.target.permName.value,
      description: event.target.permDesc.value,
    };

    try {
      const response = await axios.post(
        `https://delivery.inspect.indrones.com/api/v1/role_permission/permission/create`,
        dataToPush
      );
      // console.log(" res", response);
      handleCloseModal();
    } catch (error) {
      console.error("err", error);
    }
  };

  return (
    <>
      {/* <div
        onClick={handleOpenModal}
        className="cursor-pointer w-max ml-auto mr-5 !rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
      >
        Add Permission
      </div> */}
      <div className="h-[calc(100%-60px)] w-[90%] mx-auto cursor-default m-4 overflow-y-auto overflow-x-auto custom-scrollbar">
        <table className="table w-full border-[1px] border-solid border-[#e5e7eb]">
          <thead className="bg-[#4a4844] text-[#fff] sticky top-0">
            <tr>
              <th className="px-2 py-3 font-[500]">Sr No</th>
              <th className="px-2 py-3 font-[500]">Name</th>
              <th className="px-2 py-3 font-[500]">Description</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {permissions.map((permission, index) => {
              return (
                <tr key={index}>
                  <td className="px-2 py-3">{index + 1}</td>
                  <td className="px-2 py-3">{permission.name}</td>
                  <td className="px-2 py-3">{permission.description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* <Modal open={isOpen} onClose={handleCloseModal}>
        <div
          style={style}
          className="flex flex-col items-center justify-between bg-white w-full h-max rounded-[12px] "
        >
          <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px] w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
            <h3 className="text-[1.20rem] text-[#211f1c] grow flex items-center justify-center">
              Add Permission
            </h3>
            <div className="mr-[10px]">
              <IconButton
                onClick={handleCloseModal}
                aria-label="Close"
                size="small"
                className="text-[#211f1c]"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <form
            className="grow w-full p-3 flex flex-col gap-3"
            onSubmit={handleAddPermission}
          >
            <div className="flex items-center justify-center w-full">
              <label className="w-[25%]">Name: </label>
              <TextField
                name="permName"
                size="small"
                className="grow"
                required
              />
            </div>

            <div className="flex items-center justify-center w-full">
              <label className="w-[25%]">Description: </label>
              <TextField
                name="permDesc"
                size="small"
                className="grow"
                required
              />
            </div>
            <div className="m-auto my-2">
              <button
                aria-label="add"
                className="!rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal> */}
    </>
  );
};

export default Permissions;
