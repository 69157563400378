export const formatCurrentDate = (timestamp) => {
  const currentDate = new Date(timestamp);

  // Extract day, month, and year components
  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so add 1
  const year = currentDate.getFullYear().toString().slice(-2); // Get the last two digits of the year

  const formattedDate = `${day}-${month}-${year}`;
  // console.log(formattedDate);
  return formattedDate;
};

export const getRouteID = (location) => {
  const currentRoute = location.pathname;
  const lastSlashIndex = currentRoute.lastIndexOf("/");
  const lastPart = currentRoute.substring(lastSlashIndex + 1);
  return lastPart.split("").map(Number);
};

export const filterMarks = (mark) => {
  if (mark) {
    for (let i = 0; i < mark.length; i++) {
      if (mark[i].tobeFiltered && mark[i].label) {
        if (parseFloat(mark[i].label) % 1.0 !== 0) {
          mark[i].label = null;
        } else {
          mark[i].label = parseInt(mark[i].label);
        }
      }
    }
  }
  return mark;
};

export const filterDuplicates = (array) => {
  const seen = {};
  const result = [];

  for (let i = array.length - 1; i >= 0; i--) {
    const item = array[i];
    const label = item.label;
    if (!seen.hasOwnProperty(label)) {
      seen[label] = item;
      result.unshift(item);
    }
  }

  return result;
};

export const convertTimeToSeconds = (timeString) => {
  if (timeString) {
    const timeParts = timeString.split(":");
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const seconds = parseInt(timeParts[2]);
    const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalTimeInSeconds;
  }
};

export const formatTime = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  const timeString = [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
  ].join(":");
  return timeString;
};

export const formatDate = (timestamp) => {
  const currentDate = new Date(timestamp);

  // Extract day, month, and year components
  const day = currentDate?.getDate().toString().padStart(2, "0");
  const month = (currentDate?.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so add 1
  const year = currentDate?.getFullYear().toString().slice(-2); // Get the last two digits of the year

  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

export const getDate = (inputDate) => {
  const parts = inputDate?.split("_");

  if (parts?.length === 3) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = parts[0];
    const monthIndex = parseInt(parts[1]) - 1;
    const year = `20${parts[2]}`;
    const formattedDate = `${months[monthIndex]} ${day}, ${year}`;

    return formattedDate;
  } else {
    return "Invalid date format";
  }
};
