import React, { useState } from "react";
import Logo from "../../Assets/images/ID_LOGO_BLACK-04.png";
import PowerSettingsNewRoundedIcon from "@mui/icons-material/PowerSettingsNewRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import { useLocation, useNavigate } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { getTokenFromCookie, removeAllCookies } from "../../Utils/Cookie";
import {
  faListSquares,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { userActions } from "../../redux/slices/user-slice";
import toast, { Toaster } from "react-hot-toast";
import CustomPopper from "../UI/CustomPopper";
import { adminSettings, logoutIco } from "../../Assets/icons/gisIcons/icons";
import SettingsModal from "./SettingsModal";
const getInitials = (firstname, lastName) => {
  const first = firstname.split("")[0].toUpperCase();
  const second = lastName.split("")[0].toUpperCase();
  return `${first}${second}`;
};

const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const cookieValue = Cookies.get("clientName");
  const firstName = useSelector((state) => state.userData.first_name);
  const lastName = useSelector((state) => state.userData.last_name);
  const role = useSelector((state) => state.userData.role);
  const [isHoveredOnLabel, setIsHoveredOnLabel] = useState(false);

  const handleLogout = () => {
    removeAllCookies();
    navigate("/");
    // cookieValue === "infra" ? navigate("/AGPLogin") : navigate("/");
  };

  const userId = Cookies.get("userId");

  React.useEffect(() => {
    var userObj = {};
    const getUserDetails = async () => {
      try {
        const response = await axios.get(
          `https://staging.incharge.indrones.com/get-user-detail/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getTokenFromCookie()}`,
            },
          }
        );
        userObj = response.data.user_obj;
        userObj.role = response.data.role.name;
        dispatch(userActions.setUser(userObj));
        // return response.data.user_obj;
      } catch (error) {
        console.error(error);
      }
    };
    getUserDetails();
  }, [userId]);

  const navigateProject = () => {
    navigate("/projects");
  };

  const navigateAsset = () => {
    navigate("/assets");
  };

  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const handleOpenSettingsModal = () => setOpenSettingsModal(true);
  const handleCloseSettings = () => setOpenSettingsModal(false);

  /** Popper for project_type */
  const [popoverPosition, setPopoverPosition] = useState({
    top: -10,
    left: 10,
  });

  const handleMouseEnter = (e) => {
    setIsHoveredOnLabel(true);
    setPopoverPosition({
      top: e.target.offsetTop - 70,
      left: e.target.offsetLeft + 40,
    });
  };

  const handleMouseLeave = () => {
    setIsHoveredOnLabel(false);
  };

  return (
    <div
      className={`bg-[#fff] max-[500px]:bg-[#fcfcfc] sidebr-project-dash items-start justify-between ${
        props.isDrawerOpen === true ? "sidebarshow " : ""
      }`}
    >
      <Toaster />
      <div className="max-[767px]:h-[10%] max-[767px]:flex-row-reverse flex justify-center max-[767px]:justify-between max-[767px]:items-center w-full max-[767px]:border-dashed max-[767px]:border-b-[2px] p-[5px] sidebarbuty">
        <div className="right-arrow-close hidden max-[767px]:block">
          <CloseRoundedIcon
            style={{ color: "#3a3a3c", fontSize: "26px" }}
            onClick={props.drawerClose}
          />
        </div>
        <img
          src={Logo}
          alt="logo"
          className="h-full min-[767px]:hidden menubarsiderlogo"
        />
      </div>
      <div className="grow mt-[3%] w-full">
        <div
          className={`mx-[12px] p-[10px] items-center cursor-pointer parent-text-nav ${
            location.pathname === "/projects" ? "parent-text-nav-before" : ""
          }`}
          onClick={() => {
            navigateProject();
            props.drawerClose();
          }}
        >
          <FontAwesomeIcon
            className="text-nav font-icon"
            icon={faListSquares}
          />
          <span className="text-nav  ml-[15px]">Projects</span>
        </div>
        {(cookieValue === "oil" || cookieValue === "rail") && (
          <div
            className={`mx-[12px] p-[10px]  items-center cursor-pointer parent-text-nav ${
              location.pathname === "/assets" ? "parent-text-nav-before" : ""
            }`}
            onClick={() => {
              navigateAsset();
              props.drawerClose();
            }}
          >
            <FontAwesomeIcon
              className="text-nav font-icon"
              icon={faFileContract}
            />
            <span className="text-nav ml-[13px]">Assets</span>
          </div>
        )}
      </div>
      <div className="h-[6%] w-[100%]  my-[12px] flex justify-between">
        <div className="flex mx-[12px] items-center p-[10px] tablet-portrait-mode">
          <div className="flex text-[27px] text-[#3a3a3c] font-[400]  justify-between item-center user-details max-[700]:text-[27px]">
            {<>{getInitials(firstName, lastName)}</>}
          </div>
          <div className="flex w-[100%] flex-col items-start">
            <span className="!text-[#3a3a3c] font-[600] tracking-[0.4px] text-[15px] tablet-portrait-mode-username">
              {firstName} {lastName}
            </span>
            <span className="text-[#bdbdbd] font-[400] tracking-[0.4px] text-[12px]">
              {role}
            </span>
          </div>
        </div>
        <div
          className=" cursor-pointer  flex items-center hover:rotate-45 mr-2 p-1 rounded-full
          "
          onMouseEnter={handleMouseEnter}
        >
          <SettingsIcon className="!text-[#3a3a3c] text-[14px] tablet-portrait-mode-icon" />
        </div>

        <CustomPopper isVisible={isHoveredOnLabel} position={popoverPosition}>
          <div
            onMouseLeave={handleMouseLeave}
            className=" w-52 text-sm text-justify"
          >
            {role === "Developer" && (
              <div
                className="flex items-center gap-4 cursor-pointer p-1 px-2 rounded-md hover:bg-yellow-200 "
                onClick={() => {
                  navigate("/setting");
                }}
              >
                <img src={adminSettings} className="h-6" alt="" srcSet="" />
                <p className="tracking-wide text-lg font-semibold ">
                  Admin settings
                </p>
              </div>
            )}
            <div
              className="flex items-center gap-4 cursor-pointer p-1 px-2 rounded-md hover:bg-red-200 "
              onClick={() => {
                handleLogout();
              }}
            >
              <img src={logoutIco} className="h-6" alt="" srcSet="" />
              <p className=" tracking-wide text-lg font-semibold">Logout</p>
            </div>
          </div>
        </CustomPopper>
        <SettingsModal open={openSettingsModal} onClose={handleCloseSettings} />
      </div>
    </div>
  );
};

export default Sidebar;
