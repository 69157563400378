import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/user-slice";
import mapToggleReducer from "../slices/Videography/map-toggle-slice";
import FilterToggleReducer from "../slices/Videography/filter-toggle-slice";
import ProjectReducer from "../slices/Videography/set-project-slice";
import gisHomeReducer from "../slices/GIS/gis-home-slice";
import gisToolsReducer from "../slices/GIS/gis-tools-slice";
import gisReducer from "../slices/GIS/gis-slice";
import gisLeafletReducer from "../slices/GIS/gis-leaflet-slice";

const rootStore = configureStore({
  reducer: {
    userData: userReducer,
    gisHome: gisHomeReducer,
    gis: gisReducer,
    gisTools: gisToolsReducer,
    mapToggle: mapToggleReducer,
    filterToggle: FilterToggleReducer,
    project: ProjectReducer,
    gisLeaflet: gisLeafletReducer,
  },
});

export default rootStore;
