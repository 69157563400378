import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import Tooltip from "@mui/material/Tooltip";
import Cookies from "js-cookie";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  layersFilledIco,
  layersOutlinedIco,
  previewFilledIco,
  previewOutlinedIco,
  reportsFilled,
  reportsOutlined,
} from "../../../Assets/icons/gisIcons/icons";
import { inspectLogo } from "../../../Assets/images/gisImg/images";
import { gisHomeActions } from "../../../redux/slices/GIS/gis-home-slice";
import SidebarNavItem from "../../UI/GIS/SidebarNavItem";
import AnnotationsLeaflet from "./AnnotationsLeaflet";
import BrandingAndStatsBarLeaflet from "./BrandingAndStatsBarLeaflet";
import IndexingLeaflet from "./IndexingLeaflet";
import ReportTableL from "./ReportTableL";
import SidebarLayersLeaflet from "./SidebarLayersLeaflet";

const GISSidebarLeaflet = ({ mapRef }) => {
  const user_permissions = Cookies.get("user_permissions")?.split(",");
  const navigate = useNavigate();
  const viewerRef = useRef(null);
  const [openAssetIndexing, setOpenAssetIndexing] = useState(false);
  const [openObservation, setOpenObservations] = useState(false);
  const [openLayers, setOpenLayers] = useState(false);
  const { grp } = useParams();

  // redux
  const dispatch = useDispatch();
  const { showComparer } = useSelector((state) => state.gisTools);
  const { bottomReportToggle } = useSelector((state) => state.gisHome);

  /**
   * Handles toggling of sidebar navigation items.
   * @param {string} item - The name of the item to toggle. Can be one of: "sidebar", "observations", "layers".
   */
  const handleToggle = (item) => {
    switch (item) {
      case "indexing":
        setOpenAssetIndexing((preState) => !preState);
        setOpenObservations(false);
        setOpenLayers(false);
        dispatch(gisHomeActions.bottomReportToggle(false));

        break;
      case "observations":
        setOpenAssetIndexing(false);
        setOpenObservations((prevState) => !prevState);
        setOpenLayers(false);
        dispatch(gisHomeActions.bottomReportToggle(false));

        break;
      case "layers":
        setOpenAssetIndexing(false);
        setOpenObservations(false);
        dispatch(gisHomeActions.bottomReportToggle(false));
        setOpenLayers((prevState) => !prevState);
        break;
      case "reports":
        setOpenAssetIndexing(false);
        setOpenObservations(false);
        setOpenLayers(false);
        dispatch(gisHomeActions.bottomReportToggle());
        break;
      default:
        setOpenAssetIndexing(false);
        setOpenObservations(false);
        setOpenLayers(false);
        break;
    }
  };
  return (
    <div
      id="GISSidebarBodyLeaflet"
      className="flex flex-row  absolute top-0 left-0  h-[100vh] bg-white z-[500] rounded-tr-lg "
    >
      {!showComparer && (
        <div className="flex flex-row  relative top-0 left-0  h-[100vh] bg-white rounded-tr-lg">
          <div className="flex flex-col gap-2 p-2  min-w-max  ">
            <div
              className="flex items-center justify-center rounded-[5px] cursor-pointer mb-2"
              onClick={() => navigate("/projects")}
            >
              <Tooltip title="Go to Home" arrow placement="bottom">
                <img src={inspectLogo} className="w-[52px]" alt="logo" />
              </Tooltip>
            </div>
            <SidebarNavItem
              title="Sections"
              onClick={() => handleToggle("indexing")}
              isActive={openAssetIndexing}
            >
              <FormatListBulletedRoundedIcon style={{ color: "#4a4844" }} />
            </SidebarNavItem>
            {grp !== "ESSEL" && (
              <SidebarNavItem
                title="Observations"
                onClick={() => handleToggle("observations")}
                isActive={openObservation}
              >
                {openObservation ? (
                  <img src={previewFilledIco} alt="Close Observations" />
                ) : (
                  <img src={previewOutlinedIco} alt="Open observatiosn" />
                )}
              </SidebarNavItem>
            )}
            <SidebarNavItem
              title="Layers"
              onClick={() => handleToggle("layers")}
              isActive={openLayers}
            >
              {openLayers ? (
                <img src={layersFilledIco} alt="Close Layers" />
              ) : (
                <img src={layersOutlinedIco} alt="Open Layers" />
              )}
            </SidebarNavItem>
            <SidebarNavItem
              title="Reports"
              onClick={() => handleToggle("reports")}
              isActive={bottomReportToggle}
            >
              {bottomReportToggle ? (
                <img src={reportsFilled} alt="Close Reports" />
              ) : (
                <img src={reportsOutlined} alt="Open reports" />
              )}
            </SidebarNavItem>

            <Tooltip title="Back" placement="bottom" arrow>
              <div
                className=" absolute bottom-5 left-4 hover:left-2 hover:bg-grey-200 p-1 hover:rounded-full"
                onClick={() => navigate("/projects")}
              >
                <ArrowBackIosRoundedIcon style={{ color: "#4a4844" }} />
              </div>
            </Tooltip>
          </div>
          <div className="flex flex-col  h-[100vh]  rounded-lg focus:border focus:border-b-2 ">
            <IndexingLeaflet grp={grp} openAssetIndexing={openAssetIndexing} />
            <AnnotationsLeaflet
              viewer={viewerRef.current}
              openObservation={openObservation}
              mapRef={mapRef}
            />
            <SidebarLayersLeaflet openLayers={openLayers} mapRef={mapRef} />
          </div>
        </div>
      )}
      <ReportTableL
        isSidebaropen={openAssetIndexing}
        openShapes={openObservation}
        openLayers={openLayers}
        mapRef={mapRef}
      />
      <BrandingAndStatsBarLeaflet
        isSidebaropen={openAssetIndexing}
        openShapes={openObservation}
        openLayers={openLayers}
        map={mapRef.current}
      />
    </div>
  );
};

export default GISSidebarLeaflet;
