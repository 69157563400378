import { createSlice } from "@reduxjs/toolkit";

const gisLeafletSlice = createSlice({
  name: "gisLeaflet",
  initialState: {
    refCounterMeasure: 0,
    avaliableRasterAssets: [],
    compareRasterLayers: { leftLayer: "", rightLayer: "" },
    vectorMeasurentLayers: [],
    measurementArray: [],
    KmlsetArray: [], // New array for KML layers
    visibleLayers: [], // New array for visible layers
    asset: null,
    mapRef: null,
    opacityOfTimeline: null,
    // leaflet assets (annotations )
    observationsBucket: [],
    observationsVisibilityStatus: [],
    IsWMSAssetLoading: false,
  },
  reducers: {
    refCounterMeasureUpdate: (state) => {
      state.refCounterMeasure += 1;
    },
    setAvaliableRasterAssets: (state, action) => {
      if (!state.avaliableRasterAssets.includes(action.payload)) {
        state.avaliableRasterAssets = [
          ...state.avaliableRasterAssets,
          action.payload,
        ];
      }
    },
    setCompareRasterLayers: (state, action) => {
      state.compareRasterLayers = action.payload;
    },

    setAsset: (state, action) => {
      state.asset = action.payload;
    },
    setVectorMeasurentLayers: (state, action) => {
      state.vectorMeasurentLayers = [
        ...state.vectorMeasurentLayers,
        action.payload,
      ];
    },
    removeLayer: (state, action) => {
      const indexToRemove = state.vectorMeasurentLayers.findIndex(
        (layer) => layer.id === action.payload.id
      );
      if (indexToRemove !== -1) {
        state.vectorMeasurentLayers.splice(indexToRemove, 1);
      }
    },
    setmeasurementArray: (state, action) => {
      state.measurementArray = [...state.measurementArray, action.payload]; // Add new item to measurementArray
    },
    removeItemFromMeasurementArrayy: (state, action) => {
      const indexToRemove = state.measurementArray.findIndex(
        (layer) => layer.id === action.payload.id
      );
      if (indexToRemove !== -1) {
        state.measurementArray.splice(indexToRemove, 1);
      }
    },
    // New reducers for KmlsetArray
    setKmlsetArray: (state, action) => {
      state.KmlsetArray = [...state.KmlsetArray, action.payload];
    },
    removeKmlLayer: (state, action) => {
      state.KmlsetArray = state.KmlsetArray.filter(
        (kml) => kml.id !== action.payload
      );
    },

    // removeKmlLayer: (state, action) => {
    //   const indexToRemove = state.KmlsetArray.findIndex(
    //     (layer) => layer.id === action.payload.id
    //   );
    //   if (indexToRemove !== -1) {
    //     state.KmlsetArray.splice(indexToRemove, 1);
    //   }
    // },
    clearKmlsetArray: (state) => {
      state.KmlsetArray = [];
    },
    toggleVisibility: (state, action) => {
      const id = action.payload;
      const measureIndex = state.KmlsetArray.findIndex(
        (measure) => measure.id === id
      );
      // Check if measureIndex is valid
      if (measureIndex !== -1 && state.KmlsetArray[measureIndex]) {
        state.KmlsetArray[measureIndex].visible =
          !state.KmlsetArray[measureIndex].visible;
      }
    },
    setMapRef: (state, action) => {
      state.mapRef = action.payload;
    },
    setOpacityOfTimeline: (state, action) => {
      state.opacityOfTimeline = action.payload;
    },
    // New reducer to add item to visibleLayers array
    addItemToVisibleLayers: (state, action) => {
      state.visibleLayers.push(action.payload);
    },
    // New reducer to toggle layer visibility
    toggleLayerVisibility: (state, action) => {
      const { index } = action.payload;
      state.visibleLayers[index] = !state.visibleLayers[index];
    },
    // New reducer to remove item from visibleLayers array
    removeItemFromVisibleLayers: (state, action) => {
      const { index } = action.payload;
      state.visibleLayers.splice(index, 1);
    },

    // leaflet assets (annotations) reducers
    setObservationsBucket: (state, action) => {
      state.observationsBucket = action.payload;
    },
    setObservationsVisibilityStatus: (state, action) => {
      state.observationsVisibilityStatus = action.payload;
    },

    setIsWMSAssetLoading: (state, action) => {
      state.IsWMSAssetLoading = action.payload;
    },
  },
});

export const gisLeafletActions = gisLeafletSlice.actions;

export default gisLeafletSlice.reducer;
