import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  railway,
  railwayjpg,
  OilGas,
  OilGasicon,
  infrastructureicon,
  Infrastructure,
  Municipality,
  Municipalityicon,
  Smart,
  Smarticon,
  landrecondicon,
  landrecond,
  highwaysIco,
  highwaysImg,
  miningImg,
  miningIco,
  Forestry,
} from "../../Assets/images/servicesImg/services";
import inspecticon from "../../Assets/images/inspect-logo-transparent-png.png";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { forestryIco } from "../../Assets/images/gisImg/images";
const services = [
  { name: "Railways", id: "rail", icon: railway, src: railwayjpg },
  { name: "Oil & Gas", id: "hmel", icon: OilGasicon, src: OilGas },

  {
    name: "Infrastructure",
    id: "infra",
    icon: infrastructureicon,
    src: Infrastructure,
  },
  {
    name: "Forestry",
    id: "forest",
    icon: forestryIco,
    src: Forestry,
  },
  {
    name: "Municipality",
    id: "mnc",
    icon: Municipalityicon,
    src: Municipality,
  },

  { name: "Smart City", id: "scity", icon: Smarticon, src: Smart },
  { name: "Land Records", id: "lrec", icon: landrecondicon, src: landrecond },
  { name: "Mining", id: "mine", icon: miningIco, src: miningImg },
];
const servicesNhai = [
  { name: "Railways", id: "rail", icon: railway, src: railwayjpg },
  { name: "Oil & Gas", id: "oil", icon: OilGasicon, src: OilGas },
  { name: "Highways", id: "hway", icon: highwaysIco, src: highwaysImg },
  {
    name: "Infrastructure",
    id: "infra",
    icon: infrastructureicon,
    src: Infrastructure,
  },
  {
    name: "Municipality",
    id: "mnc",
    icon: Municipalityicon,
    src: Municipality,
  },
  { name: "Smart City", id: "scity", icon: Smarticon, src: Smart },
  { name: "Land Records", id: "lrec", icon: landrecondicon, src: landrecond },
];

const Service = () => {
  const currentHostname = window.location.hostname;
  const subdomain = currentHostname?.split(".")[0];
  var servicesToLoad = subdomain === "nhai" ? servicesNhai : services;
  const navigate = useNavigate();
  const servicelistRef = useRef(null);
  const setClient = (service) => {
    Cookies.set("clientName", service);
    navigate("/login");
  };
  const scrollTo = (scrollOffset) => {
    const element = servicelistRef.current;
    const scrollLeft = element.scrollLeft;
    element.scrollTo({
      left: scrollLeft + scrollOffset,
      behavior: "smooth",
    });
  };
  return (
    <div className="h-full w-full relative bg-[#ffc700]">
      <div className="h-[50%] service-page-bg flex flex-col">
        <div className="heading-service">
          <img src={inspecticon} className="w-[45%]" alt="Error" />
        </div>
      </div>

      <div className="w-[100%] flex justify-center">
        <div
          className="scroll-button bottom-left"
          onClick={() => scrollTo(-150)} // Scroll left
        >
          <ArrowBackIosNewRoundedIcon />
        </div>
        <div
          ref={servicelistRef}
          className="servicelist flex flex-row gap-[9px]"
        >
          {servicesToLoad.map((service, index) => {
            return (
              <>
                <div
                  key={index}
                  onClick={() => setClient(service.id)}
                  className="cursor-pointer flex flex-col justify-center items-center bg-[#fff] bg-card-sev  gap-3 rounded-[5px]"
                >
                  <div className="bg-[#4a4844] flex justify-center items-center rounded-full w-[70px] p-3 mt-[20px]">
                    <img src={service.icon} alt="Error" />
                  </div>
                  <div className="text-[23px] flex font-[500] ">
                    {service.name}
                  </div>
                  <div className="mt-[15px] w-full h-[190px]">
                    <img
                      className="rounded-b-[5px] h-[190px] w-full"
                      src={service.src}
                      alt="Error"
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div
          className="scroll-button bottom-right"
          onClick={() => scrollTo(150)} // Scroll right
        >
          <ArrowForwardIosRoundedIcon />
        </div>
      </div>
    </div>
  );
};

export default Service;
