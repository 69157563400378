import { useQuery, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "../../Assets/styles/GISStyle.css";
import GISLeaflet from "../../Components/GIS/Leaflet/GISLeaflet";
import GISSidebarLeaflet from "../../Components/GIS/Leaflet/GISSidebarLeaflet";
import { DynamoDBApisL } from "../../Services/ThirdPartyApi/AWS/DynamoDBL";
import { tokenChecker } from "../../Utils/Cookie";
import { gisHomeActions } from "../../redux/slices/GIS/gis-home-slice";
import { gisLeafletActions } from "../../redux/slices/GIS/gis-leaflet-slice";
import { gisActions } from "../../redux/slices/GIS/gis-slice";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { useParams } from "react-router-dom";
import {
  BaseLayerIcon,
  GoogleSateliteMapIcon,
  GoogleStreetMapIcon,
  OpenTopoMapIcon,
} from "../../Assets/icons/gisIcons/icons";

const GISHomeLeaflet = () => {
  const user_permissions = Cookies.get("user_permissions")?.split(",");
  const siteName = Cookies.get("start_location");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { asset } = useSelector((state) => state.gisLeaflet);
  const { refCounter } = useSelector((state) => state.gis);
  const queryClient = useQueryClient();
  const [mapInitialized, setMapInitialized] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [theme, setTheme] = useState(false);
  const [contentExpanded, setContentExpanded] = useState(false);
  const [baseLayer, setBaseLayer] = useState("GoogleStreetMap");
  const [closingTimeout, setClosingTimeout] = useState(null);
  const { grp: clientName } = useParams();

  useEffect(() => {
    const map = L.map("map").setView([19.259314789, 73.142241885], 13);
    mapRef.current = map;
    googleMapLayer.addTo(mapRef.current);
    setMapInitialized(true);
    setBaseLayer("GoogleStreetMap");
    // console.log(map.cu);
    return () => {
      map.remove();
    };
  }, []);

  useEffect(() => {
    dispatch(gisHomeActions.setShapesIds([]));

    Cookies.set("clientKey", clientName.toLowerCase());
    dispatch(gisActions.setUserPermissions(user_permissions));
    document.title = "Inspect";
  }, []);

  useEffect(() => {
    tokenChecker(navigate);
  }, [navigate]);

  const mapRef = useRef(null);

  const googleMapLayer = L.tileLayer(
    "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
    {
      maxZoom: 18,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
      name: "baselayer",
    }
  );

  const googleSatelliteLayer = L.tileLayer(
    "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
    {
      attribution: "Google Maps Satellite",
      maxNativeZoom: 20,
      maxZoom: 20,
      name: "baselayer",
    }
  );

  const openTopoMapLayer = L.tileLayer(
    "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 20,
      name: "baselayer",
    }
  );

  const openstreetmap = L.tileLayer(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 20,
      name: "baselayer",
    }
  );

  const handleBaseLayerChange = (layerName) => {
    // if (layerName === "OpenTopoMap") {
    //   setTheme(false);
    // } else {
    //   setTheme(false);
    // }
    setBaseLayer(layerName);
    let selectedLayer;
    switch (layerName) {
      case "GoogleStreetMap":
        selectedLayer = googleMapLayer;
        break;
      case "GoogleSatellite":
        selectedLayer = googleSatelliteLayer;
        break;
      case "OpenTopoMap":
        selectedLayer = openTopoMapLayer;
        break;
      case "OpenStreetMap":
        selectedLayer = openstreetmap;
        break;
      default:
        selectedLayer = googleMapLayer;
    }

    // Remove existing Baselayer
    mapRef.current.eachLayer((layer) => {
      if (layer.options.name === "baselayer") {
        mapRef.current.removeLayer(layer);
      }
    });
    // Add the selected layer to the map
    selectedLayer.addTo(mapRef.current);
    // Close the expanded options after selecting a layer
    setExpanded(false);
    setExpanded(false); // Close the expanded options after selecting a layer
  };

  const hoverColor = "#a0aab46b";

  const imgStyle = theme ? { filter: "invert(100%)" } : {};

  const fetchAssetRelatedObservations = async (id) => {
    const retrievedData = await DynamoDBApisL.getDataByID(id);
    if (retrievedData === undefined) {
      throw new Error("Data retrieval failed or returned undefined");
    }
    return retrievedData;
  };

  const { status, data, error } = useQuery({
    queryKey: ["assetRelatedObservations", asset?.Name],
    queryFn: () => asset?.Name && fetchAssetRelatedObservations(asset?.Name),
  });

  useEffect(() => {
    if (asset) {
      // Invalidate queries to ensure data freshness
      queryClient.invalidateQueries({
        queryKey: ["assetRelatedObservations", asset.Name],
      });

      // Update observations bucket if data is available
      if (data) {
        dispatch(gisLeafletActions.setObservationsBucket(data));
        const modifiedObservations = data.map((observation) => {
          return {
            id: observation.id,
            visible: observation.visible,
          };
        });

        dispatch(
          gisLeafletActions.setObservationsVisibilityStatus(
            modifiedObservations
          )
        );
      }
    }
  }, [asset, data, refCounter]);

  return (
    <>
      <div className="baselayerPicker absolute  right-2  top-1   z-[500]">
        <div className="relative flex z-[500] ">
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            direction="left"
            sx={{
              "& .MuiSpeedDial-fab": {
                height: 40,
                width: 40,
                boxShadow: "none",
              },
            }}
            icon={
              <img
                style={imgStyle}
                className={`w-10 h-10 rounded-[50%] border-2 border-[#303336] hover:border-[#48b] transition-transform duration-300 ease-in-out transform-gpu hover:scale-110 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
                src={
                  baseLayer === "GoogleStreetMap"
                    ? GoogleStreetMapIcon
                    : baseLayer === "GoogleSatellite"
                    ? GoogleSateliteMapIcon
                    : baseLayer === "OpenTopoMap"
                    ? OpenTopoMapIcon
                    : BaseLayerIcon
                }
                alt=""
              />
            }
          >
            <SpeedDialAction
              key="Google Street Map"
              arrow
              tooltipTitle="Google Street Map"
              onClick={() => handleBaseLayerChange("GoogleStreetMap")}
              icon={
                <img
                  className={`w-10 h-10 rounded-[50%] border-2 border-[#303336] hover:border-[#48b] transition-transform duration-300 ease-in-out transform-gpu hover:scale-110 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
                  src={GoogleStreetMapIcon}
                  alt="Google Street Map"
                />
              }
              sx={{
                boxShadow: "none",
              }}
            ></SpeedDialAction>
            <SpeedDialAction
              key="Google Satellite"
              arrow
              tooltipTitle="Google Satellite"
              onClick={() => handleBaseLayerChange("GoogleSatellite")}
              icon={
                <img
                  className={`w-10 h-10 rounded-[50%] border-2 border-[#303336] hover:border-[#48b] transition-transform duration-300 ease-in-out transform-gpu hover:scale-110`}
                  src={GoogleSateliteMapIcon}
                  alt="Google Satellite"
                />
              }
              sx={{
                boxShadow: "none",
              }}
            ></SpeedDialAction>
            <SpeedDialAction
              key="OpenTopoMap"
              arrow
              tooltipTitle="OpenTopoMap"
              onClick={() => handleBaseLayerChange("OpenTopoMap")}
              icon={
                <img
                  className={`w-10 h-10 rounded-[50%] border-2 border-[#303336] hover:border-[#48b] transition-transform duration-300 ease-in-out transform-gpu hover:scale-110`}
                  src={OpenTopoMapIcon}
                  alt="OpenTopoMap"
                />
              }
              sx={{
                boxShadow: "none",
              }}
            ></SpeedDialAction>
          </SpeedDial>
        </div>
      </div>
      <div id="map" className="h-screen w-full"></div>
      {mapInitialized && <GISLeaflet mapRef={mapRef} />}
      {mapInitialized && <GISSidebarLeaflet mapRef={mapRef} />}
    </>
  );
};

export default GISHomeLeaflet;
