import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const gisToolsSlice = createSlice({
  name: "gis-tools",
  initialState: {
    selectedTool: null,
    showComparer: false,
    annotationToggle: false,
    annotationTool: false,
    polygonAnnotation: false,
    polylineAnnotation: false,
    measureToolToggle: false,
    lineMeasureTool: false,
    polylineMeasureTool: false,
    polygonMeasureTool: false,
    unitsIsOpen: false,
  },
  reducers: {
    setselectedTool(state, action) {
      if (state.selectedTool === action.payload) {
        state.selectedTool = null;
      } else {
        state.selectedTool = action.payload;
      }
    },

    annotationToggle(state, action) {
      state.annotationToggle = action.payload;
    },
    measureToggle(state, action) {
      state.measureToolToggle = action.payload;
    },

    turnOffTools(state) {
      state.annotationToggle = false;
      state.measureToolToggle = false;
      state.annotationTool = false;
      state.polygonAnnotation = false;
      state.polylineAnnotation = false;
      state.lineMeasureTool = false;
      state.polylineMeasureTool = false;
      state.polygonMeasureTool = false;
    },

    toggleTools(state, action) {
      const toolName = action.payload;

      if (toolName === "showComparer") {
        state.measureToolToggle = false;
        state.annotationTool = false;
        state.polygonAnnotation = false;
        state.polylineAnnotation = false;
        state.showComparer = !state.showComparer;

        if (state.showComparer === false) {
          toast.error("Comparer has been turned off");
        } else {
          toast.success(
            "Comparer is turned on, select assets/timelines which you want ot compare."
          );
        }

        if (state.annotationToggle) {
          state.annotationToggle = false;
        }
      } else if (toolName === "annotationTool") {
        state.showComparer = false;
        state.measureToolToggle = false;
        state.polygonAnnotation = false;
        state.polylineAnnotation = false;

        state.annotationTool = !state.annotationTool;

        if (state.annotationTool === false) {
          toast.error("Marker tool has been turned off");
        } else {
          toast.success("Point marker tool activated.");
        }
      } else if (toolName === "polygonAnnotationTool") {
        state.annotationToggle = false;
        state.measureToolToggle = false;
        state.showComparer = false;
        state.measureToolToggle = false;
        state.annotationTool = false;
        state.polylineAnnotation = false;
        state.lineMeasureTool = false;
        state.polylineMeasureTool = false;
        state.polygonMeasureTool = false;
        state.polygonAnnotation = !state.polygonAnnotation;

        if (state.polygonAnnotation === false) {
          toast.error("Polygon marker tool has been turned off");
        } else {
          toast.success("Polygon marker tool activated.");
        }
      } else if (toolName === "polylineAnnotationTool") {
        state.showComparer = false;
        state.annotationToggle = false;
        state.measureToolToggle = false;
        state.annotationTool = false;
        state.polygonAnnotation = false;
        state.lineMeasureTool = false;
        state.polylineMeasureTool = false;
        state.polygonMeasureTool = false;
        state.polylineAnnotation = !state.polylineAnnotation;

        if (state.polylineAnnotation === false) {
          toast.error("Polyline marker tool has been turned off");
        } else {
          toast.success("Polyline marker tool activated.");
        }
      } else if (toolName === "polylineMeasureTool") {
        state.showComparer = false;
        state.annotationToggle = false;
        state.measureToolToggle = false;
        state.annotationTool = false;
        state.polygonAnnotation = false;
        state.polylineAnnotation = false;
        state.lineMeasureTool = false;
        state.polygonMeasureTool = false;
        state.polylineMeasureTool = !state.polylineMeasureTool;

        if (state.polylineMeasureTool === false) {
          toast.error("Measure distance tool has been turned off");
        } else {
          toast.success("Measure distance tool activated.");
        }
      } else if (toolName === "polygonMeasureTool") {
        state.showComparer = false;
        state.annotationToggle = false;
        state.measureToolToggle = false;
        state.annotationTool = false;
        state.polygonAnnotation = false;
        state.polylineAnnotation = false;
        state.lineMeasureTool = false;
        state.polylineMeasureTool = false;
        state.polygonMeasureTool = !state.polygonMeasureTool;

        if (state.polygonMeasureTool === false) {
          toast.error("Measure area tool has been turned off");
        } else {
          toast.success("Measure area tool activated.");
        }
      } else if (toolName === "lineMeasureTool") {
        state.showComparer = false;
        state.annotationToggle = false;
        state.measureToolToggle = false;
        state.annotationTool = false;
        state.polygonAnnotation = false;
        state.polylineAnnotation = false;
        state.polylineMeasureTool = false;
        state.polygonMeasureTool = false;
        state.lineMeasureTool = !state.lineMeasureTool;

        if (state.lineMeasureTool === false) {
          toast.error("Line Measure has been turned off");
        } else {
          toast.success("Line measure activated.");
        }
      }
    },

    setUnitIsOpen(state) {
      state.unitsIsOpen = !state.unitsIsOpen;
    },
  },
});

export const gisToolActions = gisToolsSlice.actions;

export default gisToolsSlice.reducer;
