import { Category } from "@mui/icons-material";

export const observationRisks = ["Low", "Medium", "High"];

export const observationsAndCategories = [
  { category: "Asset", type: "KM board" },
  { category: "Asset", type: "Warning board" },
  { category: "Asset", type: "Vent pipe" },
  { category: "Asset", type: "TLP" },
  { category: "Discolouration", type: "Discolouration" },
  { category: "Encroachment", type: "Building" },
  { category: "Encroachment", type: "Excavation area/digging" },
  { category: "Encroachment", type: "HT tower" },
  { category: "Encroachment", type: "Pylon" },
  { category: "Encroachment", type: "Structure" },
  { category: "Encroachment", type: "Tree" },
  { category: "Encroachment", type: "Debris" },
  { category: "Encroachment", type: "Water body" },
  { category: "Encroachment", type: "Electric poles" },
  { category: "Encroachment", type: "Burnt ground" },
  { category: "Encroachment", type: "Borewell" },
];

export const observationTypes = [
  "KM board",
  "Warning board",
  "Vent pipe",
  "TLP",
  "Discolouration",
  "Building",
  "Excavation area/digging",
  "HT tower",
  "Pylon",
  "Structure",
  "Tree",
  "Debris",
  "Water body",
  "Electric poles",
  "Burnt ground",
  "Borewell",
];

export const observationCategories = [
  "Asset",
  "Discolouration",
  "Encroachment",
];

export const observationTaskStatus = [
  "Yet to start",
  "Started",
  "On Hold",
  "Legal action Initiated",
  "Resolved with permanent fix",
  "Resolved with temporary fix",
];

export const CSStationsList = [
  {
    name: "CS01",
    stationName: "Kakinada",
    state: "AP",
    latitude: 16.7465,
    longitude: 82.2995,
  },
  {
    name: "CS02",
    stationName: "Eluru",
    state: "AP",
    latitude: 16.7531,
    longitude: 81.0127,
  },
  {
    name: "CS03",
    stationName: "Suryapet",
    state: "TS",
    latitude: 17.1122,
    longitude: 79.7643,
  },
  {
    name: "CS04",
    stationName: "Pudur",
    state: "TS",
    latitude: 17.6132,
    longitude: 78.5347,
  },
  {
    name: "CS05",
    stationName: "Humnabad",
    state: "KA",
    latitude: 17.8191,
    longitude: 77.1709,
  },
  {
    name: "CS06",
    stationName: "Barshi",
    state: "MH",
    latitude: 18.261,
    longitude: 75.7486,
  },
  {
    name: "CS07",
    stationName: "Shirur",
    state: "MH",
    latitude: 18.6367,
    longitude: 74.3815,
  },
  {
    name: "CS08",
    stationName: "Kalyan",
    state: "MH",
    latitude: 19.2467,
    longitude: 73.2493,
  },
  {
    name: "CS09",
    stationName: "Valsad",
    state: "GJ",
    latitude: 20.5837,
    longitude: 73.0242,
  },
  {
    name: "CS10",
    stationName: "Bharuch",
    state: "GJ",
    latitude: 21.679,
    longitude: 72.8293,
  },
];

export const HMELDates = [
  {
    name: "HMEL_ORTHO_13-04-2024_382_TO_363_COG",
    timeline: "2024/04/13",
    id: 1,
    nameToShow: "13 April 2024",
    type: "ORI",
    chainage: {
      start: 363,
      end: 382,
    },
  },
  {
    name: "HMEL_ORTHO_13-04-2024_70_TO_78",
    timeline: "2024/04/13",
    id: 2,
    nameToShow: "13 April 2024",
    type: "ORI",
    chainage: {
      start: 70,
      end: 78,
    },
  },
  {
    name: "HMEL_ORTHO_14-04-2024_362_TO_345_COG",
    timeline: "2024/04/14",
    id: 3,
    nameToShow: "14 April 2024",
    type: "ORI",
    chainage: {
      start: 345,
      end: 362,
    },
  },
  {
    name: "HMEL_ORTHO_14-04-2024_362_TO_352_COG",
    timeline: "2024/04/14",
    id: 4,
    nameToShow: "14 April 2024",
    type: "ORI",
    chainage: {
      start: 352,
      end: 362,
    },
  },
  {
    name: "HMEL_ORTHO_14-04-2024_78_TO_96",
    timeline: "2024/04/14",
    id: 5,
    nameToShow: "14 April 2024",
    type: "ORI",
    chainage: {
      start: 78,
      end: 96,
    },
  },
  {
    name: "HMEL_ORTHO_15-04-2024_344_TO_327_COG",
    timeline: "2024/04/15",
    id: 6,
    nameToShow: "15 April 2024",
    type: "ORI",
    chainage: {
      start: 327,
      end: 344,
    },
  },
  {
    name: "HMEL_ORTHO_15-04-2024_96.3_TO_115",
    timeline: "2024/04/15",
    id: 7,
    nameToShow: "15 April 2024",
    type: "ORI",
    chainage: {
      start: 96.3,
      end: 115,
    },
  },
  {
    name: "HMEL_ORTHO_16-04-2024_115",
    timeline: "2024/04/16",
    id: 8,
    nameToShow: "16 April 2024",
    type: "ORI",
    chainage: {
      start: 115,
      end: null, // Assuming end is null as it's not provided
    },
  },
  {
    name: "HMEL_ORTHO_17-04-2024_148",
    timeline: "2024/04/17",
    id: 9,
    nameToShow: "17 April 2024",
    type: "ORI",
    chainage: {
      start: 148,
      end: null, // Assuming end is null as it's not provided
    },
  },
  {
    name: "HMEL_ORTHO_18-04-2024_169_TO_174",
    timeline: "2024/04/18",
    id: 10,
    nameToShow: "18 April 2024",
    type: "ORI",
    chainage: {
      start: 169,
      end: 174,
    },
  },
  {
    name: "HMEL_ORTHO_18-04-2024_275_TO_261",
    timeline: "2024/04/18",
    id: 11,
    nameToShow: "18 April 2024",
    type: "ORI",
    chainage: {
      start: 261,
      end: 275,
    },
  },
  {
    name: "HMEL_ORTHO_19-04-2024_185_TO_200",
    timeline: "2024/04/19",
    id: 12,
    nameToShow: "19 April 2024",
    type: "ORI",
    chainage: {
      start: 185,
      end: 200,
    },
  },
  {
    name: "HMEL_ORTHO_19-04-2024_261_TO_246_COG",
    timeline: "2024/04/19",
    id: 13,
    nameToShow: "19 April 2024",
    type: "ORI",
    chainage: {
      start: 246,
      end: 261,
    },
  },
  {
    name: "HMEL_ORTHO_20-04-2024_205.1_TO215",
    timeline: "2024/04/20",
    id: 14,
    nameToShow: "20 April 2024",
    type: "ORI",
    chainage: {
      start: 205.1,
      end: 215,
    },
  },
  {
    name: "HMEL_ORTHO_20-04-2024_245_TO_224",
    timeline: "2024/04/20",
    id: 15,
    nameToShow: "20 April 2024",
    type: "ORI",
    chainage: {
      start: 224,
      end: 245,
    },
  },
];

// export const HMELDates = [
//   {
//     name: "HMEL_ORTHO_13-04-2024_382_TO_363_COG",
//     timeline: "2024/04/13",
//     id: 1,
//     nameToShow: "13 April 2024",
//     type: "ORI",
//     chainage :{
//       start : 363 ,
//       end : 382
//     }
//   },
//   {
//     name: "HMEL_ORTHO_13-04-2024_70_TO_78",
//     timeline: "2024/04/13",
//     id: 2,
//     nameToShow: "13 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_14-04-2024_362_TO_345_COG",
//     timeline: "2024/04/14",
//     id: 3,
//     nameToShow: "14 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_14-04-2024_362_TO_352_COG",
//     timeline: "2024/04/14",
//     id: 4,
//     nameToShow: "14 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_14-04-2024_78_TO_96",
//     timeline: "2024/04/14",
//     id: 5,
//     nameToShow: "14 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_15-04-2024_344_TO_327_COG",
//     timeline: "2024/04/15",
//     id: 6,
//     nameToShow: "15 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_15-04-2024_96.3_TO_115",
//     timeline: "2024/04/15",
//     id: 7,
//     nameToShow: "15 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_16-04-2024_115",
//     timeline: "2024/04/16",
//     id: 8,
//     nameToShow: "16 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_17-04-2024_148",
//     timeline: "2024/04/17",
//     id: 9,
//     nameToShow: "17 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_18-04-2024_169_TO_174",
//     timeline: "2024/04/18",
//     id: 10,
//     nameToShow: "18 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_18-04-2024_275_TO_261",
//     timeline: "2024/04/18",
//     id: 11,
//     nameToShow: "18 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_19-04-2024_185_TO_200",
//     timeline: "2024/04/19",
//     id: 12,
//     nameToShow: "19 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_19-04-2024_261_TO_246_COG",
//     timeline: "2024/04/19",
//     id: 13,
//     nameToShow: "19 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_20-04-2024_205.1_TO215",
//     timeline: "2024/04/20",
//     id: 14,
//     nameToShow: "20 April 2024",
//     type: "ORI",
//   },
//   {
//     name: "HMEL_ORTHO_20-04-2024_245_TO_224",
//     timeline: "2024/04/20",
//     id: 15,
//     nameToShow: "20 April 2024",
//     type: "ORI",
//   },
// ];

// [
//   "DSM_13-04-2024_382_TO_363_COG",
//   "DSM_13-04-2024_70_TO_78.2_COG",
//   "DSM_14-04-2024_362_TO_345_COG",
//   "DSM_14-04-2024_362_TO_352_COG",
//   "DSM_14-04-2024_78_TO_96.5_COG",
//   "DSM_15-04-2024_344_TO_327_COG",
//   "DSM_15-04-2024_96.3_TO_115.8_COG",
//   "DSM_16-04-2024_115.5_TO_140_COG",
//   "DSM_16-04-2024_326_TO_296_COG",
//   "DSM_17-04-2024_148.8_TO_158_COG",
//   "DSM_18-04-2024_169_TO_174.1_COG",
//   "DSM_19-04-2024_185_TO_200.1_COG",
//   "DSM_20-04-2024_200_TO_224.3_COG",
//   "HMEL_ORTHO_13-04-2024_382_TO_363_COG",
//   "HMEL_ORTHO_13-04-2024_70_TO_78.2_COG",
//   "HMEL_ORTHO_14-04-2024_362_TO_345_COG",
//   "HMEL_ORTHO_14-04-2024_362_TO_352_COG",
//   "HMEL_ORTHO_14-04-2024_78_TO_96.5_COG",
//   "HMEL_ORTHO_15-04-2024_344_TO_327_COG",
//   "HMEL_ORTHO_15-04-2024_96.3_TO_115.8_COG",
//   "HMEL_ORTHO_16-04-2024_115.5_TO_140_COG",
//   "HMEL_ORTHO_17-04-2024_148.8_TO_158_COG",
//   "HMEL_ORTHO_18-04-2024_169_TO_174.1_COG",
//   "HMEL_ORTHO_18-04-2024_275_TO_261.5_COG",
//   "HMEL_ORTHO_19-04-2024_185_TO_200.1_COG",
//   "HMEL_ORTHO_19-04-2024_261_TO_246_COG",
//   "HMEL_ORTHO_20-04-2024_205.1_TO215.5_COG",
//   "HMEL_ORTHO_20-04-2024_245_TO_224.3_COG",
// ];

// export const HMELIndeixngData = [
//   {
//     section: {
//       startChainage: 70,
//       endChainage: 78.2,
//       title: "70km to 78.2km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_13-04-2024_70_TO_78.2_COG", type: "ORI" },
//       { id: "DSM_13-04-2024_70_TO_78.2_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 78,
//       endChainage: 96.5,
//       title: "78km to 96.5km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_14-04-2024_78_TO_96.5_COG", type: "ORI" },
//       { id: "DSM_14-04-2024_78_TO_96.5_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 96.3,
//       endChainage: 115.8,
//       title: "96.3km to 115.8km",
//     },
//     assets: [
//       {
//         id: "DSM_15-04-2024_96.3_TO_115.8_COG",
//         type: "DSM",
//       },
//     ],
//   },

//   {
//     section: {
//       startChainage: 115.5,
//       endChainage: 140,
//       title: "115.5km to 140km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_16-04-2024_115.5_TO_140_COG", type: "ORI" },
//       { id: "DSM_16-04-2024_115.5_TO_140_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 148.8,
//       endChainage: 158,
//       title: "148.8km to 158km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_17-04-2024_148.8_TO_158_COG", type: "ORI" },
//       { id: "DSM_17-04-2024_148.8_TO_158_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 169,
//       endChainage: 174.1,
//       title: "169km to 174.1km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_18-04-2024_169_TO_174.1_COG", type: "ORI" },
//       { id: "DSM_18-04-2024_169_TO_174.1_COG", type: "DSM" },
//     ],
//   },

//   {
//     section: {
//       startChainage: 185,
//       endChainage: 200.1,
//       title: "185km to 200.1km",
//     },
//     assets: [{ id: "DSM_19-04-2024_185_TO_200.1_COG", type: "DSM" }],
//   },

//   {
//     section: {
//       startChainage: 200,
//       endChainage: 224.3,
//       title: "200km to 224.3km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_20-04-2024_245_TO_224.3_COG", type: "ORI" },
//       { id: "DSM_20-04-2024_200_TO_224.3_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 224.3,
//       endChainage: 245,
//       title: "224.3km to 245km",
//     },
//     assets: [{ id: "HMEL_ORTHO_20-04-2024_245_TO_224.3_COG", type: "ORI" }],
//   },
//   {
//     section: {
//       startChainage: 205.1,
//       endChainage: 215.5,
//       title: "205.1km to 215.5km",
//     },
//     assets: [{ id: "HMEL_ORTHO_20-04-2024_205.1_TO215.5_COG", type: "ORI" }],
//   },
//   {
//     section: {
//       startChainage: 246,
//       endChainage: 261,
//       title: "246km to 261km",
//     },
//     assets: [{ id: "HMEL_ORTHO_19-04-2024_261_TO_246_COG", type: "ORI" }],
//   },
//   {
//     section: {
//       startChainage: 261.5,
//       endChainage: 275,
//       title: "261.5km to 275km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_18-04-2024_275_TO_261.5_COG", type: "ORI" },
//       {
//         id: "DSM_18-04-2024_275_TO_261.5_COG ",
//         type: "DSM",
//       },
//     ],
//   },
//   {
//     section: {
//       startChainage: 296,
//       endChainage: 326,
//       title: "296km to 326km",
//     },
//     assets: [{ id: "DSM_16-04-2024_326_TO_296_COG", type: "DSM" }],
//   },
//   {
//     section: {
//       startChainage: 327,
//       endChainage: 344,
//       title: "327km to 344km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_15-04-2024_344_TO_327_COG", type: "ORI" },
//       { id: "DSM_15-04-2024_344_TO_327_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 345,
//       endChainage: 362,
//       title: "345km to 362km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_14-04-2024_362_TO_345_COG", type: "ORI" },
//       { id: "DSM_14-04-2024_362_TO_345_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 352,
//       endChainage: 362,
//       title: "352km to 362km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_14-04-2024_362_TO_352_COG", type: "ORI" },
//       { id: "DSM_14-04-2024_362_TO_352_COG", type: "DSM" },
//     ],
//   },
//   {
//     section: {
//       startChainage: 363,
//       endChainage: 382,
//       title: "363km to 382km",
//     },
//     assets: [
//       { id: "HMEL_ORTHO_13-04-2024_382_TO_363_COG", type: "ORI" },
//       { id: "DSM_13-04-2024_382_TO_363_COG", type: "DSM" },
//     ],
//   },
// ];

export const HMELIndeixngData = [
  {
    section: {
      startChainage: 71.1,
      endChainage: 78.8,
      title: "71.1km to 78.8km",
    },
    assets: [
      { id: "HMEL_ORTHO_13-04-2024_71.1_TO_78.8_COG", type: "ORI" },
      { id: "HMEL_DSM_13-04-2024_71.1_TO_78.8_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 78.8,
      endChainage: 97.2,
      title: "78.8km to 97.2km",
    },
    assets: [
      { id: "HMEL_ORTHO_14-04-2024_78.8_TO_97.2_COG", type: "ORI" },
      { id: "HMEL_DSM_14-04-2024_78.8_TO_97.2_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 97.2,
      endChainage: 116.2,
      title: "97.2km to 116.2km",
    },
    assets: [
      { id: "HMEL_ORTHO_15-04-2024_97.2_TO_116.2_COG", type: "ORI" },
      { id: "HMEL_DSM_15-04-2024_97.2_TO_116.2_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 116.2,
      endChainage: 142.5,
      title: "116.2km to 142.5km",
    },
    assets: [
      { id: "HMEL_ORTHO_16-04-2024_116.2_TO_142.5_COG", type: "ORI" },
      { id: "HMEL_DSM_16-04-2024_116.2_TO_142.5_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 142.5,
      endChainage: 164.5,
      title: "142.5km to 164.5km",
    },
    assets: [
      { id: "HMEL_ORTHO_17-04-2024_142.5_TO_164.5_COG", type: "ORI" },
      { id: "HMEL_DSM_17-04-2024_142.5_TO_164.5_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 164.5,
      endChainage: 180,
      title: "164.5km to 180km",
    },
    assets: [
      { id: "HMEL_ORTHO_18-04-2024_164.5_TO_180_COG", type: "ORI" },
      { id: "HMEL_DSM_18-04-2024_164.5_TO_180_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 180,
      endChainage: 200.4,
      title: "180km to 200.4km",
    },
    assets: [
      { id: "HMEL_ORTHO_19-04-2024_180_TO_200.4_COG", type: "ORI" },
      { id: "HMEL_DSM_19-04-2024_180_TO_200.4_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 200.4,
      endChainage: 225.1,
      title: "200.4km to 225.1km",
    },
    assets: [
      { id: "HMEL_ORTHO_20-04-2024_200.4_TO_225.1_COG", type: "ORI" },
      { id: "HMEL_DSM_20-04-2024_200.4_TO_225.1_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 225,
      endChainage: 247.3,
      title: "225km to 247.3km",
    },
    assets: [
      { id: "HMEL_ORTHO_20-04-2024_225_TO_247.3_COG", type: "ORI" },
      { id: "HMEL_DSM_20-04-2024_225_TO_247.3_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 247.3,
      endChainage: 262.6,
      title: "247.3km to 262.6km",
    },
    assets: [{ id: "HMEL_ORTHO_19-04-2024_247.3_TO_262.6_COG", type: "ORI" }],
  },
  {
    section: {
      startChainage: 262.6,
      endChainage: 277.1,
      title: "262.6km to 277.1km",
    },
    assets: [{ id: "HMEL_ORTHO_18-04-2024_262.6_TO_277.1_COG", type: "ORI" }],
  },
  {
    section: {
      startChainage: 297.3,
      endChainage: 327.7,
      title: "297.3km to 327.7km",
    },
    assets: [{ id: "HMEL_DSM_16-04-2024_327.7_TO_297.3_COG", type: "DSM" }],
  },
  {
    section: {
      startChainage: 327.7,
      endChainage: 346.5,
      title: "327.7km to 346.5km",
    },
    assets: [
      { id: "HMEL_ORTHO_15-04-2024_327.7_TO_346.5_COG", type: "ORI" },
      { id: "HMEL_DSM_15-04-2024_327.7_TO_346.5_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 346.5,
      endChainage: 356,
      title: "346.5km to 356km",
    },
    assets: [
      { id: "HMEL_ORTHO_14-04-2024_346.5_TO_356_COG", type: "ORI" },
      { id: "HMEL_DSM_14-04-2024_346.5_TO_356_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 355.8,
      endChainage: 364.8,
      title: "355.8km to 364.8km",
    },
    assets: [
      { id: "HMEL_ORTHO_14-04-2024_355.8_TO_364.8_COG", type: "ORI" },
      { id: "HMEL_DSM_14-04-2024_355.8_TO_364.8_COG", type: "DSM" },
    ],
  },
  {
    section: {
      startChainage: 364.8,
      endChainage: 383.9,
      title: "364.8km to 383.9km",
    },
    assets: [
      { id: "HMEL_ORTHO_13-04-2024_364.8_TO_383.9_COG", type: "ORI" },
      { id: "HMEL_DSM_13-04-2024_364.8_TO_383.9_COG", type: "DSM" },
    ],
  },
];
