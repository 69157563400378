import React, { useState } from "react";
// import "../../styles/video-canvas-respo.css";
import Loader from "@mui/material/CircularProgress";
import Hls from "hls.js";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const VideoPlayer = (props) => {
  const canvasRef = React.useRef(null);
  const hlsRef = React.useRef(null);
  const videoRef = props.videoRef;
  const videoSrc = useSelector((state) => state.project.videoSrc);
  const { currentLevel, getQualityLevels } = props;
  const level = currentLevel;
  const [isOpen, setIsOpen] = React.useState(false);

  const [zoomLevel, setZoomLevel] = React.useState(1);
  const [xOffset, setXOffset] = React.useState(0);
  const [yOffset, setYOffset] = React.useState(0);
  const [draw, setDraw] = React.useState(false);

  const drawMode = useSelector((state) => state.mapToggle.drawMode);
  const loopMode = useSelector((state) => state.mapToggle.loopMode);
  const loopChainge = useSelector((state) => state.loop);

  const increaseZoomLevel = () => {
    setZoomLevel((prev) => prev + 0.5);
  };

  const decreaseZoomLevel = () => {
    setZoomLevel((prev) => Math.max(prev - 0.5, 1));
  };

  const resetZoomSettings = () => {
    setZoomLevel(1);
    setXOffset(0);
    setYOffset(0);
  };

  const increaseOffsetX = () => {
    if (zoomLevel !== 1) {
      setXOffset((prev) => prev + 20);
    }
  };

  const decreaseOffsetX = () => {
    if (zoomLevel !== 1) {
      setXOffset((prev) => prev - 20);
    }
  };

  const increaseOffsetY = () => {
    if (zoomLevel !== 1) {
      setYOffset((prev) => prev + 20);
    }
  };

  const decreaseOffsetY = () => {
    if (zoomLevel !== 1) {
      setYOffset((prev) => prev - 20);
    }
  };

  React.useEffect(() => {
    let hls;

    if (videoRef.current) {
      const video = videoRef.current;

      if (Hls.isSupported() && !hlsRef.current) {
        hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(video);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const levels = hls.levels;
          getQualityLevels(levels);
        });

        hlsRef.current = hls;
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoSrc;
      }
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    };
  }, [videoSrc, videoRef]);

  React.useEffect(() => {
    if (hlsRef.current) {
      hlsRef.current.currentLevel = level;
    }
  }, [level]);

  React.useEffect(() => {
    if (drawMode) {
      setDraw(true);
    } else {
      setDraw(false);
    }
  }, [drawMode]);

  let isDrawing = false;
  let startX, startY, endX, endY;
  const handleMouseDown = (e) => {
    if (!draw) return;

    const canvasRect = canvasRef.current.getBoundingClientRect();
    isDrawing = true;
    startX =
      (e.clientX - canvasRect.left) *
      (canvasRef.current.width / canvasRect.width);
    startY =
      (e.clientY - canvasRect.top) *
      (canvasRef.current.height / canvasRect.height);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;
    const canvasRect = canvasRef.current.getBoundingClientRect();
    endX =
      (e.clientX - canvasRect.left) *
      (canvasRef.current.width / canvasRect.width);
    endY =
      (e.clientY - canvasRect.top) *
      (canvasRef.current.height / canvasRect.height);

    renderFrame();
  };

  const handleMouseUp = () => {
    // if (!draw) return;
    isDrawing = false;
    updateRectInfo();
    // setIsOpen(true);
  };

  const drawRect = () => {
    if (!draw) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const width = endX - startX;
    const height = endY - startY;
    context.strokeStyle = "blue";
    context.lineWidth = 4;
    context.strokeRect(startX, startY, width, height);
  };

  const updateRectInfo = () => {
    const width = Math.abs(endX - startX);
    const height = Math.abs(endY - startY);
    const rectX = endX < startX ? endX : startX;
    const rectY = endY < startY ? endY : startY;
    const currentTime = videoRef.current.currentTime;

    // Calculate frame number based on current time and frame rate
    const frameNumber = Math.floor(currentTime * 30);
    const newRect = {
      reactWidth: width,
      reactHeight: height,
      videoWidth: videoRef.current.videoWidth,
      videoHeight: videoRef.current.videoHeight,
      topLeft: { x: rectX, y: rectY }, // Changed from topRight to topLeft
      // bottomRight: { x: rectX + width, y: rectY + height },
      frameNumber,
    };
    console.log(newRect);
  };

  let animationFrameId;
  const renderFrame = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const context = canvas.getContext("2d");
      const aspectRatio = video.videoWidth / video.videoHeight;

      const canvasWidth = canvas.width;
      const canvasHeight = canvasWidth / aspectRatio;

      const offsetX = (canvas.width - canvasWidth * zoomLevel) / 2;
      const offsetY = (canvas.height - canvasHeight * zoomLevel) / 2;

      context.drawImage(
        video,
        offsetX + xOffset,
        offsetY + yOffset,
        canvasWidth * zoomLevel,
        canvasHeight * zoomLevel
      );

      drawRect();

      animationFrameId = requestAnimationFrame(renderFrame);
    }
  };

  React.useEffect(() => {
    const canvas = canvasRef.current;

    const videoCanPlayHandler = () => {
      if (videoRef) {
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        renderFrame();
      }
    };

    videoRef.current.addEventListener("canplay", videoCanPlayHandler);

    return () => {
      cancelAnimationFrame(animationFrameId);
      if (videoRef.current) {
        videoRef.current.removeEventListener("canplay", videoCanPlayHandler);
      }
    };
  }, [videoRef]);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseup", handleMouseUp);
    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
    };
  }, [draw]);

  React.useEffect(() => {
    renderFrame();
  }, [zoomLevel, xOffset, yOffset]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative min-[700px]:h-full w-[67%] max-[700px]:w-[100%] border-l ml-2 mr-2 bg-gray-200 flex items-center parent-canvas-video-respo">
      {props.showLoader && (
        <div className="absolute top-0 items-center flex bg-black w-full h-full justify-center z-[1]">
          <Loader className="!w-[60px] !h-[60px]" />
        </div>
      )}
      <div className="absolute z-[999] right-6 bottom-6 text-white flex flex-col">
        <div className="speed-dial-container">
          <button className="speed-dial-button" onClick={toggleOpen}>
            {isOpen ? (
              <CloseRoundedIcon className="!text-[#fff]" />
            ) : (
              <SettingsIcon className="!text-[#fff]" />
            )}
          </button>
          {isOpen && (
            <>
              <div className="speed-dial-options_2">
                <div className="flex justify-center items-center">
                  <Tooltip title="Reset" placement="top" arrow>
                    <div
                      className="speed-dial-option"
                      aria-label="Reset Zoom Settings"
                      onClick={resetZoomSettings}
                    >
                      <RefreshIcon />
                    </div>
                  </Tooltip>
                </div>
                <div className="flex flex-row justify-center items-center gap-[8px]">
                  <Tooltip title="Increase Zoom" placement="top" arrow>
                    <div
                      className="speed-dial-option"
                      aria-label="Increase Zoom"
                      onClick={increaseZoomLevel}
                    >
                      <AddRoundedIcon />
                    </div>
                  </Tooltip>
                  <Tooltip title="Decrease Zoom" placement="top" arrow>
                    <div
                      className="speed-dial-option"
                      aria-label="Decrease Zoom"
                      onClick={decreaseZoomLevel}
                    >
                      <RemoveRoundedIcon />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="speed-dial-options">
                <div className="flex flex-row  justify-center items-center">
                  <Tooltip title="Move Left" placement="left" arrow>
                    <div
                      className="speed-dial-option"
                      aria-label="Move Left"
                      onClick={increaseOffsetX}
                    >
                      <ArrowCircleLeftIcon />
                    </div>
                  </Tooltip>
                  <div className="flex flex-col justify-center items-center gap-[8px]">
                    <Tooltip title="Move Up" placement="top" arrow>
                      <div
                        className="speed-dial-option"
                        aria-label="Move Up"
                        onClick={increaseOffsetY}
                      >
                        <ArrowCircleUpIcon />
                      </div>
                    </Tooltip>
                    <Tooltip title="Move Down" placement="bottom" arrow>
                      <div
                        className="speed-dial-option"
                        aria-label="Move Down"
                        onClick={decreaseOffsetY}
                      >
                        <ArrowCircleDownIcon />
                      </div>
                    </Tooltip>
                  </div>
                  <Tooltip title="Move Right" placement="right" arrow>
                    <div
                      className="speed-dial-option"
                      aria-label="Move Right"
                      onClick={decreaseOffsetX}
                    >
                      <ArrowCircleRightIcon />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {loopMode && (
        <div className="absolute z-[999] left-0 bottom-0 text-white bg-black/50 p-2">
          {`Looping From ${loopChainge.chainage.start_chainage} Km to ${loopChainge.chainage.end_chainage} Km`}
        </div>
      )}

      <div className="h-full w-full">
        <video
          onLoadedMetadata={props.handleDuration}
          onTimeUpdate={props.handleTimeUpdate}
          ref={videoRef}
          muted
          autoPlay
          onClick={props.handlePlayPause}
          className="h-full w-full hidden"
          style={{
            objectFit: "fill",
          }}
        />
        <canvas
          ref={canvasRef}
          className="h-[100%] w-[100%] canvas-video-respo"
          // onClick={props.handlePlayPause}
          style={{ transform: "translateZ(0)", objectFit: "fill" }}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
