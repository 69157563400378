import React from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import Cookies from "js-cookie";
import MapLine from "../../Components/Layouts/MapLine";
import { tokenChecker } from "../../Utils/Cookie";
import { latLngs } from "../../Utils/lat_long";
import { latLngs_ct_dr } from "../../Utils/lat_long_csmt_dadar";
import { latLngs_iocl_nrpl } from "../../Utils/lat_long_iocl_nrpl";
import { latLngs_virar_dhu } from "../../Utils/lat_long_virar_dahanu";
import {
  projectData,
  projectDataIOCL,
} from "../../../src/Data/testProjectData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  boxShadow: 24,
};

const getProjectData = (projName) => {
  if (projName === "IOCL-NRPL" || projName === "IOCL-NRPL-Object-Detection") {
    return {
      start_chainage: projectDataIOCL[0].start_chainage,
      end_chainage: projectDataIOCL[0].end_chainage,
    };
  } else {
    const project = projectData.find((proj) => proj.projectName === projName);
    if (project) {
      return {
        start_chainage: project.start_chainage,
        end_chainage: project.end_chainage,
      };
    } else {
      return {
        start_chainage: "",
        end_chainage: "",
      };
    }
  }
};
const setMap = (location) => {
  var maplocation;
  if (location === "Panvel-Karjat") {
    maplocation = latLngs;
  } else if (location === "Virar-Dahnu") {
    maplocation = latLngs_virar_dhu;
  } else if (location === "IOCL-NRPL") {
    maplocation = latLngs_iocl_nrpl;
  } else {
    maplocation = latLngs_ct_dr;
  }
  return maplocation;
};
const Asset = () => {
  const [assets, setAssets] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [clientName, setClientName] = React.useState("mrvc");
  const [openProjectIndex, setOpenProjectIndex] = React.useState(-1);
  const cookieValue = Cookies.get("clientName");
  const navigate = useNavigate();
  // const [viewOpen, setViewOpen] = React.useState(false);

  React.useEffect(() => {
    tokenChecker(navigate);
  }, [navigate]);

  React.useState(() => {
    if (cookieValue && cookieValue === "oil") {
      setClientName("iocl");
    }
  }, [cookieValue]);

  // const handleViewbtn = (event, index) => {
  //   setOpenProjectIndex(index);
  //   setViewOpen(true);
  // };

  const handleOpen = (event, index) => {
    setOpenProjectIndex(index);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    const getAssets = async () => {
      try {
        const response = await axios.get(
          `https://inspecthb.indrones.com/get_all_entries/${clientName}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // //console.log(response.data);
        setAssets(response.data[0]);
      } catch (error) {
        console.error(error);
      }
    };
    getAssets();
  }, []);

  const handleDownload = (s3_link, clipName, clipstatus) => {
    if (clipstatus === "processing") {
      toast.error("wait for some sec, video is processing!");
    } else {
      const filename = clipName;
      const link = document.createElement("a");
      link.href = s3_link;
      link.setAttribute("download", `${filename}.mp4`);
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  return (
    <div className="flex flex-col w-full sidebr-project-dashbord overflow-y-scroll custom-scrollbar no-track relative">
      <div className="grow grid gap-[12px] p-[15px] max-[767px]:p-[6px] grid-cols-3 max-[500px]:grid-cols-1 max-[500px]:gap-[8px] grid-rows-3 h-fit tabletview">
        {/* <div className="grow grid gap-[12px] p-[24px] grid-cols-3 grid-rows-3 h-full"> */}
        {typeof assets !== "string"
          ? Object.entries(assets).map(([projName, clips], index) => {
              var chainageData = getProjectData(projName);
              const isSelected = openProjectIndex === index;
              return (
                <>
                  <div
                    key={index}
                    className="w-[100%] flex flex-col mt-[0px] rounded-[3px] relative bg-[#fff] p-2 h-fit"
                  >
                    <div className="w-[100%] h-[250px] z-[1]">
                      <MapLine location={setMap(projName)} modalView={false} />
                    </div>
                    <div className="bg-[#fff] detailscardproject h-auto">
                      <div className="font-semibold uppercase text-[17px]	tracking-[1px]">
                        {projName}
                      </div>
                      <div className="border-b border-solid border-[#f1efef] mt-[6px] mb-[6px]"></div>
                      <div className="flex justify-between text-[15px] max-[395px]:flex-col">
                        <div className="flex">
                          <h2 className="font-[600] w-[120px] text-[#757576]">
                            Start Chainage :
                          </h2>
                          <span className="ml-[5px] text-[#7f8183]">
                            {chainageData.start_chainage} km
                          </span>
                        </div>
                        <div className="flex">
                          <h2 className="font-[600] w-[120px] text-[#757576]">
                            End Chainage :
                          </h2>
                          <span className="text-[#7f8183]">
                            {chainageData.end_chainage} km
                          </span>
                        </div>
                      </div>
                      <div className="border-b border-solid border-[#f1efef] mt-[6px] mb-[11px]"></div>
                      <div className="flex justify-end">
                        <div
                          className="flex items-center justify-center rounded-[5px] cursor-pointer  play-btn"
                          onClick={(event) => handleOpen(event, index)}
                        >
                          <span className="capitalize text-[14px] font-[400] tracking-[0.9px]">
                            Download Clips
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isSelected && (
                    <Modal open={open} onClose={handleClose}>
                      <div
                        style={style}
                        className="flex flex-col items-center justify-between bg-white w-full h-[50%] rounded-[12px] overflow-hidden modelvideoClip"
                      >
                        <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px]  w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
                          <h3 className="!text-[1.20rem]  text-[#211f1c] grow flex items-center justify-center">
                            Video Clips
                          </h3>
                          <div className="mr-[10px]">
                            <IconButton
                              onClick={handleClose}
                              aria-label="Close"
                              size="small"
                              className="text-[#211f1c]"
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </div>
                        <div className="grow w-full p-3 overflow-y-scroll custom-scrollbar">
                          {clips.length ? (
                            clips.map((clip, clipIndex) => {
                              return (
                                <div
                                  key={clipIndex}
                                  className="flex w-[90%] items-center justify-between my-[10px] p-2 bg-white mx-auto border rounded-lg"
                                >
                                  <div>{clip.clip_name}</div>
                                  <div>
                                    <IconButton
                                      attr={clip.s3_output_link}
                                      className={`text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] ${
                                        clip.status === "processed"
                                          ? ""
                                          : "opacity-50 cursor-no-drop"
                                      }`}
                                      onClick={() =>
                                        handleDownload(
                                          clip.s3_output_link,
                                          clip.clip_name,
                                          clip.status
                                        )
                                      }
                                    >
                                      <DownloadIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="text-center mt-2">
                              No Assets Available!
                            </div>
                          )}
                        </div>
                      </div>
                    </Modal>
                  )}
                </>
              );
            })
          : "No Assets Available"}
        {/* </div> */}
      </div>
      <Toaster
        position="top-center"
        reverseOrder={true}
        toastOptions={{
          duration: 2000,
          style: {
            background: "#fff",
            color: "#4a4844",
            border: "1px solid #fff",
          },
        }}
      />
    </div>
  );
};

export default Asset;
