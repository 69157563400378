import { Tooltip } from "@mui/material";
import React from "react";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-[#fff] max-[500px]:bg-[#fcfcfc] flex flex-col items-center py-5 gap-5 h-full !w-[5%] border-r">
        <Tooltip title="Add Annotations" placement="right" arrow>
          <div
            className="flex flex-col items-center  side-btn cursor-pointer"
            onClick={() => navigate("/setting")}
          >
            <SettingsRoundedIcon
              className={`text-[#B5B7C2] rounded-full side-btn-icon`}
            />
            <span className="mt-[3px] text-[#5d647b] leading-[0.75rem] text-[0.71875rem] tracking-[0.01em] font-[600]">
              Settings
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Add Annotations" placement="right" arrow>
          <div
            className="flex flex-col items-center  side-btn cursor-pointer"
            onClick={() => navigate("/perms")}
          >
            <ManageAccountsIcon
              className={`text-[#B5B7C2] rounded-full side-btn-icon`}
            />
            <span className="mt-[3px] text-[#5d647b] leading-[0.75rem] text-[0.71875rem] tracking-[0.01em] font-[600]">
              Permisision
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Add Annotations" placement="right" arrow>
          <div
            className="flex flex-col items-center  side-btn cursor-pointer"
            onClick={() => navigate("/role_perms")}
          >
            <BadgeRoundedIcon
              className={`text-[#B5B7C2] rounded-full side-btn-icon`}
            />
            <span className="mt-[3px] text-[#5d647b] leading-[0.75rem] text-[0.71875rem] tracking-[0.01em] font-[600]">
              Role-Permisision
            </span>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default Sidebar;
