import {
  agpSiteAIco,
  agpSiteBIco,
} from "../Assets/images/servicesImg/services";

export const projectData = [
  {
    projectName: "Panvel-Karjat",
    project_type: "Video",
    start_location: "Panvel",
    end_location: "Karjat",
    start_chainage: 0,
    end_chainage: 32,
    active: true,
    fly_dates: [
      { date: "Feb 2021", data: false, isCompare: false },
      { date: "Jan 2022", data: false, isCompare: false },
      { date: "Oct 2022", data: false, isCompare: false },
      {
        id: 1,
        date: "Jan 2023",
        data: true,
        isCompare: true,
        link: "pk_r4",
        round: "4",
      },
      {
        id: 2,
        date: "Apr 2023",
        data: true,
        isCompare: true,
        link: "pk_r5",
        round: "5",
      },
      {
        id: 3,
        date: "June 2023",
        data: true,
        isCompare: true,
        link: "pk_r6",
        round: "6",
      },
    ],
  },
  {
    projectName: "Virar-Dahnu",
    project_type: "Video",
    start_location: "Virar",
    end_location: "Dahanu",
    start_chainage: 0,
    end_chainage: 64.78,
    active: false,
    fly_dates: [],
  },
  {
    projectName: "CSMT-Dadar",
    project_type: "Video",
    start_location: "CSMT",
    end_location: "Kalyan",
    start_chainage: 0,
    end_chainage: 53,
    active: true,
    fly_dates: [
      {
        id: 4,
        date: "CSMT-Dadar",
        data: true,
        isCompare: true,
        link: "cd_r1",
      },
    ],
  },
];

export const projectDataIOCL = [
  {
    projectName: "indian-oil",
    start_location: "IOCL",
    project_type: "Video",
    end_location: "NRPL",
    start_chainage: 167.3,
    end_chainage: 0,
    active: true,
    fly_dates: [
      { id: 5, date: "Najibabad", data: true, isCompare: false, link: "in_r1" },
      {
        id: 6,
        date: "Najibabad Object Detection",
        data: true,
        isCompare: false,
        link: "in_r2",
      },
    ],
  },
];

export const AGPData = [
  {
    projectName: "agp",
    start_location: "Site A",
    nonLinear: true,
    end_location: null,
    end_chainage: null,
    active: true,
    path: "AGP",
    fly_dates: null,
    project_type: "ORI",
    project_summary: false,
    thumbnailImg: agpSiteAIco,
  },
  {
    projectName: "agp",
    start_location: "Site B",
    nonLinear: true,
    end_location: null,
    end_chainage: null,
    active: true,
    path: "AGP",
    fly_dates: null,
    project_type: "ORI",
    project_summary: true,
    thumbnailImg: agpSiteBIco,
  },
];

export const MunicipalityData = [
  {
    projectName: "kmda",
    start_location: "Dunlop Canal",
    nonLinear: true,
    path: "KMDA",
    end_location: null,
    end_chainage: null,
    active: true,
    fly_dates: null,
    project_type: "ORI",
  },
  {
    projectName: "kdmc",
    start_location: "Barave",
    nonLinear: true,
    path: "KDMC",
    end_location: null,
    end_chainage: null,
    active: true,
    fly_dates: null,
    project_type: "ORI",
  },
  {
    projectName: "gangasagar",
    start_location: "Gangasagar Temple Area",
    nonLinear: true,
    path: "Gangasagar",
    end_location: null,
    end_chainage: null,
    active: true,
    fly_dates: null,
    project_type: "ORI",
  },
];

export const asslrData = [
  // {
  //   projectName: "doitc",
  //   start_location: "DOITC Jaipur",
  //   nonLinear: true,
  //   path: "DOITC",
  //   end_location: null,
  //   end_chainage: null,
  //   active: true,
  //   fly_dates: null,
  //   project_type: "ORI",
  // },
  {
    projectName: "sahreuav",
    start_location: "ShareUAV Delhi",
    nonLinear: true,
    path: "shareuav",
    end_location: null,
    end_chainage: null,
    active: true,
    fly_dates: null,
    project_type: "ORI",
  },
];

export const landRecData = [
  {
    projectName: "asslr",
    start_location: "Yellasiri",
    nonLinear: true,
    path: "APSSLR",
    end_location: null,
    end_chainage: null,
    active: true,
    fly_dates: null,
    project_type: "ORI",
  },
];

export const miningData = [
  {
    projectName: "essel",
    start_location: "Essel",
    nonLinear: true,
    path: "ESSEL",
    end_location: null,
    end_chainage: null,
    active: true,
    fly_dates: null,
    project_type: "ORI",
  },
];

export const forestryData = [
  {
    projectName: "forestry",
    start_location: "Site A",
    nonLinear: true,
    path: "Forestry",
    end_location: null,
    end_chainage: null,
    active: true,
    fly_dates: null,
    project_type: "ORI",
  },
];

export const hmelData = [
  {
    projectName: "hmel",
    start_location: "HMEL Photogrammetry",
    nonLinear: true,
    end_location: null,
    end_chainage: null,
    active: true,
    path: "HMEL",
    fly_dates: null,
    project_type: "ORI",
    project_summary: false,
    // thumbnailImg: agpSiteAIco,
  },
  // {
  //   projectName: "hmel",
  //   start_location: "HMEL Videography",
  //   project_type: "Video",
  //   end_location: "",
  //   start_chainage: 167.3,
  //   end_chainage: 0,
  //   active: true,
  //   fly_dates: [
  //     { id: 5, date: "Najibabad", data: true, isCompare: false, link: "in_r1" },
  //     {
  //       id: 6,
  //       date: "Najibabad Object Detection",
  //       data: true,
  //       isCompare: false,
  //       link: "in_r2",
  //     },
  //   ],
  // },
];
