import "leaflet-geometryutil";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import polylabel from "@mapbox/polylabel";
import "leaflet-draw";
// markers.js

class MarkerClass {
  // Marker-specific functions

  hnadleMarkerLabel(layer, markerName, map, labelFeatureGroup) {
    if (layer.type === "marker") {
      return;
    }
    // Get the marker's latlng
    if (layer.type === "polygon") {
      const latLngs = layer.getLatLngs();
      // For polygon markers, calculate centroid
      // const centroid = this.calculatePolygonCentroid(latLng[0]);
      const array = latLngs[0].map((latLng) => [latLng.lng, latLng.lat]);
      const coordinates = [array];
      const centroid = polylabel(coordinates, 1.0);
      this.addMarkerLabel(
        layer,
        centroid.reverse(),
        markerName,
        map,
        labelFeatureGroup
      );
    } else if (layer.type === "polyline") {
      const latLngs = layer.getLatLngs();
      // For polyline markers, calculate midpoint based on polyline length
      const totalLength = L.GeometryUtil.length(layer);
      const halfLength = totalLength / 2;
      let accumulatedLength = 0;

      // Iterate through polyline vertices to find the midpoint
      for (let i = 1; i < latLngs.length; i++) {
        const distance = latLngs[i - 1].distanceTo(latLngs[i]);
        if (accumulatedLength + distance >= halfLength) {
          // Calculate the fraction of the current segment needed to reach the midpoint
          const fraction = (halfLength - accumulatedLength) / distance;

          // Interpolate the coordinates
          const lat =
            latLngs[i - 1].lat +
            fraction * (latLngs[i].lat - latLngs[i - 1].lat);
          const lng =
            latLngs[i - 1].lng +
            fraction * (latLngs[i].lng - latLngs[i - 1].lng);

          const midPoint = L.latLng(lat, lng);
          this.addMarkerLabel(
            layer,
            midPoint,
            markerName,
            map,
            labelFeatureGroup
          );
          break;
        } else {
          accumulatedLength += distance;
        }
      }
    }
  }

  // addMarkerLabel(layer, latLng, markerName, map) {
  //   // Create and add the label
  //   const label = L.marker(latLng, {
  //     icon: L.divIcon({
  //       className: "leaflet-div-label",
  //       html: `<div class="MeasurmentLabel">
  //       <p>${markerName}</p></div>`,
  //     }),
  //   }).addTo(map);

  //   // Set opacity based on zoom level
  //   label.setOpacity(map.getZoom() >= 13 ? 1 : 0);

  //   // Update opacity when zoom changes
  //   map.on("zoomend", () => {
  //     label.setOpacity(map.getZoom() >= 13 ? 1 : 0);
  //   });

  //   // Store the label reference in the marker
  //   layer.label = label;
  // }
  addMarkerLabel(layer, latLng, markerName, map, labelFeatureGroup) {
    // Create and add the label
    const label = L.marker(latLng, {
      icon: L.divIcon({
        className: "leaflet-div-label",
        html: `<div class="text-[10px] absolute bg-black bg-opacity-50 text-white py-0.5 px-1 rounded">${markerName}</div>`,
      }),
    });
    // labelFeatureGroup.addLayer(label);

    // Set opacity based on zoom level
    label.setOpacity(map.getZoom() >= 19 ? 1 : 0);

    // Update opacity when zoom changes
    map.on("zoomend", () => {
      label.setOpacity(map.getZoom() >= 19 ? 1 : 0);
    });

    // Store the label reference in the marker
    layer.label = label;
  }
}

class Measure {
  handlePolyline(layer, map, measure) {
    const lengthInMeters = L.GeometryUtil.length(layer);
    const lastLatLng = layer.getLatLngs()[layer.getLatLngs().length - 1];
    return this.addPolylineLabel(
      lastLatLng,
      lengthInMeters,
      map,
      layer,
      measure
    );
  }

  handlePolygon(layer, map, measure) {
    const areaInSquareMeters = L.GeometryUtil.geodesicArea(
      layer.getLatLngs()[0]
    );
    const latLngs = layer.getLatLngs()[0];
    // console.log("latlng[0]",latLngs);
    const perimeterInMeters = this.calculatePerimeter(latLngs);
    // const centerLatLng = layer.getBounds().getCenter();
    const latLngsArray = layer.getLatLngs();
    const array = [latLngsArray[0].map((latLng) => [latLng.lng, latLng.lat])];
    // const coordinates=[array];
    const centerLatLng = polylabel(array, 1.0);
    return this.addPolygonLabel(
      centerLatLng,
      areaInSquareMeters,
      perimeterInMeters,
      map,
      layer,
      measure
    );
  }

  calculatePolylineLength(layer) {
    const lengthInMeters = L.GeometryUtil.length(layer);
    return lengthInMeters;
  }
  calculatePolygonArea(layer) {
    const areaInSquareMeters = L.GeometryUtil.geodesicArea(
      layer.getLatLngs()[0]
    );
    // const latLngs = layer.getLatLngs()[0];
    // const perimeterInMeters = this.calculatePerimeter(latLngs);
    return areaInSquareMeters;
  }

  addPolylineLabel(latLng, lengthInMeters, map, layer, measure) {
    if (layer.label) {
      map.removeLayer(layer.label);
    }
    if (measure !== null) {
      const label = L.marker(latLng, {
        icon: L.divIcon({
          className: "leaflet-div-label",
          html: `
                  
                   <div class="measure-label-box">  
                  <div class="measure-label" style="">
                  
                  <span class="measure-label-text">  ${
                    lengthInMeters < 1000
                      ? `${lengthInMeters.toFixed(2)}`
                      : `${(lengthInMeters / 1000).toFixed(2)} `
                  }  ${lengthInMeters < 1000 ? "m" : "km"}</span>
         
          </div>
               </div>
                  `,
        }),
      });
      measure.length = lengthInMeters;
      label.setOpacity(map.getZoom() >= 19 ? 1 : 0);
      layer.label = label;
      map.on("zoomend", () => {
        label.setOpacity(map.getZoom() >= 19 ? 1 : 0);
      });
      this.previousLabel = label;
      return label;
    } else {
      return (layer.length = lengthInMeters);
    }
  }

  addPolygonLabel(
    latLng,
    areaInSquareMeters,
    perimeterInMeters,
    map,
    layer,
    measure
  ) {
    // Remove previous label
    if (layer.label) {
      map.removeLayer(layer.label);
    }
    if (measure !== null) {
      const label = L.marker(latLng.reverse(), {
        icon: L.divIcon({
          className: "leaflet-div-label",
          html: `
               <div class="measure-label-box">  
                  <div class="measure-label" style="">
                  
                  <span class="measure-label-text"> ${
                    areaInSquareMeters < 1000000
                      ? `${areaInSquareMeters.toFixed(2)}`
                      : `${(areaInSquareMeters / 1000000).toFixed(2)} `
                  } ${areaInSquareMeters < 1000000 ? "m²" : "km²"}</span>
         
          </div>
               </div>
                `,
        }),
      });

      label.setOpacity(map.getZoom() >= 19 ? 1 : 0);

      measure.area = areaInSquareMeters;
      measure.perimeter = perimeterInMeters;

      layer.label = label;
      map.on("zoomend", () => {
        label.setOpacity(map.getZoom() >= 19 ? 1 : 0);
      });
      this.previousLabel = label;
      return label;
    } else {
      layer.area = areaInSquareMeters;
      layer.perimeter = perimeterInMeters;
    }
  }

  calculatePerimeter(latLngs) {
    let perimeterInMeters = 0;
    for (let i = 0; i < latLngs.length - 1; i++) {
      const latLng1 = latLngs[i];
      const latLng2 = latLngs[i + 1];
      const distance = latLng1.distanceTo(latLng2);
      perimeterInMeters += distance;
    }
    const lastLatLng = latLngs[latLngs.length - 1];
    const firstLatLng = latLngs[0];
    const closingDistance = lastLatLng.distanceTo(firstLatLng);
    perimeterInMeters += closingDistance;
    return perimeterInMeters;
  }
}
class LayerControlUtils {
  handleRadioButtonClick = (
    index,
    vectorMeasurentLayers,
    map,
    visibleLayers
  ) => {
    const markerLayer = vectorMeasurentLayers[index];
    if (markerLayer && visibleLayers[index]) {
      markerLayer.addTo(map);
    } else {
      map.removeLayer(markerLayer);
    }
  };

  handleMarkerColorChange = (index, colour, vectorMeasurentLayers, map) => {
    const markerLayer = vectorMeasurentLayers[index];

    if (markerLayer && markerLayer._icon) {
      const iconElement = markerLayer._icon;
      iconElement.style.color = colour; // Change color directly using style property
    }

    if (markerLayer && markerLayer.type !== "marker") {
      markerLayer.setStyle({ color: colour }); // Set color for non-marker layers
      markerLayer.addTo(map);
    }
  };
}

// Export the classes
export { MarkerClass, Measure, LayerControlUtils };

//     <p class="ml-[1px] text-[10px]">
//       ${
//         perimeterInMeters < 1000
//           ? `${perimeterInMeters.toFixed(2)}`
//           : `${(perimeterInMeters / 1000).toFixed(2)} `
//       }
//     </p>
//     <p class="ml-[1px] text-[10px]">
//       ${perimeterInMeters < 1000 ? "m" : "Km"}
//     </p>
//   </div>
// </div>

//  <div class="flex items-center mt-[0.5px]">
//                       <div class="flex items-center">
//                         <p class="m-0 text-[10px]">
//                           Perimeter:
//                         </p>

//                 </div>
// <button class="remove" aria-label="Remove"><span><svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11"><g fill="none" fill-rule="evenodd" stroke-linecap="round"><line x1="9.5" x2="1.5" y1="1.5" y2="9.5" stroke="#FFF" stroke-width="1.75"/><line x1="9.5" x2="1.5" y1="1.5" y2="9.5" stroke="#FFF" stroke-width="1.75" transform="rotate(-90 5.5 5.5)"/></g></svg></span></button>
