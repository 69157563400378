import { Tab, TabPanel, Tabs, TabsList } from "@mui/base/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Slider from "@mui/material/Slider";
import L from "leaflet";
import omnivore from "leaflet-omnivore";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  svStationMarker,
  visibilityIco,
  visibilityOffIco,
} from "../../../Assets/icons/gisIcons/icons";
import { Measure } from "../../../Utils/Leaflet/LeafletUtils";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";

const SidebarLayersLeaflet = ({ openLayers, mapRef }) => {
  const map = mapRef.current;
  const { refCounterMeasure } = useSelector((state) => state.gisLeaflet);
  const { asset } = useSelector((state) => state.gisLeaflet);
  const { KmlsetArray } = useSelector((state) => state.gisLeaflet);
  const [measurementArray, setMeasurementArray] = useState(null);
  const measure = new Measure();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [hideAll, setHideAll] = useState(true);
  const handleAccordionToggle = (panelIndex) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelIndex : false);
  };
  const getLabelLayerById = (id) => {
    let targetLayer = null;
    map.eachLayer((layer) => {
      if (layer._leaflet_id === id && layer instanceof L.LayerGroup) {
        targetLayer = layer.getLayers()[1];
      }
    });
    return targetLayer;
  };
  const toggleKmlVisibilityById = (id) => {
    const KmlToUpdate = KmlsetArray.find((kml) => kml.id === id);
    if (KmlToUpdate) {
      dispatch(gisLeafletActions.toggleVisibility(id));
    } else {
    }
  };
  const toggleMeasurementVisibilityById = (id) => {
    const measurementToUpdate = measurementArray.find(
      (measurement) => measurement.id === id
    );

    if (measurementToUpdate) {
      measurementToUpdate.visible = !measurementToUpdate.visible;

      setMeasurementArray((prevMeasurements) => {
        const updatedMeasurements = prevMeasurements.map((measurement) => {
          if (measurement.id === id) {
            return measurementToUpdate;
          }
          return measurement;
        });
        return updatedMeasurements;
      });

      const layer = getLayerById(id);

      // Check if the layer exists
      if (layer) {
        // Toggle the opacity of the layer based on visibility
        const opacity = measurementToUpdate.visible ? 0.5 : 0;
        const opacitylabel = measurementToUpdate.visible ? 1 : 0;

        // Update the opacity of the layer and label
        if (measurementToUpdate.filter_type === "polyline") {
          layer.setStyle({ opacity: opacity });
          // layer.label.setStyle({ opacity: opacity });
          layer.label.setOpacity(opacitylabel);
        } else if (measurementToUpdate.filter_type === "polygon") {
          layer.setStyle({
            fillOpacity: opacity, // Set fill opacity
            opacity: opacity, // Set border opacity
          });
          layer.label.setOpacity(opacitylabel);
        }
      } else {
        //console.error(`Layer with ID ${id} not found.`);
      }
    } else {
      //console.error(`Measurement with ID ${id} not found.`);
    }
    localStorage.setItem("measurementData", JSON.stringify(measurementArray));
  };
  const toggleVisibilityOfAll = () => {
    // Check if any measurement is currently invisible
    const anyInvisible = measurementArray.some(
      (measurement) => !measurement.visible
    );

    // Toggle visibility of all measurements based on whether any are currently invisible
    measurementArray.forEach((measurement) => {
      // Toggle the visibility of each measurement based on whether any are currently invisible
      if (anyInvisible) {
        if (!measurement.visible) {
          // Only toggle if the measurement is currently invisible
          setHideAll(true);
          toggleMeasurementVisibilityById(measurement.id);
        }
      } else {
        if (measurement.visible) {
          // Only toggle if the measurement is currently visible
          toggleMeasurementVisibilityById(measurement.id);
          setHideAll(false);
        }
      }
    });
  };
  const loadKML = (kmlFile, id) => {
    return fetch(kmlFile)
      .then((response) => response.text())
      .then((kmlData) => {
        const kmlLayer = omnivore.kml.parse(kmlData);
        // console.log(kmlLayer, "parsed kml formated file");
        return kmlLayer;
      })
      .catch((error) => {
        console.error("Error loading KML:", error);
        throw error;
      });
  };

  const kmlLayerGroup = L.featureGroup().addTo(map);
  function getKmlLayerById(layerId) {
    let foundLayer = null;
    kmlLayerGroup.eachLayer((layer) => {
      if (layer._leaflet_id === layerId) {
        foundLayer = layer;
      }
    });
    return foundLayer;
  }

  const [firstTimeAdd, setFirstTimeAdd] = useState(0);
  useEffect(() => {
    const getDataFromKml = () => {
      try {
        // Retrieve measure data from local storage

        if (!KmlsetArray) {
          return;
        }
        // Clear existing markers on the map
        // kmlLayerGroup.clearLayers();
        if (KmlsetArray.length > 0) {
          // Iterate over each measure in the filtered local data
          KmlsetArray.forEach((kmlObj) => {
            // Check if the measure exists on the map
            // console.log("object-", kmlObj);
            const existingMarker = getKmlLayerById(kmlObj.id);
            // console.log("existing", existingMarker);
            if (kmlObj.visible) {
              if (existingMarker === null) {
                loadKML(kmlObj.kmlFilePath, kmlObj.id)
                  .then((kmlLayer) => {
                    kmlLayer._leaflet_id = kmlObj.id; // Use the provided id
                    kmlLayerGroup.addLayer(kmlLayer);
                    const bounds = kmlLayer.getBounds();
                    // console.log(kmlLayer, "kmlLayer");

                    const defaultMarkerIcon = new L.Icon({
                      className: "custom-marker-icon",
                      iconUrl: svStationMarker,
                      iconSize: [32, 32],
                    });

                    var customIcon = L.divIcon({
                      className: "custom-div-icon",
                      html: '<img src="path/to/custom-icon.png" class="custom-icon"/><div class="custom-label">Label Text</div>',
                      iconAnchor: [0, -10],
                    });

                    const chainageLabelCreator = (lat, lon) => {
                      const label = L.marker([lat, lon], {
                        icon: L.divIcon({
                          className: "leaflet-div-label",
                          html: `<div class="text-[10px] absolute bg-black bg-opacity-50 text-white py-0.5 px-1 rounded w-max">km</div>`,
                          iconAnchor: [0, -10],
                        }),
                        zIndexOffset: 20,
                        pane: "labels",
                      });
                    };
                    if (kmlObj.KmlName === "Center Line") {
                      kmlLayer.setStyle({
                        fillColor: "transparent",
                        color: "#ff0000",
                      });
                      kmlLayer.bringToFront();
                      kmlLayer.type = "polyline";
                    } else if (kmlObj.KmlName === "SV Stations") {
                      // console.log(kmlObj, "sv statiosn");
                      kmlLayer.type = "marker";
                      kmlLayer.eachLayer(function (layer) {
                        if (layer instanceof L.Marker) {
                          // console.log(layer, "layer from layer ");
                          layer.setIcon(defaultMarkerIcon);
                          const label = L.marker(layer.getLatLng(), {
                            icon: L.divIcon({
                              className: "leaflet-div-label",
                              html: `<div class="text-[10px] absolute bg-black bg-opacity-50 text-white py-0.5 px-1 rounded w-max">${layer.feature.properties.name} </div>`,
                              iconAnchor: [0, -10],
                            }),
                            zIndexOffset: 20,
                          });
                          label.addTo(map);
                          layer.label = label;
                          // label.setOpacity(map.getZoom() >= 14 ? 1 : 0);
                          // chainageLabelCreator();
                          // Set the custom icon for each marker in the KML layer
                        }
                      });
                    } else if (kmlObj.KmlName === "RoU") {
                      kmlLayer.setStyle({
                        fillColor: "transparent",
                      });
                      kmlLayer.type = "polygon";
                      kmlLayer.bringToBack();
                    }
                    // kmlLayer.setStyle({
                    //   color: "red",
                    // });

                    if (bounds.isValid() && firstTimeAdd === 0) {
                      map.fitBounds(bounds);
                      setFirstTimeAdd(firstTimeAdd + 1);
                      // console.log("increamet");
                    }
                  })
                  .catch((error) => {
                    console.error("Error returning KML:", error);
                  });
              }
            } else if (!kmlObj.visible) {
              const kmlLayer = getKmlLayerById(kmlObj.id);
              if (kmlLayer !== null) {
                kmlLayerGroup.removeLayer(kmlLayer);
              }
            }
          });
        } else {
        }
      } catch (error) {
        console.error("Error", error);
      }
    };

    getDataFromKml();
    return () => {
      map.removeLayer(kmlLayerGroup);
    };
  }, [KmlsetArray]);
  useEffect(() => {
    const handleZoomChange = () => {
      if (!KmlsetArray) {
        return;
      }
      KmlsetArray.forEach((kmlObj) => {
        kmlLayerGroup.eachLayer((kmlLayer) => {
          if (kmlLayer._leaflet_id === kmlObj.id) {
            if (kmlObj.KmlName === "Center Line") {
              if (map.getZoom() < 14) {
                kmlLayer.setStyle({
                  weight: 5,
                  fillColor: "transparent",
                  color: "#ff0000",
                });
              } else {
                kmlLayer.setStyle({
                  weight: 4,
                  fillColor: "transparent",
                  color: "#ff0000",
                });
              }
            } else if (kmlObj.KmlName === "RoU") {
              if (map.getZoom() < 14) {
                kmlLayer.setStyle({
                  weight: 0,
                  fillColor: "transparent",
                });
              } else {
                kmlLayer.setStyle({
                  weight: 3,
                  fillColor: "transparent",
                });
              }
            } else if (kmlObj.KmlName === "SV Stations") {
              // console.log(kmlObj, "sv statiosn");
              kmlLayer.type = "marker";
              kmlLayer.eachLayer(function (layer) {
                if (layer instanceof L.Marker) {
                  layer.label.setOpacity(map.getZoom() >= 16 ? 1 : 0);
                }
              });
            }
          }
        });
      });
    };

    map.on("zoomend", handleZoomChange);

    // Cleanup function to remove the event listener
    return () => {
      map.off("zoomend", handleZoomChange);
    };
  }, [map, KmlsetArray]);

  const markerFeatureGroup = L.featureGroup().addTo(map);
  useEffect(() => {
    const fetchDataFromLocalStorage = async () => {
      try {
        // Retrieve measure data from local storage
        const localData = JSON.parse(localStorage.getItem("measurementData"));

        if (!localData) {
          // //console.log("No measurements found");
          return;
        }

        const assetLocalData = localData.filter(
          (measurement) => asset.Name === measurement.assetId
        );
        setMeasurementArray(assetLocalData);

        // Clear existing markers on the map
        markerFeatureGroup.clearLayers();

        if (assetLocalData.length > 0) {
          // Iterate over each measure in the filtered local data
          assetLocalData.forEach((measurement) => {
            // Check if the measure exists on the map
            const existingMarker = getLayerById(measurement.id);
            if (existingMarker === null) {
              // Add measure to the map based on filter_type
              if (measurement.filter_type === "polyline") {
                const polyline = L.polyline(measurement.measurePosition, {
                  stroke: true,
                  color: "#fff",
                  weight: 4,
                  opacity: 0.9,
                  lineCap: "round",
                  lineJoin: "round",
                  fill: false,
                  clickable: true,
                });
                // Add polyline to the map with ID
                polyline._leaflet_id = measurement.id;
                polyline.type = measurement.filter_type;
                // Call a function that calculates length and stores it in db,
                const labelpolyline = measure.handlePolyline(
                  polyline,
                  map,
                  measurement
                );

                // polyline.on("click", addToolTip(polyline));

                markerFeatureGroup.addLayer(polyline);
                markerFeatureGroup.addLayer(labelpolyline);
                // Add onclick function to polyline
                // Bind a popup to the polyline
                polyline.bindPopup(
                  `<span>
                      ${
                        measurement.length < 1000
                          ? `Length: ${measurement.length?.toFixed(2)} m`
                          : `Length: ${(measurement.length / 1000)?.toFixed(
                              2
                            )} km`
                      }
                  </span>`
                );

                // Add polyline to feature group
              } else if (measurement.filter_type === "polygon") {
                const polygon = L.polygon(measurement.measurePosition, {
                  stroke: true,
                  color: "#000",
                  weight: 4,
                  opacity: 0.9,
                  lineCap: "round",
                  lineJoin: "round",
                  fill: true,
                  clickable: true,
                  fillColor: "#fff",
                  fillOpacity: 0.2,
                });
                // Add polygon to the map with ID
                polygon._leaflet_id = measurement.id;
                polygon.type = measurement.filter_type;
                // Calculate area, perimeter, etc.
                const labelPolygon = measure.handlePolygon(
                  polygon,
                  map,
                  measurement
                );

                // Bind a popup to the polygon
                polygon.bindPopup(
                  `<span class="text-bold">
                    ${
                      measurement.area < 1000000
                        ? `Area: ${measurement.area?.toFixed(2)} m²`
                        : `Area: ${(measurement.area / 1000000)?.toFixed(
                            2
                          )} km²`
                    }
                    <br>
                    ${
                      measurement.perimeter < 1000
                        ? `Perimeter: ${measurement.perimeter?.toFixed(2)} m`
                        : `Perimeter: ${(measurement.perimeter / 1000)?.toFixed(
                            2
                          )} km`
                    }
                </span> 
                `
                );
                markerFeatureGroup.addLayer(polygon); // Add polygon to feature group
                markerFeatureGroup.addLayer(labelPolygon);
              }
            }
          });
        } else {
          //console.log(
          //   "No measurement data found for the asset in local storage"
          // );
        }

        // Add the feature group to the map
        // markerFeatureGroup.addTo(map);
        // Update the specific measurements in local storage, if necessary
        // (This part could be optimized further if only specific measurements are updated)
        localStorage.setItem("measurementData", JSON.stringify(localData));
      } catch (error) {
        //console.error("Error fetching data from local storage:", error);
      }
    };

    fetchDataFromLocalStorage();
    return () => {
      map.removeLayer(markerFeatureGroup);
    };
  }, [refCounterMeasure, asset]);

  // Function to get layer by ID
  const getLayerById = (id) => {
    let targetLayer = null;
    map.eachLayer((layer) => {
      if (layer._leaflet_id === id) {
        // console.log(layer, "matched layer");
        targetLayer = layer;
      }
    });
    return targetLayer;
  };

  const clearMeasure = (id) => {
    let layerToRemove = getLayerById(id);
    map.removeLayer(layerToRemove.label); // remove label
    // console.log(layerToRemove, "--layerToRemove");
    map.removeLayer(layerToRemove); //remove layer
    const existingLayers =
      JSON.parse(localStorage.getItem("measurementData")) || [];
    const updatedLayers = existingLayers.filter(
      (layer, index) => layer.id !== id
    );
    localStorage.setItem("measurementData", JSON.stringify(updatedLayers));
    setMeasurementArray(updatedLayers);
    // dispatch(gisLeafletActions.refCounterMeasureUpdate());
    // avoids calling useEffect and re-add all markers again thus colous and other layercontroll properties of exiting makers are maintained
  };
  // const clearAllMeasure = () => {
  //   map.eachLayer((layer) => {
  //     if (layer.label) {
  //       map.removeLayer(layer.label);
  //     }
  //   });
  //   markerFeatureGroup.clearLayers();

  //   localStorage.setItem("measurementData", JSON.stringify([]));
  //   setHideAll(!hideAll);
  //   dispatch(gisLeafletActions.refCounterMeasureUpdate());
  // };
  const clearAllMeasure = () => {
    // Check if all measurements are selected
    const allSelected = measurementArray.every(
      (measurement) => subCheckboxesChecked[`checkbox${measurement.id}`]
    );

    if (allSelected) {
      // Clear all measurements if all are selected
      map.eachLayer((layer) => {
        if (layer.label) {
          map.removeLayer(layer.label);
        }
      });
      markerFeatureGroup.clearLayers();

      localStorage.setItem("measurementData", JSON.stringify([]));
      setHideAll(!hideAll);
      dispatch(gisLeafletActions.refCounterMeasureUpdate());
    } else {
      // Clear only selected measurements
      const selectedMeasurements = measurementArray.filter(
        (measurement) => subCheckboxesChecked[`checkbox${measurement.id}`]
      );

      selectedMeasurements.forEach((measurement) => {
        clearMeasure(measurement.id);
      });
    }
  };
  const deleteSingleMeasures = (id) => {
    const promise = new Promise((resolve, reject) => {
      const confirmDelete = () => {
        toast(
          <div className="text-center">
            <p className="text-center">
              Are you sure you want to delete this measurement?
            </p>
            <div className="flex justify-center mt-2 gap-4">
              <button
                className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                onClick={() => {
                  clearMeasure(id);
                  resolve("Deleted!"); // Resolve with a success message when "Yes" is clicked
                  toast.dismiss();
                }}
              >
                Yes
              </button>
              <button
                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => {
                  resolve("Canceled"); // Resolve with null when "No" is clicked
                  toast.dismiss();
                }}
              >
                No
              </button>
            </div>
          </div>,
          {
            duration: null, // Set duration to null to keep toast open until resolved
            position: "top-center",
            style: {
              minWidth: "300px",
            },
            onClose: () => {
              // onClose(); // Close the popup when the toast is closed
            },
          },
          {
            duration: 5000,
          }
        );
      };

      confirmDelete();
      // Add event listener to the map for click events
      map.on("click", () => {
        // resolve("Canceled"); // Resolve with 'Canceled' when user clicks on the map
        toast.dismiss();
      });
    });

    promise.then((value) => {
      if (value && value !== "Canceled") {
        toast.success(value, {
          duration: 1000,
          icon: "☑️",
          iconTheme: {
            success: "#FF0000",
            secondary: "#FF0000",
          },
        }); // Display success toast with duration 2 seconds
      } else if (value === "Canceled") {
        toast.error("Canceled", { duration: 1500 }); // Display 'Canceled' toast with duration 2 seconds
      }
    });
  };
  const deleteAllMeasures = () => {
    const promise = new Promise((resolve, reject) => {
      const confirmDelete = () => {
        toast(
          <div>
            Are you sure you want to delete all measurements?
            <div className="flex justify-center mt-2">
              <button
                className="text-white bg-red-500 py-2 px-4 rounded mr-4"
                onClick={() => {
                  clearAllMeasure();
                  resolve("Deleted!"); // Resolve with a success message when "Yes" is clicked
                  toast.dismiss();
                }}
              >
                Yes
              </button>
              <button
                className="text-white bg-gray-500 py-2 px-4 rounded"
                onClick={() => {
                  resolve("Canceled"); // Resolve with null when "No" is clicked
                  toast.dismiss();
                }}
              >
                No
              </button>
            </div>
          </div>,
          {
            duration: null, // Set duration to null to keep toast open until resolved
            position: "top-center",
            style: {
              minWidth: "300px",
            },
            onClose: () => {
              // onClose(); // Close the popup when the toast is closed
            },
          },
          {
            duration: 5000,
          }
        );
      };

      confirmDelete();
      // Add event listener to the map for click events
      map.on("click", () => {
        // resolve("Canceled"); // Resolve with 'Canceled' when user clicks on the map
        toast.dismiss();
      });
    });

    promise.then((value) => {
      if (value && value !== "Canceled") {
        toast.success(value, {
          duration: 1000,
          icon: "☑️",
          iconTheme: {
            success: "#FF0000",
            secondary: "#FF0000",
          },
        }); // Display success toast with duration 2 seconds
      } else if (value === "Canceled") {
        toast.error("Canceled", { duration: 1500 }); // Display 'Canceled' toast with duration 2 seconds
      }
    });
  };

  //edit control
  const [showMeasureEditButtonsArray, setShowMeasureEditButtonsArray] =
    useState(
      // measurementStateDat`a ? measurementStateData.map(() => true) : []
      measurementArray ? measurementArray?.map(() => false) : [false]
    );
  const [editMeasureMode, setEditMeasureMode] = useState(false);
  const [selectedMeasureLayer, setSelecteMeasuredLayer] = useState(null);
  const [selectedMeasureLayerIndex, setSelecteMeasuredLayerIndex] =
    useState(null);
  const editCtrlMeasure = useRef(null); // Ref to hold the editCtrl object

  const handleMeasureEditClick = (id, index) => {
    const layer = getLayerById(id);
    map.removeLayer(layer.label);

    var mulitiEdit = false;
    if (
      selectedMeasureLayer !== layer &&
      selectedMeasureLayer &&
      editMeasureMode
    ) {
      handleMeasureSaveEdit(selectedMeasureLayerIndex);
      mulitiEdit = true;
    }
    setSelecteMeasuredLayer(layer); // Set the selected layer for editing
    setSelecteMeasuredLayerIndex(index);
    map.removeLayer(layer.label);
    if (layer) {
      // Toggle the edit mode
      setEditMeasureMode((prevEditMeasureMode) => !prevEditMeasureMode);
      // Show the edit buttons
      // setMeasureShowEditButtons(
      //   (prevMeasureShowEditButtons) => !prevMeasureShowEditButtons
      // );
      // Show the edit buttons for the specific measure
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = !newArray[index];
        return newArray;
      });

      const selectedMeasureFeatureGroup = L.featureGroup([layer]);
      // Initialize the edit control with the new feature group if it's not initialized
      if (!editCtrlMeasure.current) {
        const editCtrlMeasureOptions = {
          featureGroup: selectedMeasureFeatureGroup,
        };
        editCtrlMeasure.current = new L.EditToolbar.Edit(
          map,
          editCtrlMeasureOptions
        );
      }
      // Enable or disable the edit functionality based on the edit mode
      if (!editMeasureMode || mulitiEdit) {
        editCtrlMeasure.current.enable();
      } else {
        editCtrlMeasure.current.disable();
        // setMeasureShowEditButtons(false); // Hide the edit buttons
        // Hide the edit buttons for the specific measure
        setShowMeasureEditButtonsArray((prevArray) => {
          const newArray = [...prevArray];
          newArray[index] = false;
          return newArray;
        });
        updateMarkerGeometry(layer, map, measurementArray[index]);
        editCtrlMeasure.current = null;
        setSelecteMeasuredLayer(null);
      }
    }
  };

  const updateMarkerGeometry = (layer, map, measurement) => {
    if (layer.type === "polygon") {
      // Calculate area, perimeter, etc.
      const labelPolygon = measure.handlePolygon(layer, map, measurement);
      // labelPolygon.addTo(map);
      markerFeatureGroup.addLayer(labelPolygon);
    } else {
      const labelPolyline = measure.handlePolyline(layer, map, measurement);
      // labelPolyline.addTo(map);
      markerFeatureGroup.addLayer(labelPolyline);
    }
    measurement.measurePosition = layer?.getLatLngs();
    const updatedMarkerData = measurementArray.map((marker, idx) => {
      if (marker === measurement) {
        return marker;
      }
      return marker;
    });
    // Update the markerData state
    setMeasurementArray(measurementArray);
    // // Update the markerData in local storage
    localStorage.setItem("measurementData", JSON.stringify(updatedMarkerData));
    // dispatch(gisLeafletActions.refCounterMeasureUpdate());
  };

  const handleMeasureSaveEdit = (index) => {
    if (selectedMeasureLayer && editCtrlMeasure.current) {
      editCtrlMeasure.current.save();
      editCtrlMeasure.current.disable();
      // Toggle the edit mode
      setEditMeasureMode((prevEditMeasureMode) => !prevEditMeasureMode);
      // Show the edit buttons
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = !newArray[index];
        return newArray;
      });
      map.addLayer(selectedMeasureLayer.label);
      updateMarkerGeometry(selectedMeasureLayer, map, measurementArray[index]);
      editCtrlMeasure.current = null;
      setSelecteMeasuredLayer(null);
    }
  };

  const handleMeasureCancelEdit = (index) => {
    if (editMeasureMode && selectedMeasureLayer) {
      if (editCtrlMeasure.current) {
        // Check if  editCtrlMeasure.current is initialized
        editCtrlMeasure.current.revertLayers();
        editCtrlMeasure.current.disable(); // Disable the edit control
      }
      setEditMeasureMode(false); // Disable edit mode
      // setMeasureShowEditButtons(false); // Hide the edit buttons
      // Hide the edit buttons for the specific measure
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = false;
        return newArray;
      });
      map.addLayer(selectedMeasureLayer.label);
      updateMarkerGeometry(selectedMeasureLayer, map, measurementArray[index]);

      editCtrlMeasure.current = null;
      setSelecteMeasuredLayer(null);
    }
  };

  const [fillOpacityVal, setFillOpacity] = useState(0.5);
  const handleOpacityChange = (id, OvalueChange) => {
    setFillOpacity(OvalueChange);
    const markerLayer = getLayerById(id);
    // If the marker layer exists
    if (markerLayer) {
      // Determine the type of the layer
      if (markerLayer.type === "polyline") {
        markerLayer.setStyle({ opacity: OvalueChange });
      } else if (markerLayer.type === "polygon") {
        let fillINOpacity;
        if (OvalueChange > 0.2) {
          fillINOpacity = 0.2;
        } else {
          fillINOpacity = OvalueChange;
        }
        markerLayer.setStyle({
          fillOpacity: fillINOpacity, // Set fill opacity
          opacity: OvalueChange, // Set border opacity
        });
        // console.log(
        //   "layer in opacity",
        //   markerLayer,
        //   "opacity value",
        //   OvalueChange,
        //   "id",
        //   id
        // );
      } else {
        //console.log("Unknown layer type");
        markerLayer.eachLayer(function (layer) {
          if (layer instanceof L.Marker) {
            layer.setOpacity(OvalueChange);
          }
        });
      }
    } else {
      //console.log("Layer not found");
    }
  };

  const handleMarkerColorChange = (id, colour) => {
    // Find the layer with the corresponding index
    const markerLayer = getLayerById(id);
    // If the marker layer exists
    if (markerLayer && markerLayer.type !== "marker") {
      // Check if it's a polygon layer
      if (markerLayer instanceof L.Polygon) {
        // Update the style of the polygon
        markerLayer.setStyle({ color: colour, fillColor: colour });
      } else if (markerLayer instanceof L.Polyline) {
        // Update the style of the polyline
        markerLayer.setStyle({ color: colour });
      }
    }
  };

  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false); // State for main checkbox
  const initialSubCheckboxesState = Object.fromEntries(
    measurementArray?.map((measurement) => [
      `checkbox${measurement.id}`,
      false,
    ]) ?? []
  );

  const [subCheckboxesChecked, setSubCheckboxesChecked] = useState(
    initialSubCheckboxesState
  ); // State for sub-checkboxes

  // Function to toggle the state of all sub-checkboxes

  const handleSelectAllChange = () => {
    const newSelectAllChecked = !selectAllChecked;
    setSelectAllChecked(newSelectAllChecked);

    // Determine the new state for all checkboxes
    const newSubCheckboxesChecked = {};
    measurementArray?.forEach((measurement) => {
      newSubCheckboxesChecked[`checkbox${measurement.id}`] =
        newSelectAllChecked;
    });
    setSubCheckboxesChecked(newSubCheckboxesChecked);
  };

  // Function to toggle the state of a single sub-checkbox
  const handleSubCheckboxChange = (key) => {
    const newSubCheckboxesChecked = { ...subCheckboxesChecked };
    newSubCheckboxesChecked[key] = !newSubCheckboxesChecked[key];
    setSubCheckboxesChecked(newSubCheckboxesChecked);
    setSelectAllChecked(
      Object.values(newSubCheckboxesChecked).every((value) => value)
    );
  };

  const toggleCheckboxes = () => {
    setShowCheckboxes(!showCheckboxes);
  };

  const resetCheckboxes = () => {
    setShowCheckboxes(false);
  };
  return (
    <div
      className={`${
        openLayers ? "block" : "hidden"
      } relative rounded-md min-w-[18rem]  !grow h-screen overflow-y-auto mr-2 ml-2 mb-2 mt-2 border-solid border-[#d7d8d8] border-[1px] bg-white`}
    >
      <div className="w-full rounded-tl-md rounded-tr-md flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
        <div className="grow flex items-center justify-center">Layers</div>
      </div>
      <div>
        <Tabs defaultValue={1}>
          <TabsList className="mb-2 rounded-md bg-white flex  items-center justify-center content-between min-w-tabs-list font-[400]">
            <Tab
              slotProps={{
                root: ({ selected, disabled }) => ({
                  className: ` ${
                    selected
                      ? "text bg-[#ffca0094] "
                      : "bg-transparent focus:text-white hover:bg-[#faea8e] border-[#ffca0094]"
                  } ${
                    disabled
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  }w-full p-2 m-1.5 border rounded-lg flex justify-center focus:outline-0 focus:shadow-outline-purple-light`,
                }),
              }}
              value={1}
            >
              Measurements
            </Tab>
            <Tab
              slotProps={{
                root: ({ selected, disabled }) => ({
                  className: ` ${
                    selected
                      ? " bg-[#ffca0094]"
                      : "bg-transparent focus:text-white border-[#ffca0094] hover:bg-[#faea8e] "
                  } ${
                    disabled
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  } w-full p-2 m-1.5 border rounded-md flex justify-center focus:outline-0 focus:shadow-outline-purple-light`,
                }),
              }}
              value={2}
            >
              Assets
            </Tab>
          </TabsList>
          {/* {(measurementArray?.length > 0 || KmlsetArray?.length > 0) ? (
            <div className="flex gap-4 items-center  justify-center my-1">
              {!showCheckboxes ? (
                <button onClick={toggleCheckboxes}>Select</button>
              ) : (
                <>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectAllChecked}
                      onChange={handleSelectAllChange}
                    />
                    Select All
                  </label>
                  <button onClick={resetCheckboxes}>X</button>
                </>
              )}

              <button
                className={`visibility-toggle-btn
                        ${editMeasureMode || !showCheckboxes
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                  }
                        `}
                onClick={(event) => {
                  event.stopPropagation();
                  setHideAll(!hideAll);
                  toggleVisibilityOfAll();
                }}
                disabled={editMeasureMode || !showCheckboxes}
              >
                <img
                  src={hideAll ? visibilityIco : visibilityOffIco}
                  alt={hideAll ? "Visible" : "Hidden"}
                  className="h-7"
                />
              </button>
              <button
                onClick={() => {
                  // clearAllMeasure();
                  deleteAllMeasures();
                }}
                className={`
                        ${editMeasureMode || !showCheckboxes
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                  }
                        flex justify-center items-center text-xs  p-1 px-2  rounded-md w-auto h-[28px]  border my-auto cursor-pointer  mr-[4px] text-red-700 hover:text-white  border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium  dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900
                        `}
                disabled={editMeasureMode || !showCheckboxes}
              >
                Delete
              </button>
            </div>
          ) : (
            <></>
          )} */}
          <TabPanel className="w-full" key="measuremetnsTab" value={1}>
            <div className="flex-1 overflow-y-auto overflow-x-hidden">
              {measurementArray?.length <= 0 || !measurementArray ? (
                <p className="text-center text-sm p-2">
                  No measurements created
                </p>
              ) : (
                measurementArray?.map((measurement, index) => (
                  <Accordion
                    className={`text-sm border-t  border-white  !my-0  !p-0 ${
                      editMeasureMode ? "" : ""
                    }`}
                    key={index + 1}
                    expanded={expanded === index}
                    onChange={handleAccordionToggle(index)}
                    disabled={editMeasureMode}
                    title={
                      editMeasureMode
                        ? "Save or cancel editing current measure first"
                        : ""
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      onClick={(event) => event.stopPropagation()}
                      className=" flex  !items-center !justify-center min-h-max m-0 "
                    >
                      {showCheckboxes ? (
                        <label key={measurement.id}>
                          <input
                            type="checkbox"
                            checked={
                              subCheckboxesChecked[`checkbox${measurement.id}`]
                            }
                            onChange={() =>
                              handleSubCheckboxChange(
                                `checkbox${measurement.id}`
                              )
                            }
                          />
                        </label>
                      ) : (
                        <button
                          className={`visibility-toggle-btn`}
                          onClick={(event) => {
                            event.stopPropagation();
                            // toggleMarkerVisibility(marker);
                            toggleMeasurementVisibilityById(measurement.id);
                          }}
                          disabled={showMeasureEditButtonsArray[index]}
                        >
                          {measurement?.visible ? (
                            <img
                              src={visibilityIco}
                              alt="Visible"
                              className="h-4"
                            />
                          ) : (
                            <img
                              src={visibilityOffIco}
                              alt="Hidden"
                              className="h-4"
                            />
                          )}
                        </button>
                      )}

                      <div className="rounded-md ml-2 flex justify-between items-center gap-4">
                        <p
                          className="btn-dangerrounded "
                          onClick={(event) => {
                            event.stopPropagation();
                            if (measurement.measurePosition) {
                              const latlng = [measurement.measurePosition];
                              const bounds = L.latLngBounds(latlng);
                              map?.flyToBounds(bounds, {
                                duration: 2, // Animation duration in seconds
                                easeLinearity: 0.5, // Animation easing factor (0 = linear, 1 = same speed throughout)
                                maxZoom: 18,
                              });
                            } else {
                              //console.error("Layer coordinates not available");
                            }
                            //console.log("clicked on measurement");
                          }}
                        >
                          <p key={index}>
                            {measurement.filter_type === "polyline"
                              ? "Distance "
                              : "Area "}
                            {`measure ${index + 1}`}
                          </p>
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      disabled={!measurement.visible}
                      className="!mb-0 !mt-0 grow w-full"
                    >
                      <div className="flex flex-col text-sm">
                        <div className="flex items-center">
                          <div className="mr-2">Change color:</div>
                          <input
                            type="color"
                            className="rounded-md border"
                            // value={selectedColor}
                            onChange={(e) => {
                              handleMarkerColorChange(
                                measurement.id,
                                e.target.value
                              );
                            }}
                            disabled={!measurement.visible}
                          />
                        </div>
                        <div
                          className="flex items-center justify-center
                      "
                        >
                          <p className="w-[70px]">Opacity:</p>
                          <Slider
                            min={0}
                            max={1}
                            step={0.1}
                            value={fillOpacityVal}
                            valueLabelDisplay="auto"
                            onChange={(e) =>
                              handleOpacityChange(
                                measurement.id,
                                e.target.value
                              )
                            }
                            disabled={!measurement.visible || editMeasureMode} // Disable slider if marker is not visible
                            className="w-1/2 h-1"
                          />
                        </div>
                      </div>
                      {measurement.filter_type === "polyline" ? (
                        <span>
                          {measurement.length < 1000
                            ? ` Length: ${measurement.length?.toFixed(2)} m`
                            : ` Length:  ${(measurement.length / 1000)?.toFixed(
                                2
                              )} km`}
                        </span>
                      ) : (
                        <span>
                          {measurement.area < 1000000
                            ? `  Area:  ${measurement.area?.toFixed(2)} m²`
                            : `  Area: ${(measurement.area / 1000000)?.toFixed(
                                2
                              )} km²`}

                          <br></br>
                          {measurement.perimeter < 1000
                            ? ` Perimeter: ${measurement.perimeter?.toFixed(
                                2
                              )} m`
                            : ` Perimeter: ${(
                                measurement.perimeter / 1000
                              )?.toFixed(2)} km`}
                        </span>
                      )}
                      {/* <div className="flex gap-2 items-center  justify-center mt-2  ">
                        {!showMeasureEditButtonsArray[index] ? (
                          <button
                            onClick={() => {
                              deleteSingleMeasures(measurement.id);
                            }}
                            className="flex justify-center items-center text-xs  p-1 px-2  rounded-md w-auto h-[28px]  border my-auto cursor-pointer  mr-[4px] text-red-700 hover:text-white  border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium  dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
                          >
                            Delete
                          </button>
                        ) : null}
                        {!showMeasureEditButtonsArray[index] ? (
                          <button
                            className={`flex justify-center items-center text-xs  p-1 px-2  rounded-md w-auto  border my-auto cursor-pointer 
                            ${
                              showMeasureEditButtonsArray[index]
                                ? "bg-purple-400 text-white"
                                : "border-purple-400 text-purple-400 hover:bg-purple-400 hover:text-white"
                            }
                              ${
                                !measurement.visible
                                  ? "opacity-50 cursor-not-allowed"
                                  : ""
                              } `}
                            onClick={() => {
                              handleMeasureEditClick(measurement.id, index);
                            }}
                            disabled={!measurement.visible}
                          >
                            <svg
                              className="w-5 h-[5.5] text-purple-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                strokeLinecap="round"
                                strokeWidth="2"
                                stroke="currentColor"
                              >
                                <path d="m20 20H4"></path>
                                <path
                                  clipRule="evenodd"
                                  fillRule="evenodd"
                                  d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                                ></path>
                              </g>
                            </svg>
                            <p className="ml-1"> Edit Shape</p>
                          </button>
                        ) : null}
                        {showMeasureEditButtonsArray[index] && (
                          <div className="flex justify-center items-center ">
                            <button
                              className="mr-[2px] text-xs  p-1 px-2  rounded-md btn btn-success btn-sm border text-white  border-green-800 bg-green-400  hover:bg-white hover:bg-opacity-50 hover:text-black"
                              onClick={() => {
                                handleMeasureSaveEdit(index);
                              }}
                            >
                              Save
                            </button>
                            <button
                              className="text-xs  p-1 px-2  rounded-md btn btn-danger btn-sm border   border-red-500 bg-white bg-opacity-50 hover:bg-opacity-100 hover:bg-red-400 hover:text-white"
                              onClick={() => {
                                handleMeasureCancelEdit(index);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div> */}
                      <div className="flex gap-2 items-center justify-center mt-2 ">
                        {!showMeasureEditButtonsArray[index] ? (
                          <button
                            onClick={() => {
                              deleteSingleMeasures(measurement.id);
                            }}
                            className="flex items-center justify-center text-xs p-1 px-2 rounded-md w-auto h-[28px] border my-auto cursor-pointer mr-[4px] text-red-700 hover:text-white border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none  font-medium focus:ring-0 group "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 -960 960 960"
                              width="24"
                              className="w-4 h-4 mr-1 stroke-current  fill-red-700 group-hover:fill-white"
                              // fill="red"
                              // stroke="red"
                            >
                              <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                            </svg>
                            Delete
                          </button>
                        ) : null}
                        {!showMeasureEditButtonsArray[index] ? (
                          <button
                            className={`flex items-center justify-center text-xs p-1 px-2 rounded-md w-auto border my-auto cursor-pointer ${
                              showMeasureEditButtonsArray[index]
                                ? "bg-purple-400 text-white"
                                : "border-purple-400 text-purple-400 hover:bg-purple-400 hover:text-white"
                            } ${
                              !measurement.visible
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                            } `}
                            onClick={() => {
                              handleMeasureEditClick(measurement.id, index);
                            }}
                            disabled={!measurement.visible}
                          >
                            <svg
                              className="w-5 h-[5.5] text-purple-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                strokeLinecap="round"
                                strokeWidth="2"
                                stroke="currentColor"
                              >
                                <path d="m20 20H4"></path>
                                <path
                                  clipRule="evenodd"
                                  fillRule="evenodd"
                                  d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                                ></path>
                              </g>
                            </svg>
                            <p className="ml-1"> Edit Shape</p>
                          </button>
                        ) : null}
                        {showMeasureEditButtonsArray[index] && (
                          <div className="flex items-center">
                            <button
                              className="mr-[2px] text-xs p-1 px-2 rounded-md btn btn-success btn-sm border text-white border-green-800 bg-green-400 hover:bg-white hover:bg-opacity-50 hover:text-black"
                              onClick={() => {
                                handleMeasureSaveEdit(index);
                              }}
                            >
                              Save
                            </button>
                            <button
                              className="text-xs p-1 px-2 rounded-md btn btn-danger btn-sm border border-red-500 bg-white bg-opacity-50 hover:bg-opacity-100 hover:bg-red-400 hover:text-white"
                              onClick={() => {
                                handleMeasureCancelEdit(index);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))
              )}
            </div>
          </TabPanel>

          <TabPanel className="w-full" key="assetsTab" value={2}>
            <div className="flex-1 overflow-y-auto overflow-x-hidden">
              {KmlsetArray?.length <= 0 ? (
                <p className="text-center text-sm p-2">No kml created</p>
              ) : (
                KmlsetArray?.map((kml, index) => (
                  <Accordion key={index + kml.id} className="accordianWrapper">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      onClick={(event) => event.stopPropagation()}
                      className=" flex  !items-center !justify-center min-h-max m-0 "
                    >
                      {showCheckboxes ? (
                        <label key={kml.id}>
                          <input
                            type="checkbox"
                            checked={subCheckboxesChecked[`checkbox${kml.id}`]}
                            onChange={() =>
                              handleSubCheckboxChange(`checkbox${kml.id}`)
                            }
                          />
                        </label>
                      ) : (
                        <button
                          className={`visibility-toggle-btn`}
                          onClick={(event) => {
                            event.stopPropagation();
                            dispatch(
                              gisLeafletActions.toggleVisibility(kml.id)
                            );
                            // toggleKmlVisibilityById(kml.id);
                          }}
                          // disabled={showMeasureEditButtonsArray[index]}
                        >
                          {kml?.visible ? (
                            <img
                              src={visibilityIco}
                              alt="Visible"
                              className="h-4"
                            />
                          ) : (
                            <img
                              src={visibilityOffIco}
                              alt="Hidden"
                              className="h-4"
                            />
                          )}
                        </button>
                      )}

                      <div className="rounded-md ml-2 flex justify-between items-center gap-4">
                        <p
                          className="btn-dangerrounded text-black"
                          onClick={(event) => {
                            event.stopPropagation();
                            const kmlLayer = getLayerById(kml.id);
                            if (kmlLayer) {
                              const bounds = kmlLayer.getBounds();
                              map?.flyToBounds(bounds, {
                                duration: 2, // Animation duration in seconds
                                easeLinearity: 0.5, // Animation easing factor (0 = linear, 1 = same speed throughout)
                                maxZoom: 19,
                              });
                            } else {
                              //console.error("Layer coordinates not available");
                            }
                          }}
                        >
                          {kml.KmlName}
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      disabled={!kml.visible}
                      className="!mb-0 !mt-0 grow w-full"
                    >
                      <div className="flex flex-col text-sm">
                        <div className="flex items-start">
                          <p className="w-[70px]">Opacity:</p>
                          <Slider
                            min={0}
                            max={1}
                            step={0.1}
                            value={fillOpacityVal}
                            valueLabelDisplay="auto"
                            onChange={(e) =>
                              handleOpacityChange(kml.id, e.target.value)
                            }
                            disabled={!kml.visible} // Disable slider if marker is not visible
                            className="ml-1 w-3/5 "
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))
              )}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default SidebarLayersLeaflet;
