import React from "react";
import Header from "../Layouts/Header";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

const AdminLayout = () => {
  return (
    <>
      <div className="flex h-full w-full flex-col overflow-y-hidden overflow-x-hidden">
        <Header drawerOpen={() => {}} />
        <div className="flex h-[calc(100%-60px)] w-full">
          <Sidebar />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
