import React from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Permissions from "./Permissions";
import Roles from "./Roles";
import RolePermission from "./RolePermission";
import { Toaster } from "react-hot-toast";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="p-3">
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Admin = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="h-full w-[80%]">
        <div className="h-full p-3">
          <Tabs value={value} onChange={handleChange} className="w-full">
            <Tab label="Roles" {...a11yProps(0)} className="w-[50%]" />
            <Tab label="Permissions" {...a11yProps(1)} className="w-[50%]" />
            <Tab
              label="Role-Permission"
              {...a11yProps(2)}
              className="w-[50%]"
            />
          </Tabs>
          <CustomTabPanel value={value} index={0} className="h-full">
            <div className="w-full h-full">
              <Roles />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className="w-full">
              <Permissions />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div className="w-full">
              <RolePermission />
            </div>
          </CustomTabPanel>
        </div>
      </div>
      <Toaster position="top-center" />
    </>
  );
};

export default Admin;
