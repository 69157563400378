import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import React from "react";
import toast from "react-hot-toast";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const RolePermission = () => {
  const [allPerm, setAllPerm] = React.useState([]);
  const [reloader, setReloader] = React.useState(0);
  const [allRoles, setAllRoles] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [asignedPermList, setAsignedPermList] = React.useState([]);

  React.useEffect(() => {
    const getAllRoles = async () => {
      const response = await axios.get(
        "https://delivery.inspect.indrones.com/api/v1/role_permission/get_all_roles",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("mohit", response);
      setAllRoles(response.data);
    };
    getAllRoles();
  }, []);

  React.useEffect(() => {
    const getPermissionByRole = async () => {
      const response = await axios.get(
        `https://delivery.inspect.indrones.com/api/v1/role_permission/asigned_permissions/${selectedRole}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("this is rp", response.data.all_permissions);

      setAsignedPermList(response.data.perms_list);
      setAllPerm(response.data.all_permissions);
    };
    getPermissionByRole();
  }, [selectedRole, reloader]);

  const checkBoxChange = (name) => {
    setAsignedPermList((prevList) => {
      if (prevList.includes(name)) {
        return prevList.filter((item) => item !== name); // Remove the item if it exists
      } else {
        return [...prevList, name]; // Add the item if it doesn't exist
      }
    });
  };

  const updatePermissions = async () => {
    const updatedIds = asignedPermList
      .map((permission) => {
        const object = allPerm.find((obj) => obj.name === permission);
        return object ? object.id : null;
      })
      .filter((id) => id !== null);

    // console.log("update", updatedIds, selectedRole);

    try {
      const response = await axios.post(
        `https://delivery.inspect.indrones.com/api/v1/role_permission/asign/permission/${selectedRole}`,
        { permission_id: updatedIds }
      );

      toast.success("Role Updated Sucessfully");
      setReloader(reloader + 1);
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again!!");
    }
  };

  return (
    <div className="flex flex-col w-[90%] mx-auto">
      <div className="flex items-center mt-5 pb-5 border-b">
        <label className="w-[200px]">Select Role to Update:</label>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="w-[300px]"
            value={selectedRole}
            label="Role"
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            {allRoles.map((role, index) => (
              <MenuItem value={role.id}>{role.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {selectedRole !== "" ? (
        <>
          <div className="mt-5">
            <div>Annotations</div>
            <div className="flex items-center gap-5">
              <div>Marker</div>
              <div className="flex">
                <div className="flex items-center">
                  <label>View</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes("annotation_marker_view")}
                    onChange={() => checkBoxChange("annotation_marker_view")}
                  />
                </div>
                <div className="flex items-center">
                  <label>Add</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes("annotation_marker_add")}
                    onChange={() => checkBoxChange("annotation_marker_add")}
                  />
                </div>
                <div className="flex items-center">
                  <label>Edit</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes("annotation_marker_edit")}
                    onChange={() => checkBoxChange("annotation_marker_edit")}
                  />
                </div>
                <div className="flex items-center">
                  <label>Delete</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes(
                      "annotation_marker_delete"
                    )}
                    onChange={() => checkBoxChange("annotation_marker_delete")}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <div>Polyline</div>
              <div className="flex">
                <div className="flex items-center">
                  <label>View</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes(
                      "annotation_polyline_view"
                    )}
                    onChange={() => checkBoxChange("annotation_polyline_view")}
                  />
                </div>
                <div className="flex items-center">
                  <label>Add</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes(
                      "annotation_polyline_add"
                    )}
                    onChange={() => checkBoxChange("annotation_polyline_add")}
                  />
                </div>
                <div className="flex items-center">
                  <label>Edit</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes(
                      "annotation_polyline_edit"
                    )}
                    onChange={() => checkBoxChange("annotation_polyline_edit")}
                  />
                </div>
                <div className="flex items-center">
                  <label>Delete</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes(
                      "annotation_polyline_delete"
                    )}
                    onChange={() =>
                      checkBoxChange("annotation_polyline_delete")
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <div>Polygon</div>
              <div className="flex">
                <div className="flex items-center">
                  <label>View</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes(
                      "annotation_polygon_view"
                    )}
                    onChange={() => checkBoxChange("annotation_polygon_view")}
                  />
                </div>
                <div className="flex items-center">
                  <label>Add</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes("annotation_polygon_add")}
                    onChange={() => checkBoxChange("annotation_polygon_add")}
                  />
                </div>
                <div className="flex items-center">
                  <label>Edit</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes(
                      "annotation_polygon_edit"
                    )}
                    onChange={() => checkBoxChange("annotation_polygon_edit")}
                  />
                </div>
                <div className="flex items-center">
                  <label>Delete</label>
                  <Checkbox
                    {...label}
                    checked={asignedPermList.includes(
                      "annotation_polygon_delete"
                    )}
                    onChange={() => checkBoxChange("annotation_polygon_delete")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 flex items-center gap-5">
            <div>Measurement</div>
            <div className="flex">
              <div className="flex items-center">
                <label>Line</label>
                <Checkbox
                  {...label}
                  checked={asignedPermList.includes("measurement_line_view")}
                  onChange={() => checkBoxChange("measurement_line_view")}
                />
              </div>
              <div className="flex items-center">
                <label>Polyline</label>
                <Checkbox
                  {...label}
                  checked={asignedPermList.includes(
                    "measurement_polyline_view"
                  )}
                  onChange={() => checkBoxChange("measurement_polyline_view")}
                />
              </div>
              <div className="flex items-center">
                <label>Polygon</label>
                <Checkbox
                  {...label}
                  checked={asignedPermList.includes("measurement_polygon_view")}
                  onChange={() => checkBoxChange("measurement_polygon_view")}
                />
              </div>
            </div>
          </div>

          <div className="flex mt-5 items-center gap-5">
            <div>Comments</div>
            <div className="flex">
              <div className="flex items-center">
                <label>View</label>
                <Checkbox
                  {...label}
                  checked={asignedPermList.includes("comments_view")}
                  onChange={() => checkBoxChange("comments_view")}
                />
              </div>
              <div className="flex items-center">
                <label>Add</label>
                <Checkbox
                  {...label}
                  checked={asignedPermList.includes("comments_add")}
                  onChange={() => checkBoxChange("comments_add")}
                />
              </div>
              <div className="flex items-center">
                <label>Edit</label>
                <Checkbox
                  {...label}
                  checked={asignedPermList.includes("comments_edit")}
                  onChange={() => checkBoxChange("comments_edit")}
                />
              </div>
              <div className="flex items-center">
                <label>Delete</label>
                <Checkbox
                  {...label}
                  checked={asignedPermList.includes("comments_delete")}
                  onChange={() => checkBoxChange("comments_delete")}
                />
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div>Others</div>
            <div className="flex  items-center justify-start gap-5">
              <div className="flex items-center mr-auto">
                <label>View Shape Files</label>
                <Checkbox
                  {...label}
                  checked={asignedPermList.includes("view_shapes")}
                  onChange={() => checkBoxChange("view_shapes")}
                />
              </div>
              <div className="flex items-center mr-auto">
                <label>Generate Live Stream</label>
                <Checkbox
                  {...label}
                  checked={asignedPermList.includes("generate_live_stream")}
                  onChange={() => checkBoxChange("generate_live_stream")}
                />
              </div>
              <div className="flex items-center mr-auto">
                <label>Export Reports</label>
                <Checkbox
                  {...label}
                  checked={asignedPermList.includes("export_reports")}
                  onChange={() => checkBoxChange("export_reports")}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center mr-auto">
            <label>Compare Timelines</label>
            <Checkbox
              {...label}
              checked={asignedPermList.includes("compare_timelines")}
              onChange={() => checkBoxChange("compare_timelines")}
            />
          </div>

          <div className="flex items-center w-full justify-center gap-5 mt-10">
            <div
              onClick={updatePermissions}
              className="cursor-pointer w-max  !rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
            >
              Save
            </div>
            <div
              onClick={() => setReloader(reloader + 1)}
              className="cursor-pointer w-max !rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
            >
              Reset
            </div>
          </div>
        </>
      ) : (
        <div className="mt-5">Please Select a Role</div>
      )}
    </div>
  );
};

export default RolePermission;
