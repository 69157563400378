import L from "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import {
  ZoomInIcon,
  ZoomOutIcon,
  defaultMarker,
  homeIcon,
  lineIco,
  markers,
  measuringTapeIco,
  pinIcon,
  polygonIco,
  polylineIco,
  scaleIco,
} from "../../../Assets/icons/gisIcons/icons";

import { Tooltip } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { gisActions } from "../../../redux/slices/GIS/gis-slice";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { formatDate } from "../../../Features/GIS/Utils/otherUtils";
import { AnnotationManager } from "../../../Services/ThirdPartyApi/AWS/DynamoDBL";
import { S3Apis } from "../../../Services/ThirdPartyApi/AWS/S3";
import { Measure } from "../../../Utils/Leaflet/LeafletUtils";
import { gisHomeActions } from "../../../redux/slices/GIS/gis-home-slice";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import { gisToolActions } from "../../../redux/slices/GIS/gis-tools-slice";
import MetaInputLeaflet from "./MetaInputLeaflet";

const GISToolbarLeaflet = ({
  handleHomeButtonClick,
  setShowCompare,
  setToolCategory,
  toolCategory,
  handleZoom,
  imageryLabel,
  cursorLatLng,
  mapRef,
}) => {
  const map = mapRef.current;
  const dispatch = useDispatch();
  const { asset } = useSelector((state) => state.gisLeaflet);
  const { mode } = useSelector((state) => state.gisHome);
  const { userPermissions } = useSelector((state) => state.gis);
  const { grp } = useParams();
  const [mapComparer, setMapCompapere] = useState(false);
  const [drawMarker, setDrawMarker] = useState(null);
  const [drawPolygon, setDrawPolygon] = useState(null);
  const [drawPolyline, setDrawPolyline] = useState(null);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const measure = new Measure();
  const {
    showComparer,
    annotationTool,
    measureToolToggle,
    polygonAnnotation,
    polylineAnnotation,
    annotationToggle,
    unitsIsOpen,
    selectedTool,
    lineMeasureTool,
    polylineMeasureTool,
    polygonMeasureTool,
  } = useSelector((state) => state.gisTools);

  const { vectorMeasurentLayers, measurementArray, observationsBucket } =
    useSelector((state) => state.gisLeaflet);

  // if (
  //   (showComparer ||
  //     annotationTool ||
  //     measureToolToggle ||
  //     polygonAnnotation ||
  //     polylineAnnotation) &&
  //   shapesIds.length > 0
  // ) {
  //   dispatch(gisHomeActions.setShapesIds([]));
  // }

  map?.on("contextmenu", function (e) {
    e.originalEvent.preventDefault(); // Prevent the default context menu from appearing
  });

  // formatted styles

  // backgroundColor: "rgba(20,26,34,.725)",
  // "&:hover": {
  // backgroundColor: "rgba(20,26,34)",
  // },
  // boxShadow: "0 0 1.5px rgba(255,255,255,.7)",
  // backdropFilter: "blur(20px)",

  const hoverColor = "rgba(20,26,34)";
  const [selectedMeasurmentUnit, setSelectedMeasurmentUnit] = useState("m");
  const [selectedAreaUnit, setSelectedAreaUnit] = useState("sq m");
  const [isModalOpenCustom, setIsModalOpenCustom] = useState(false);
  const [markerLayerCurrent, setMarkerLayer] = useState(null);
  const [markerPosition, setMarkerPosition] = useState([]);
  const handleUnitChange = (unit) => {
    setSelectedMeasurmentUnit(unit);
  };

  const handleAreaUnitChange = (unit) => {
    setSelectedAreaUnit(unit);
  };

  const measurementPermissions = [
    "measurement_line_view",
    "measurement_polyline_view",
    "measurement_polygon_view",
  ];

  const hasMeasurementPermission = userPermissions.some((permission) =>
    measurementPermissions.includes(permission)
  );

  const annotationPolylinePermissions = [
    "annotation_line_add",
    "annotation_polyline_add",
    "annotation_polygon_add",
  ];

  const hasAnnotationPermission = userPermissions.some((permission) =>
    annotationPolylinePermissions.includes(permission)
  );

  const siteName = Cookies.get("start_location");

  //code for leaflet-draw
  const customMarkerIcon = new L.Icon({
    className: "custom-marker-icon",
    iconUrl: defaultMarker,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  // const drawMarker = new L.Draw.Marker(map, {
  //   icon: customMarkerIcon,
  // });
  // const drawPolygon = new L.Draw.Polygon(map);
  // const drawPolyline = new L.Draw.Polyline(map);

  useEffect(() => {
    if (!mapRef.current) return; // Ensure map is initialized

    // Initialize drawing tools
    const marker = new L.Draw.Marker(mapRef.current, {
      icon: customMarkerIcon,
    });
    const polygon = new L.Draw.Polygon(mapRef.current, {
      shapeOptions: {
        stroke: true,
        color: "#000",
        weight: 4,
        opacity: 0.5,
        lineCap: "round",
        lineJoin: "round",
        fill: true,
        clickable: true,
        fillColor: "#FFF",
        fillOpacity: 0.3,
        dashArray: "10,10",
      },
    });
    const polyline = new L.Draw.Polyline(mapRef.current, {
      shapeOptions: {
        stroke: true,
        color: "#000",
        weight: 4,
        opacity: 0.9,
        lineCap: "round",
        lineJoin: "round",
        fill: false,
        clickable: true,
        dashArray: "10,10",
      },
    });

    // Set the drawing tools in state
    setDrawMarker(marker);
    setDrawPolygon(polygon);
    setDrawPolyline(polyline);

    return () => {
      // Clean up resources when component unmounts
      // map.off(L.Draw.Event.CREATED, handleDrawCreated);
    };
  }, [mapRef]);

  const disableAllTools = () => {
    if (drawPolygon._enabled) {
      drawPolygon.disable();
    }
    if (drawPolyline._enabled) {
      drawPolyline.disable();
    }
    if (drawMarker._enabled) {
      drawMarker.disable();
    }
  };

  const handlePointMarkerToolClick = () => {
    disableAllTools();
    drawMarker.enable();
  };

  const handlePolygonMarkerToolClick = () => {
    disableAllTools();
    drawPolygon.enable();
  };

  const handlePolyLineMarkerToolClick = () => {
    disableAllTools();

    L.Draw.Polyline.prototype._onMouseUp = function (e) {
      var originalEvent = e.originalEvent;
      if (originalEvent.button === 2) {
        // Check if the event was triggered by a right-click
        this.deleteLastVertex(); // Call the function to delete the last vertex
        // Prevent the default right-click behavior
        L.DomEvent.preventDefault(originalEvent);
      } else {
        var clientX = originalEvent.clientX;
        var clientY = originalEvent.clientY;
        this._endPoint.call(this, clientX, clientY, e);
      }
      this._clickHandled = null;
    };
    drawPolyline.enable();
  };

  const handleLineCreated = () => {
    // Disable all tools first
    disableAllTools();
    overridePolylineMouseUp();
    drawPolyline.enable();
  };

  const handlePolylineMeasureClick = () => {
    disableAllTools();
    overridePolylineMouseUp(true);
    drawPolyline.enable();
  };

  const handlePolygonMeasureClick = () => {
    disableAllTools();
    drawPolygon.enable();
  };

  // Function to override the _onMouseUp method of L.Draw.Polyline
  // Override the _onMouseUp method of L.Draw.Polyline
  const overridePolylineMouseUp = (restoreOriginalBehavior = false) => {
    if (restoreOriginalBehavior) {
      // Restore original _onMouseUp method
      L.Draw.Polyline.prototype._onMouseUp = function (e) {
        var originalEvent = e.originalEvent;
        var clientX = originalEvent.clientX;
        var clientY = originalEvent.clientY;
        this._endPoint.call(this, clientX, clientY, e);
        this._clickHandled = null;
      };
    } else {
      // Override _onMouseUp method to stop at two points
      L.Draw.Polyline.prototype._onMouseUp = function (e) {
        var originalEvent = e.originalEvent;
        var clientX = originalEvent.clientX;
        var clientY = originalEvent.clientY;
        this._endPoint.call(this, clientX, clientY, e);
        this._clickHandled = null;

        // Check if the number of points drawn is equal to 2, then finish the shape
        if (this._markers?.length === 2) {
          this._finishShape();
        }
      };
    }
  };

  document.addEventListener("keydown", function (event) {
    // Check if the pressed key is "esc" and any Leaflet Draw mode is enabled
    if (
      event.key === "Escape" &&
      (drawMarker?._enabled || drawPolygon?._enabled || drawPolyline?._enabled)
    ) {
      handleEscKeyPress();
    }
  });

  // Your function to be called when any Leaflet Draw mode is active and "esc" key is pressed
  function handleEscKeyPress() {
    dispatch(gisToolActions.setselectedTool(null));
    dispatch(gisToolActions.turnOffTools());
    disableAllTools();
  }

  useEffect(() => {
    const handleDrawCreated = (e) => {
      // console.log("handle draw called");
      const layer = e.layer;
      const type = e.layerType;

      layer.type = type;
      // setMarkerCategory(type);

      if (
        selectedTool === "PolylineMeasureTool" ||
        selectedTool === "lineMeasure"
      ) {
        //from utils to calculate geometry properties and add label
        // measure.handlePolyline(layer, map,null);
      }
      if (selectedTool === "PolygonMeasureTool") {
        // measure.handlePolygon(layer, map,null);
      }
      map.addLayer(layer);

      if (type === "polygon") {
        setMarkerPosition(layer.getLatLngs());
        measure.handlePolygon(layer, map, null);
        // setMarkerCategory(type);
      } else if (type === "polyline") {
        setMarkerPosition(layer.getLatLngs());
        // console.log("latlng array", layer.getLatLngs());
        measure.handlePolyline(layer, map, null);
        // setMarkerCategory(type);
      } else {
        setMarkerPosition(layer.getLatLng());
        // setMarkerCategory(type);
      }

      if (
        selectedTool !== "lineMeasure" &&
        selectedTool !== "polylineMeasure" &&
        selectedTool !== "polygonMeasure"
      ) {
        setIsModalOpenCustom(true);
        setMarkerLayer(layer);
        dispatch(gisLeafletActions.addItemToVisibleLayers(true));
        dispatch(gisActions.refCounterUpdate());
      } else {
        const newMeasure = {
          id: uuidv4(),
          assetId: asset.Name,
          measurePosition: layer.getLatLngs(),
          length: null,
          area: null,
          perimeter: null,
          createdOn: formatDate(newTimestamp),
          groupName: grp,
          filter_type: layer.type,
          visible: true,
        };
        // dispatch(gisActions.setmeasurementArray(layer));

        const existingData =
          JSON.parse(localStorage.getItem("measurementData")) || [];
        const newData = [...existingData, newMeasure];
        localStorage.setItem("measurementData", JSON.stringify(newData));
        map.removeLayer(layer);
        dispatch(gisLeafletActions.refCounterMeasureUpdate());
        dispatch(gisToolActions.turnOffTools());
        dispatch(gisToolActions.setselectedTool(null));
      }
    };

    map?.on(L.Draw.Event.CREATED, handleDrawCreated);
    return () => {
      map.off(L.Draw.Event.CREATED, handleDrawCreated);
    };
  }, [measurementArray, selectedTool, vectorMeasurentLayers]);

  //saving data
  const newTimestamp = Date.now();

  const handleMarkerSave = async (
    description,
    keyValuePairs,
    observationType,
    category,
    severity,
    markerName,
    assetId,
    file,
    date,
    AnnotationType,
    taskStatus,
    observerName,
    verifierName,
    reviewerName,
    length,
    area,
    perimeter
  ) => {
    // console.table(
    //   description,
    //   keyValuePairs,
    //   observationType,
    //   category,
    //   severity,
    //   markerName,
    //   assetId,
    //   file,
    //   date,
    //   AnnotationType,
    //   taskStatus,
    //   observerName,
    //   verifierName,
    //   reviewerName,
    //   length,
    //   area,
    //   perimeter
    // );
    if (true) {
      var imagedata;
      if (file) {
        const imageData = await S3Apis.uploadData(file, "images");
        imagedata = [{ path: imageData.s3Url.key, date: imageData.Imagedate }];
      } else {
        imagedata = [];
      }

      if (AnnotationType === "Custom") {
        const newMarker = {
          id: uuidv4(),
          timestamp: newTimestamp,
          description: description,
          keyValuePairs: keyValuePairs,
          category: "Other",
          severity: severity,
          markerName: markerName,
          assetId: assetId,
          markerDate: date,
          markerPosition,
          createdOn: formatDate(newTimestamp),
          images: imagedata,
          groupName: grp,
          filter_type: AnnotationType,
          visible: true,
        };
        markerLayerCurrent._leaflet_id = newMarker.id;
        // AnnotationManager.addAnnotations(newMarker);
        // if (severity === "High") {
        //   const dataForMail = {
        //     recipients: [getUserEmail()],
        //     dataJson: {
        //       name: markerName,
        //       description,
        //       typeOfMarker,
        //       // date: findAssetNameById(assetId, grpAssets),
        //     },
        //   };

        //   const responses = await axios.post(
        //     "http://192.168.0.104:8001/api/v1/email/notify",
        //     dataForMail
        //   );

        // }
        // dispatch(gisActions.setMarkerArray(newMarker));
      } else if (AnnotationType === "marker") {
        const newMarker = {
          id: uuidv4(),
          timestamp: newTimestamp,
          markerName: markerName,
          assetId: assetId,
          observationType: observationType,
          category: category,
          severity: severity,
          description: description,
          filter_type: AnnotationType,
          markerPosition,
          keyValuePairs: keyValuePairs,
          markerDate: date,
          createdOn: formatDate(newTimestamp),
          images: imagedata,
          groupName: grp,
          visible: true,
          taskStatus: taskStatus,
          addedBy: observerName,
          verifiedBy: verifierName,
          reviewedBy: reviewerName,
        };

        var type = markerLayerCurrent.type;
        map.removeLayer(markerLayerCurrent);
        AnnotationManager.addAnnotations(newMarker);

        // if (severity === "High") {
        //   const dataForMail = {
        //     recipients: [getUserEmail()],
        //     dataJson: {
        //       name: markerName,
        //       description,
        //       typeOfMarker,
        //       // date: findAssetNameById(assetId, grpAssets),
        //     },
        //   };

        //   const responses = await axios.post(
        //     "http://192.168.0.104:8001/api/v1/email/notify",
        //     dataForMail
        //   );
        // }
        //adding object in vectorlayer
        // dispatch(gisLeafletActions.setVectorMeasurentLayers(newMarker));

        // const existingData =
        //   JSON.parse(localStorage.getItem("markerData")) || [];
        // const newData = [...existingData, newMarker];
        // localStorage.setItem("markerData", JSON.stringify(newData));
        dispatch(gisActions.refCounterUpdate());
      } else if (AnnotationType === "polyline") {
        const newMarker = {
          id: uuidv4(),
          timestamp: newTimestamp,
          markerName: markerName,
          assetId: assetId,
          observationType: observationType,
          category: category,
          severity: severity,
          description: description,
          filter_type: AnnotationType,
          markerPosition,
          keyValuePairs: keyValuePairs,
          markerDate: date,
          createdOn: formatDate(newTimestamp),
          images: imagedata,
          groupName: grp,
          visible: true,
          taskStatus: taskStatus,
          addedBy: observerName,
          verifiedBy: verifierName,
          reviewedBy: reviewerName,
        };

        var type = markerLayerCurrent.type;
        markerLayerCurrent.remove();
        AnnotationManager.addAnnotations(newMarker);
        // if (severity === "High") {
        //   const dataForMail = {
        //     recipients: [getUserEmail()],
        //     dataJson: {
        //       name: markerName,
        //       description,
        //       typeOfMarker,
        //       // date: findAssetNameById(assetId, grpAssets),
        //     },
        //   };

        //   const responses = await axios.post(
        //     "http://192.168.0.104:8001/api/v1/email/notify",
        //     dataForMail
        //   );
        // }
        //adding object in vectorlayer
        // dispatch(gisLeafletActions.setVectorMeasurentLayers(newMarker));
        dispatch(gisActions.refCounterUpdate());
      } else if (AnnotationType === "polygon") {
        const newMarker = {
          id: uuidv4(),
          timestamp: newTimestamp,
          markerName: markerName,
          assetId: assetId,
          observationType: observationType,
          category: category,
          severity: severity,
          description: description,
          filter_type: AnnotationType,
          markerPosition,
          keyValuePairs: keyValuePairs,
          markerDate: date,
          createdOn: formatDate(newTimestamp),
          images: imagedata,
          groupName: grp,
          visible: true,
          taskStatus: taskStatus,
          addedBy: observerName,
          verifiedBy: verifierName,
          reviewedBy: reviewerName,
        };
        var type = markerLayerCurrent.type;
        map.removeLayer(markerLayerCurrent);
        AnnotationManager.addAnnotations(newMarker);

        // if (severity === "High") {
        //   const dataForMail = {
        //     recipients: [getUserEmail()],
        //     dataJson: {
        //       name: markerName,
        //       description,
        //       typeOfMarker,
        //       // date: findAssetNameById(assetId, grpAssets),
        //     },
        //   };

        //   const responses = await axios.post(
        //     "http://192.168.0.104:8001/api/v1/email/notify",
        //     dataForMail
        //   );
        // }
        //adding object in vectorlayer
        // dispatch(gisLeafletActions.setVectorMeasurentLayers(newMarker));
        dispatch(gisActions.refCounterUpdate());
      }
    }
  };
  return (
    <>
      <div className="toolbarContainer">
        {!showComparer && (
          <div
            id="toolbar"
            className="absolute flex flex-col justify-end items-end z-[1000]  right-2 top-12 mt-1 "
          >
            {asset !== null && (
              <Tooltip title="Home" arrow placement="left">
                <button
                  className={`!ml-0 h-10 w-10 bg-[rgba(20,26,34,.725)]  rounded-[50%] active:bg-[#48b] hover:bg-[rgba(20,26,34)] flex items-center justify-center my-2`}
                  onClick={handleHomeButtonClick}
                  style={{
                    boxShadow: "0 0 1.5px rgba(255,255,255,.7)",
                    backdropFilter: "blur(20px)",
                  }}
                >
                  <img className="w-6" src={homeIcon} alt="" />
                </button>
              </Tooltip>
            )}
            {asset !== null &&
              grp !== "ESSEL" &&
              hasAnnotationPermission &&
              mode !== "3D" && (
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  icon={
                    <img
                      className="w-6 "
                      src={
                        selectedTool === "marker"
                          ? pinIcon
                          : selectedTool === "polyline"
                          ? polylineIco
                          : selectedTool === "polygon"
                          ? polygonIco
                          : selectedTool === "line"
                          ? lineIco
                          : markers
                      }
                      alt={`${selectedTool} icon`}
                    />
                  }
                  direction="left"
                  sx={{
                    // '& .MuiSpeedDial-root': {
                    //   pointerEvents: ({ speedDialOpen }) => (speedDialOpen ? 'auto' : 'none')
                    // },
                    marginTop: "-6px",

                    "& .MuiSpeedDial-fab": {
                      backgroundColor:
                        selectedTool === "marker" ||
                        selectedTool === "polyline" ||
                        selectedTool === "polygon"
                          ? "#48b"
                          : "rgba(20,26,34,.725)",
                      "&:hover": {
                        backgroundColor: hoverColor,
                      },
                      height: 40,
                      width: 40,
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => {
                    if (
                      selectedTool === "marker" ||
                      selectedTool === "polyline" ||
                      selectedTool === "polygon"
                    ) {
                      dispatch(gisToolActions.setselectedTool(null));
                      dispatch(gisToolActions.turnOffTools());
                      disableAllTools();
                    }
                  }}
                >
                  {userPermissions.includes("annotation_marker_add") && (
                    <SpeedDialAction
                      key="Point Observation"
                      icon={
                        <img
                          src={pinIcon}
                          className="w-6"
                          alt="Point Observation"
                        />
                      }
                      arrow
                      tooltipTitle="Point Observation"
                      sx={{
                        backgroundColor: "rgba(20,26,34,.725)",
                        "&:hover": {
                          backgroundColor: "rgba(20,26,34)",
                        },
                        boxShadow: "0 0 1.5px rgba(255,255,255,.7)",
                        backdropFilter: "blur(20px)",
                      }}
                      onClick={() => {
                        dispatch(gisToolActions.setselectedTool("marker"));
                        dispatch(gisToolActions.toggleTools("annotationTool"));
                        handlePointMarkerToolClick();
                      }}
                    />
                  )}
                  {userPermissions.includes("annotation_polyline_add") && (
                    <SpeedDialAction
                      key="Polyline Observation"
                      arrow
                      icon={
                        <img
                          src={polylineIco}
                          className="w-6"
                          alt="Polyline Observation"
                        />
                      }
                      tooltipTitle="Polyline Observation"
                      onClick={() => {
                        dispatch(gisToolActions.setselectedTool("polyline"));

                        dispatch(
                          gisToolActions.toggleTools("polylineAnnotationTool")
                        );
                        handlePolyLineMarkerToolClick();
                      }}
                      sx={{
                        backgroundColor: "rgba(20,26,34,.725)",
                        "&:hover": {
                          backgroundColor: hoverColor,
                        },
                        boxShadow: "none",
                      }}
                    />
                  )}
                  {userPermissions.includes("annotation_polygon_add") && (
                    <SpeedDialAction
                      key="Polygon Observation"
                      arrow
                      icon={
                        <img
                          src={polygonIco}
                          className="w-6"
                          alt="Polygon Observation"
                        />
                      }
                      tooltipTitle="Polygon Observation"
                      onClick={() => {
                        dispatch(gisToolActions.setselectedTool("polygon"));
                        dispatch(
                          gisToolActions.toggleTools("polygonAnnotationTool")
                        );
                        handlePolygonMarkerToolClick();
                      }}
                      sx={{
                        backgroundColor: "rgba(20,26,34,.725)",
                        "&:hover": {
                          backgroundColor: hoverColor,
                        },
                        boxShadow: "none",
                      }}
                    />
                  )}
                </SpeedDial>
              )}

            {/* compair  */}
            {/* {grp !== "ESSEL" &&
              userPermissions.includes("compare_timelines") &&
              mode !== "3D" && (
                <Tooltip title="Click to Compare" arrow placement="left">
                  <button
                    style={{
                      boxShadow: "0 0 1.5px rgba(255,255,255,.7)",
                      backdropFilter: "blur(20px)",
                    }}
                    sx={{
                      backgroundColor: "rgba(20,26,34,.725)",
                      "&:hover": {
                        backgroundColor: "rgba(20,26,34)",
                      },
                      boxShadow: "0 0 1.5px rgba(255,255,255,.7)",
                      backdropFilter: "blur(20px)",
                    }}
                    className={`h-10 w-10 rounded-[50%] flex items-center justify-center ${showComparer
                        ? "bg-[#48b] "
                        : "bg-[rgba(20,26,34,.725)]   "
                      }   hover:bg-[rgba(20,26,34)]`}
                    onClick={() => {
                      if (mode === "3d") {
                        // setShowCompare(!showComparer);
                        // dispatch(gisToolActions.toggleTools("showComparer"));
                        // Toggle the showButtons
                        // showAssetsSelect();
                        // dispatch(gisHomeActions.toggleShowComparer(showComparer));
                      } else {
                        dispatch(gisToolActions.setselectedTool(null));
                        dispatch(gisToolActions.turnOffTools());
                        disableAllTools();
                        dispatch(gisToolActions.toggleTools("showComparer"));
                        // setMapCompapere(!mapComparer);
                      }
                    }}
                  >
                    <img className="w-6 text-red-200" src={switchIcon} alt="" />
                  </button>
                </Tooltip>
              )} */}

            {/* measure tools  */}
            {asset !== "" && hasMeasurementPermission && mode !== "3D" && (
              <div className="relative flex">
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  icon={
                    <img
                      className="w-6"
                      src={
                        selectedTool === "lineMeasure"
                          ? lineIco
                          : selectedTool === "polylineMeasure"
                          ? polylineIco
                          : selectedTool === "polygonMeasure"
                          ? polygonIco
                          : scaleIco
                      }
                      alt=""
                    />
                  }
                  direction="left"
                  sx={{
                    marginTop: "-6px",

                    "& .MuiSpeedDial-root": {
                      pointerEvents: "none",
                    },
                    "& .MuiSpeedDial-fab": {
                      backgroundColor:
                        selectedTool === "lineMeasure" ||
                        selectedTool === "polylineMeasure" ||
                        selectedTool === "polygonMeasure"
                          ? "#48b"
                          : "rgba(20,26,34,.725)",
                      boxShadow: "0 0 1.5px rgba(255,255,255,.7)",
                      backdropFilter: "blur(20px)",
                      "&:hover": {
                        backgroundColor: hoverColor,
                      },
                      height: 40,
                      width: 40,
                    },
                  }}
                  onClick={() => {
                    if (
                      selectedTool === "lineMeasure" ||
                      selectedTool === "polylineMeasure" ||
                      selectedTool === "polygonMeasure"
                    ) {
                      dispatch(gisToolActions.setselectedTool(null));
                      dispatch(gisToolActions.turnOffTools());
                      disableAllTools();
                    }
                  }}
                >
                  {userPermissions.includes("measurement_line_view") && (
                    <SpeedDialAction
                      key="Measure Line"
                      icon={
                        <img src={lineIco} className="w-6" alt="Measure Line" />
                      }
                      arrow
                      tooltipTitle="Measure Line"
                      sx={{
                        backgroundColor: "rgba(20,26,34,.725)",
                        "&:hover": {
                          backgroundColor: hoverColor,
                        },
                        boxShadow: "none",
                      }}
                      onClick={() => {
                        dispatch(gisToolActions.setselectedTool("lineMeasure"));
                        dispatch(gisToolActions.toggleTools("lineMeasureTool"));
                        handleLineCreated();
                      }}
                    />
                  )}

                  {userPermissions.includes("measurement_polyline_view") && (
                    <SpeedDialAction
                      key="Measure Distance"
                      icon={
                        <img
                          src={polylineIco}
                          className="w-6"
                          alt="Measure Distance"
                        />
                      }
                      arrow
                      tooltipTitle="Measure Distance"
                      onClick={() => {
                        dispatch(
                          gisToolActions.setselectedTool("polylineMeasure")
                        );
                        dispatch(
                          gisToolActions.toggleTools("polylineMeasureTool")
                        );
                        handlePolylineMeasureClick();
                      }}
                      sx={{
                        backgroundColor: "rgba(20,26,34,.725)",
                        "&:hover": {
                          backgroundColor: hoverColor,
                        },
                        boxShadow: "none",
                      }}
                    />
                  )}
                  {userPermissions.includes("measurement_polygon_view") && (
                    <SpeedDialAction
                      key="Measure Area"
                      icon={
                        <img
                          src={polygonIco}
                          className="w-6"
                          alt="Measure Area"
                        />
                      }
                      arrow
                      tooltipTitle="Measure Area"
                      onClick={() => {
                        dispatch(
                          gisToolActions.setselectedTool("polygonMeasure")
                        );
                        dispatch(
                          gisToolActions.toggleTools("polygonMeasureTool")
                        );
                        handlePolygonMeasureClick();
                      }}
                      sx={{
                        backgroundColor: "rgba(20,26,34,.725)",
                        "&:hover": {
                          backgroundColor: hoverColor,
                        },
                        boxShadow: "none",
                      }}
                    />
                  )}
                </SpeedDial>
              </div>
            )}
          </div>
        )}
        <Toaster />

        {asset !== "" && (
          <>
            <div className="bottomToolbar absolute bottom-8 right-2 z-[500]  flex flex-col gap-2 ">
              <div className="unitsSelector  flex-row-reverse relative gap-2 hidden">
                {!unitsIsOpen ? (
                  <Tooltip title="Unit selector" arrow placement="left">
                    <button
                      className={`w-7 h-7 flex items-center justify-center  text-white bg-[rgba(20,26,34,.725)] border rounded   ${
                        unitsIsOpen
                          ? "border bg-[#48b] rounded"
                          : `hover:bg-[${hoverColor}]`
                      }`}
                      onClick={() => {
                        dispatch(gisToolActions.setUnitIsOpen());
                      }}
                    >
                      <img
                        className="w-6"
                        src={measuringTapeIco}
                        alt=""
                        srcSet=""
                      />
                    </button>
                  </Tooltip>
                ) : (
                  <button
                    className={`w-7 h-7 flex items-center justify-center  text-white bg-[rgba(20,26,34,.725)] border rounded   ${
                      unitsIsOpen
                        ? "border bg-[#48b] rounded"
                        : `hover:bg-[${hoverColor}]`
                    }`}
                    onClick={() => {
                      dispatch(gisToolActions.setUnitIsOpen());
                    }}
                  >
                    <img
                      className="w-6"
                      src={measuringTapeIco}
                      alt=""
                      srcSet=""
                    />
                  </button>
                )}
                {unitsIsOpen && (
                  <div className="  absolute right-8 bottom-1  bg-white p-2 rounded-sm flex flex-col gap-2 bg-[#a0aab4bb]">
                    <div className="flex gap-2 justify-between items-center">
                      <p>Length</p>{" "}
                      <select
                        className={`text-xs !py-0 w-max h-7 text-white bg-[rgba(20,26,34,.725)] border rounded hover:bg-[${hoverColor}] relative z-10`}
                        value={selectedMeasurmentUnit}
                        onChange={(e) => handleUnitChange(e.target.value)}
                      >
                        <option className=" text-xs" value="m">
                          m
                        </option>
                        <option className=" text-xs" value="km">
                          km
                        </option>
                        <option className=" text-xs" value="ft">
                          ft
                        </option>
                      </select>
                    </div>
                    <div className="flex justify-between items-center">
                      <p>Area</p>
                      <select
                        className={`text-xs !py-0 w-max h-7 text-white bg-[rgba(20,26,34,.725)] border rounded hover:bg-[${hoverColor}] relative z-10`}
                        value={selectedAreaUnit}
                        onChange={(e) => handleAreaUnitChange(e.target.value)}
                      >
                        <option className=" text-xs" value="m">
                          sq m
                        </option>
                        <option className=" text-xs" value="km">
                          sq km
                        </option>
                        <option className=" text-xs" value="ft">
                          sq ft
                        </option>
                        <option className=" text-xs" value="acer">
                          acre
                        </option>
                        <option className=" text-xs" value="hector">
                          hector
                        </option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
              <div className="zoomController  flex flex-col text-white bg-[rgba(20,26,34,.725)] rounded-[20px] w-10 h-20 ">
                <button
                  className="hover:bg-[rgba(20,26,34)] rounded-t-[20px] w-10 h-10 flex justify-center items-center"
                  onClick={() => handleZoom("in")}
                >
                  <img
                    className="w-6 h-6 mt-2"
                    src={ZoomInIcon}
                    alt="zoom in"
                  />
                </button>
                <button
                  className="hover:bg-[rgba(20,26,34)] rounded-b-[20px] w-10 h-10 flex justify-center items-center"
                  onClick={() => handleZoom("out")}
                >
                  <img
                    className="w-6 h-6 mt-[-4px]"
                    src={ZoomOutIcon}
                    alt="zoom out"
                  />
                </button>
              </div>
              {imageryLabel !== "" &&
                (siteName === "Site B" ? (
                  <Tooltip
                    title={mode === "2D" ? "Switch to 3D" : "Switch to 2D"}
                    arrow
                    placement="left"
                  >
                    <button
                      onClick={() => {
                        dispatch(gisHomeActions.setMode());
                      }}
                      className={`w-10 h-10 rounded-[50%] text-white bg-[rgba(20,26,34,.725)] hover:bg-[${hoverColor}] font-semibold `}
                    >
                      {mode}
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Orthographic View" arrow placement="left">
                    <button
                      className={`w-10 h-10 rounded-[50%]  text-white bg-[rgba(20,26,34,.725)] hover:bg-[${hoverColor}]`}
                    >
                      <h2 className=" font-bold">2D</h2>
                    </button>
                  </Tooltip>
                ))}
            </div>
          </>
        )}
      </div>
      {isModalOpenCustom && (
        <MetaInputLeaflet
          isOpen={isModalOpenCustom}
          onClose={() => {
            setIsModalOpenCustom(false);
          }}
          onSave={handleMarkerSave}
          markerLayer={markerLayerCurrent}
          map={map}
        />
      )}
    </>
  );
};

export default GISToolbarLeaflet;
