import "./App.css";
import "leaflet/dist/leaflet.css";
import "../src/Assets/styles/pageStyle.scss";
import { Route, Routes } from "react-router-dom";
import {
  ServicePage,
  Login,
  Signup,
  Projects,
  ProjectViewLayout,
  Assets,
  SingleScreen,
  CompareScreen,
} from "./imports";
import Thankyou from "./Pages/Home/Thankyou";
import PipelineInfraLogin from "./Pages/Login/PipelineInfraLogin";
import AGPLogin from "./Pages/Login/AGPLogin";
import Admin from "./Components/Admin/Admin";
import GISHomeLeaflet from "./Pages/GIS/GISHomeLeaflet";
import AdminLayout from "./Components/Admin/AdminLayout";
import Permissions from "./Components/Admin/Permissions";
import RolePermission from "./Components/Admin/RolePermission";
import Settings from "./Components/Admin/Settings";
import ErrorPage from "./Pages/Error/ErrorPage";

function App() {
  return (
    <div className="App h-full overflowhidden">
      <Routes>
        <Route path="/" element={<ServicePage />}></Route>
        {/* <Route path="/PIL" element={<PipelineInfraLogin />}></Route> */}
        {/* <Route path="/AGPLogin" element={<AGPLogin />}></Route> */}

        <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route element={<ProjectViewLayout />}>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/assets" element={<Assets />}></Route>
        </Route>
        <Route path="gis/:grp" element={<GISHomeLeaflet />}></Route>
        {/* <Route path="gis/leaflet" element={<GISHomeLeaflet />}></Route> */}
        <Route path="surveillance/:project" element={<SingleScreen />}></Route>
        <Route path="compare/:ids" element={<CompareScreen />}></Route>
        <Route path="/nhai" element={<Thankyou />} />
        {/* <Route path="/admin" element={<Admin />} /> */}
        <Route element={<AdminLayout />}>
          <Route path="/setting" element={<Settings />} />
          <Route path="/perms" element={<Permissions />} />
          <Route path="/role_perms" element={<RolePermission />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
