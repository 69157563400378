import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

const ImageSlider = ({ images }) => {
  const imagesWithDate = images?.map((marker) => {
    return {
      url: `https://d2boh9caghjgas.cloudfront.net/${marker.path}`,
      date: marker.date,
    };
  });

  const [imageDate, setImageDate] = React.useState(imagesWithDate[0].date);

  const handleStartSlide = (index) => {
    // Check if the index is within the bounds of the array
    if (index >= 0 && index < imagesWithDate.length + 1) {
      setImageDate(imagesWithDate[index - 1].date);
    } else {
      setImageDate("Date not available");
    }
  };

  return (
    <div className="w-full image-slider relative">
      <SimpleImageSlider
        showNavs={true}
        useGPURender={false}
        width="100%"
        style={{ position: "relative" }}
        height={180}
        images={imagesWithDate}
        onStartSlide={(index) => handleStartSlide(index)}
      />
      <div className="absolute bottom-[-1px] right-0 z-[999] bg-white p-1 border-b border-r">
        {imageDate}
      </div>
    </div>
  );
};

export default ImageSlider;
