import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../Assets/styles/GISStyle.css";
import { useDispatch, useSelector } from "react-redux";
import X2JS from "x2js";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import { addIco } from "../../../Assets/icons/gisIcons/icons";
import "../../../Assets/styles/GISStyle.css";
import { HMELIndeixngData } from "../../../Data/config/ProjectConfig";

// const segregateAgpAssets = (objectsArray, keyword) => {
//   const Assets = [];
//   objectsArray.forEach((obj) => {
//     if (obj.Name.includes(keyword)) {
//       const dateMatch = obj.Name.match(/\d{2}_\d{2}_\d{4}/);
//       if (dateMatch) {
//         const date = dateMatch[0];
//         const agpObject = { date, ...obj };
//         Assets.push(agpObject);
//       } else {
//         Assets.push(obj);
//       }
//     }
//   });
//   return Assets;
// };
const segregateAssets = (objectsArray) => {
  const Assets = [];
  objectsArray.forEach((obj) => {
    const dateMatch = obj.Name.match(/\d{2}_\d{2}_\d{4}/);
    if (dateMatch) {
      const date = dateMatch[0];
      const agpObject = { date, ...obj };
      Assets.push(agpObject);
    } else {
      Assets.push(obj);
    }
  });
  return Assets;
};

const IndexingLeaflet = (props) => {
  const [assetData, setAssetData] = React.useState([]);
  const { grp: clientName } = useParams();

  const [selectedAnnotation, setSelectedAnnotation] = React.useState(null);
  const [isReportModalOpen, setIsReportModalOpen] = React.useState(false);
  const [isProgressReportModalOpen, setIsProgressReportModalOpen] =
    React.useState(false);
  const [isAssetModalOpen, setIsAssetModalOpen] = React.useState(false);
  const [elementWidth, setElementWidth] = useState(null);
  const [openDropdownMap, setOpenDropdownMap] = useState({});
  const [openDropdownOfAssetProp, setOpenDropdownOfAssetProp] = useState({});

  const dispatch = useDispatch();
  const selectedAsset = useSelector((state) => state.gisLeaflet.asset);
  const showComparer = useSelector((state) => state.gisTools.showComparer);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadingId, setLoadingId] = React.useState(null);

  const [opacity, setOpacity] = useState(1);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [statusAnchorEl, setSAnchorEl] = useState(null);
  const statusOpen = Boolean(anchorEl);
  const statusId = statusOpen ? "simple-popper" : undefined;

  const handleStatusClick = (id) => {
    setLoadingId(id);
    // setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleOpenModalProgress = () => {
    setIsProgressReportModalOpen(true);
    setIsReportModalOpen(false);
  };

  const handleOpenModalAsset = () => {
    setIsProgressReportModalOpen(false);
    setIsReportModalOpen(false);
    setIsAssetModalOpen(true);
  };

  const handleOpenModal = (annotation) => {
    setSelectedAnnotation(annotation);
    setIsReportModalOpen(true);
    setIsProgressReportModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsReportModalOpen(false);
  };

  const handleCloseModalAsset = () => {
    setIsAssetModalOpen(false);
  };

  const handleCloseModalProgress = () => {
    setIsProgressReportModalOpen(false);
  };

  useEffect(() => {
    const geoServerClientReferenace = clientName.toLowerCase();
    const wmsUrl = `https://gis.indrones.com/geoserver/${clientName}/wms`;

    // Step 1: GetLayers
    const getCapabilities = async () => {
      const response = await axios.get(
        `https://gis.indrones.com/geoserver/${geoServerClientReferenace}/wms?request=GetCapabilities`
      );

      const x2js = new X2JS();
      const jsonObj = x2js.xml2js(response.data);
      // console.log(jsonObj, "jsonobj");
      const layers = jsonObj.WMS_Capabilities.Capability.Layer.Layer;
      const groupedData = segregateAssets(layers);
      setAssetData([]);
      setAssetData(groupedData);
      // dispatch(gisLeafletActions.setAsset(groupedData[0]));
      const setDefaultAsset = groupedData.find(
        (asset) => asset.Name === "HMEL_ORTHO_14-04-2024_355.8_TO_364.8_COG"
      );
      dispatch(gisLeafletActions.setAsset(setDefaultAsset));
    };
    getCapabilities();
  }, []);

  function convertMixedData(originalData) {
    const formattedData = [];

    // Group data by date
    const groupedData = originalData.reduce((acc, entry) => {
      const date = entry.split("_")[2];
      acc[date] = acc[date] || [];
      acc[date].push(entry);
      return acc;
    }, {});

    // Process each date group
    Object.keys(groupedData).forEach((date) => {
      const entries = groupedData[date];
      const sections = {};

      // Organize entries into sections by start chainage
      entries.forEach((entry) => {
        const parts = entry.split("_");
        const startChainage = parseFloat(parts[3]);
        sections[startChainage] = sections[startChainage] || [];
        sections[startChainage].push(entry);
      });

      // Convert sections to formatted data and sort them by start chainage
      Object.keys(sections)
        .sort((a, b) => parseFloat(a) - parseFloat(b)) // Sort sections by start chainage
        .forEach((startChainage) => {
          const entriesInSection = sections[startChainage];
          const endChainage = parseFloat(entriesInSection[0].split("_")[5]);
          const title = `${startChainage}km to ${endChainage}km`;
          const section = {
            startChainage,
            endChainage,
            title,
          };
          const assets = entriesInSection.map((entry) => ({
            id: entry,
            type: entry.startsWith("HMEL_DSM") ? "DSM" : "ORI",
          }));
          formattedData.push({ section, assets });
        });
    });

    return formattedData;
  }

  const logNames = (assetData) => {
    // console.log(assetData);
    let assetNameArry = assetData.map((asset) => asset.Name);
    // console.log(assetNameArry);
    return assetNameArry;
  };

  const dataToPass = logNames(assetData);
  // const formattedData = convertMixedData(dataToPass);

  const toggleDropdown = (assetId) => {
    setOpenDropdownMap((prevState) => {
      const updatedState = { ...prevState };

      Object.keys(updatedState).forEach((id) => {
        updatedState[id] = false;
      });

      updatedState[assetId] = !prevState[assetId];

      return updatedState;
    });
  };

  const toggleDropdownOfAssetProp = (id) => {
    setOpenDropdownOfAssetProp((prevState) => {
      const updatedState = { ...prevState };

      Object.keys(updatedState).forEach((assetPropId) => {
        updatedState[assetPropId] = false;
      });

      updatedState[id] = !prevState[id];

      return updatedState;
    });
  };

  // const deleteTimeline = async (id) => {
  //   try {
  //     const response = await axios.delete(
  //       `https://api.cesium.com/v1/assets/${id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${props.token}`,
  //         },
  //       }
  //     );
  //     console.log(response.data, "Deleted");
  //     toast.success("Timeline Deleted!!");

  //     dispatch(gisActions.refCounterUpdate());
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // console.log("assetDatass", assetData);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const selectedAssetEquals = (assetTitle) => {
    if (assetTitle !== selectedAsset?.Title) {
      return false;
    } else if (selectedAsset?.Title === undefined) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    dataToPass?.map((asset) => {
      asset !== null &&
        dispatch(gisLeafletActions.setAvaliableRasterAssets(asset));
    });
  }, [assetData]);

  // const indexingType = "timeline"; // sectional
  // if (indexingType === "sectional") {
  //   return (
  //     <SectionsIndexing
  //       openAssetIndexing={props.openAssetIndexing}
  //       assetData={assetData}
  //     />
  //   );
  // }

  return (
    <div
      className={`${
        props.openAssetIndexing ? "block" : "hidden"
      }  relative rounded-tl-md rounded-tr-md min-w-[18rem] !grow border-solid border-[#d7d8d8] border-[1px] bg-[#fff] mr-2 ml-2 mb-2 mt-2  overflow-x-hidden`}
    >
      <div className="w-full rounded-tl-[1px] rounded-tr-[1px] flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
        <div className="grow flex items-center justify-center">Sections</div>
      </div>
      <div className="overflow-x-hidden">
        {HMELIndeixngData?.map((asset, index) => {
          return (
            <Accordion
              key={asset?.section?.title + 1 + index}
              className={`text-sm !my-0 !p-0 shadow-none `}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="flex !items-center !justify-center min-h-max !m-0"
              >
                <p className="text-sm">{asset?.section?.title}</p>
              </AccordionSummary>
              <AccordionDetails className="!m-0 !p-2 grow w-full">
                {asset.assets.map((asset, index) => (
                  <Tooltip
                    placement="right"
                    key={asset?.id + index}
                    title={"Click to add "}
                  >
                    <div
                      className={` flex grow  my-2 hover:bg-[#ffea00b4] py-1 rounded-md   items-center ${
                        selectedAsset?.Name === asset?.id
                          ? "bg-[#ecfc61]"
                          : showComparer === true
                          ? "bg-white"
                          : ""
                      }`}
                      key={index}
                    >
                      <div
                        className={`w-full  cursor-pointer px-2 pr-[10px] flex justify-between items-center`}
                        onClick={() => {
                          const assetToSet = assetData.find(
                            (item) => item?.Name === asset.id
                          );
                          dispatch(gisLeafletActions.setAsset(assetToSet));
                        }}
                      >
                        <p
                          className={`pl-4 text-sm ${
                            selectedAsset?.Name === asset.name
                              ? null
                              : "border-l-2"
                          }`}
                        >
                          {asset.type}
                        </p>
                        <span className=" text-xs">
                          {/* {asset?.chainage.start} km
                          {asset?.chainage.end !== null &&
                            ` to ${asset.chainage.end} km`} */}
                        </span>

                        <img
                          src={addIco}
                          className="h-5 border-2 my-auto border-[#808080] rounded-full"
                          alt=""
                          srcSet=""
                        />
                      </div>
                    </div>
                  </Tooltip>
                ))}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      {/* {Object.keys(assetData).map((category) => (
        <div className="category" key={category}>
          {assetData.map((asset, index) => (
            <div className="flex grow " key={index}>
              <div
                className={`box-showl flex grow flex-col  ${
                  universalAsset === asset.id
                    ? "bg-[#f0f8ff]"
                    : showComparer === true
                    ? "bg-white"
                    : ""
                }`}
              >
                {asset.type === "IMAGERY" && (
                  <>
                    <div className="flex relative">
                      {showComparer && (
                        <Checkbox
                          color="default"
                          size="small"
                          id={asset.id}
                          checked={compareAssets.includes(asset.id)}
                          disabled={
                            compareAssets.includes(asset.id) &&
                            compareAssets.length > 3
                          }
                          onChange={() => props.compareAssetsArray(asset.id)}
                        />
                      )}
                      <div
                        className={`w-full m-1 cursor-pointer py-1 px-2 ${
                          (asset.status === "IN_PROGRESS" ||
                            asset.status === "NOT_STARTED") &&
                          "text-gray-500"
                        }      `}
                        onClick={() => {
                          asset.status === "IN_PROGRESS" ||
                          asset.status === "NOT_STARTED"
                            ? toast.error("Tiling is in process.")
                            : dispatch(
                                gisHomeActions.setAsset({
                                  asset: asset.id,
                                  assetType: asset.type,
                                })
                              );
                        }}
                      >
                        {asset.date
                          ? getDate(asset.date)
                          : asset.name.split("_").join(" ")}
                      </div>

                      <button
                        onClick={() => {
                          toggleDropdown(asset.id);
                          handleStatusClick(asset.id);
                        }}
                        className={
                          openDropdownMap[asset.id] ? "rotated mr-2" : "mr-2"
                        }
                      >
                        <img
                          src={chevronIcoDropdown}
                          alt=""
                          className="h-8"
                          srcSet=""
                        />
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      ))} */}
    </div>
  );
};

export default IndexingLeaflet;
