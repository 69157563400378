import React from "react";
import { SketchPicker } from "react-color";

const Settings = () => {
  const [color, setColor] = React.useState("#000000"); // Initial color, here it's black

  const handleChange = (event) => {
    setColor(event.target.value); // Update state with the newly selected color
  };

  return (
    <>
      <div className="w-[95%] h-full">
        <div className="mt-5 flex flex-col items-start gap-5">
          <div>Measurement - Severity</div>
          <div className="flex">
            <div className="flex items-center">
              <label>Low</label>
              <input type="color" value={color} onChange={handleChange} />
            </div>
            <div className="flex items-center">
              <label>Medium</label>
              <input type="color" value={color} onChange={handleChange} />
            </div>
            <div className="flex items-center">
              <label>High</label>
              <input type="color" value={color} onChange={handleChange} />
            </div>
          </div>
        </div>

        {/* <div className="flex mt-5 items-center gap-5">
          <div>Comments</div>
          <div className="flex">
            <div className="flex items-center">
              <label>View</label>
            </div>
            <div className="flex items-center">
              <label>Add</label>
            </div>
            <div className="flex items-center">
              <label>Edit</label>
            </div>
            <div className="flex items-center">
              <label>Delete</label>
            </div>
          </div>
        </div> */}

        <div className="flex items-center w-full justify-center gap-5 mt-10">
          <div
            //   onClick={updatePermissions}
            className="cursor-pointer w-max  !rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
          >
            Save
          </div>
          <div
            //   onClick={() => setReloader(reloader + 1)}
            className="cursor-pointer w-max !rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
          >
            Reset
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
