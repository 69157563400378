import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Slider from "@mui/material/Slider";
import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  visibilityIco,
  visibilityOffIco,
} from "../../../Assets/icons/gisIcons/icons";
import { observationCategories } from "../../../Data/config/ProjectConfig";
import { AnnotationManager } from "../../../Services/ThirdPartyApi/AWS/DynamoDBL";
import { MarkerClass } from "../../../Utils/Leaflet/LeafletUtils";
import { gisLeafletActions } from "../../../redux/slices/GIS/gis-leaflet-slice";
import { gisActions } from "../../../redux/slices/GIS/gis-slice";
import { AnnotationReport } from "../AnnotationReport";
const AnnotationsLeaflet = ({ openObservation, mapRef }) => {
  const markerClass = new MarkerClass();
  const dispatch = useDispatch();
  const { allObservations } = useSelector((state) => state.gis);
  const { observationsBucket, observationsVisibilityStatus } = useSelector(
    (state) => state.gisLeaflet
  );
  const [categoryFilter, setCategoryFilter] = useState("");
  const [severityFilter, setSeverityFilter] = useState("");
  const [openAnnoReport, setOpenAnnoReport] = useState(null);
  const [markerData, setMarkerData] = useState([]);
  const [finalFilteredAnnotations, setFinalFilteredAnnotations] =
    useState(observationsBucket);

  const severities = ["All", "Low", "Medium", "High"];
  const assetId = useSelector((state) => state.gisLeaflet.asset);

  const [assetFilter, setAssetFilter] = useState(null);

  useEffect(() => {
    if (assetId) {
      setAssetFilter(assetId);
    }
  }, [assetId]);

  useEffect(() => {
    setMarkerData(observationsBucket);
    setFinalFilteredAnnotations([]);
    setFinalFilteredAnnotations(observationsBucket);
  }, [observationsBucket]);

  useEffect(() => {
    setFinalFilteredAnnotations(
      categoryFilter === "" && severityFilter === ""
        ? markerData
        : markerData.filter(
            (observation) =>
              (categoryFilter === "" ||
                observation.category === categoryFilter) &&
              (severityFilter === "" || observation.severity === severityFilter)
          )
    );
  }, [allObservations, categoryFilter, severityFilter]);
  // console.log(finalFilteredAnnotations, "finalFilteredAnnotations");
  // useEffect(() => {
  //   markerData?.forEach((element) => {
  //     const layer = getLayerById(element.id);
  //     // Do something with 'layer'
  //     console.log("layer in effect",layer);
  //   });
  // }, [markerData]);
  // const handleReportClick = (annotation) => {
  //   setOpenAnnoReport(true);
  // };

  const handleCloseModal = () => {
    setOpenAnnoReport(null);
  };

  const handleChange = (name) => {
    if (name === "Low") {
      return "bg-yellow-200 border border-yellow-500 text-black";
    } else if (name === "Medium") {
      return "bg-orange-200 border border-orange-500 text-black";
    } else if (name === "High") {
      return "bg-red-200 border border-red-500 text-black";
    } else {
      return "bg-grey-200 border border-grey-500 text-black";
    }
  };

  const [elementWidth, setElementWidth] = useState(null);

  const map = mapRef.current;
  const [expanded, setExpanded] = useState(false);
  const handleAccordionToggle = (panelIndex) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelIndex : false);
  };
  const [fillOpacityVal, setFillOpacity] = useState(0.5);

  const [editMode, setEditMode] = useState(false);
  const [showMeasureEditButtonsArray, setShowMeasureEditButtonsArray] =
    useState(markerData ? markerData?.map(() => false) : [false]);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [selectedLayerIndex, setSelectedLayerIndex] = useState(null);
  const editCtrl = useRef(null); // Ref to hold the editCtrl object

  // Function to get layer by ID
  const getLayerById = (id) => {
    let targetLayer = null;
    map.eachLayer((layer) => {
      if (layer._leaflet_id === id) {
        targetLayer = layer;
      }
    });
    return targetLayer;
  };

  const updateMarkerData = (index, layer) => {
    // Get the marker from markerData using the provided index
    const markerToUpdate = markerData[index];
    // Update the marker position based on its type
    if (
      markerToUpdate?.filter_type === "polygon" ||
      markerToUpdate?.filter_type === "polyline"
    ) {
      // markerToUpdate.markerPosition = layer?.getLatLngs();

      const key = {
        id: markerToUpdate.id,
        timestamp: markerToUpdate.timestamp,
      };

      const fieldsToUpdate = {
        markerPosition: layer?.getLatLngs(),
      };

      // AnnotationManager.editAnnotations(key, fieldsToUpdate);
      AnnotationManager.editShape(key, fieldsToUpdate);

      markerClass.hnadleMarkerLabel(layer, markerToUpdate.markerName, map);
    } else {
      const key = {
        id: markerToUpdate.id,
        timestamp: markerToUpdate.timestamp,
      };
      const fieldsToUpdate = {
        markerPosition: layer?.getLatLng(),
      };

      // AnnotationManager.editAnnotations(key, fieldsToUpdate);
      AnnotationManager.editShape(key, fieldsToUpdate);
    }
    map.removeLayer(layer);

    dispatch(gisActions.refCounterUpdate());
  };

  const handleEditClick = (marker, index) => () => {
    const id = marker.id;

    const layer = getLayerById(id);
    if (marker.markerPosition) {
      const bounds = L.latLngBounds([marker.markerPosition]);

      map?.flyToBounds(bounds, {
        duration: 2,
        easeLinearity: 0.5,
        minZoom: 15,
      });
    }

    var mulitiEdit = false;

    if (selectedLayer !== layer && selectedLayer && editMode) {
      handleSaveEdit(selectedLayerIndex);
      mulitiEdit = true;
    }
    setSelectedLayer(layer); // Set the selected layer for editing
    setSelectedLayerIndex(index);
    // if (layer.type !== "marker") {
    //   map.removeLayer(layer.label); //for label
    // }

    if (layer) {
      // Toggle the edit mode
      setEditMode((prevEditMode) => !prevEditMode);
      // Show the edit buttons
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = !newArray[index];
        return newArray;
      });
      // Filter out the selected layer and create a new feature group
      const selectedFeatureGroup = L.featureGroup([layer]);
      // Initialize the edit control with the new feature group if it's not initialized
      if (!editCtrl.current) {
        const editCtrlOptions = {
          featureGroup: selectedFeatureGroup,
        };
        editCtrl.current = new L.EditToolbar.Edit(map, editCtrlOptions);
      }
      // Enable or disable the edit functionality based on the edit mode
      if (!editMode || mulitiEdit) {
        editCtrl.current.enable();
      } else {
        setShowMeasureEditButtonsArray((prevArray) => {
          const newArray = [...prevArray];
          newArray[index] = false;
          return newArray;
        });
        editCtrl.current.disable();

        // updateMarkerData(index, selectedLayer);
        // Hide the edit buttons
        // setSelectedLayer(null);
        // editCtrl.current = null;
      }
    }
  };

  const handleSaveEdit = (index) => {
    if (selectedLayer) {
      if (editCtrl.current) {
        // Check if editCtrl is initialized
        editCtrl.current.save();
        editCtrl.current.disable(); // Disable the edit control
      }

      updateMarkerData(index, selectedLayer);
      setEditMode((prevEditMode) => !prevEditMode); // Disable edit mode
      // Hide the edit buttons
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = !newArray[index];
        return newArray;
      });
      setSelectedLayer(null);
      editCtrl.current = null;
    }
  };

  const handleCancelEdit = (index) => {
    if (editMode && selectedLayer) {
      if (editCtrl.current) {
        // Check if editCtrl is initialized
        editCtrl.current.revertLayers();
        editCtrl.current.disable(); // Disable the edit control
      }
      updateMarkerData(index, selectedLayer);
      setEditMode(false); // Disable edit mode
      // Hide the edit buttons
      setShowMeasureEditButtonsArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = false;
        return newArray;
      });
      setSelectedLayer(null);
      editCtrl.current = null;
    }
  };

  // const handleOpacityChange = (id, OvalueChange) => {
  //   setFillOpacity(OvalueChange);
  //   const markerLayer = getLayerById(id);
  //   // console.log("layer check", markerLayer);
  //   // If the marker layer exists
  //   if (markerLayer) {
  //     // Determine the type of the layer
  //     if (markerLayer.type === "marker") {
  //       // Handle marker
  //       markerLayer.setOpacity(OvalueChange);
  //     } else if (markerLayer.type === "polyline") {
  //       // Handle polyline or polygon
  //       markerLayer.setStyle({ opacity: OvalueChange });
  //     } else if (markerLayer.type === "polygon") {
  //       let fillINOpacity;
  //       if (OvalueChange > 0.2) {
  //         fillINOpacity = 0.2;
  //       } else {
  //         fillINOpacity = OvalueChange;
  //       }
  //       // console.log("polygon opacity called");
  //       markerLayer.setStyle({
  //         fillOpacity: fillINOpacity, // Set fill opacity
  //         opacity: OvalueChange, // Set border opacity
  //       });
  //     } else {
  //       // console.log("Unknown layer type");
  //     }
  //     // Add the modified layer back to the map
  //     // markerLayer.addTo(map);
  //   } else {
  //     // console.log("Layer not found");
  //   }
  // };

  const [opacityValues, setOpacityValues] = useState({});

  const handleOpacityChange = (id, opacityChange) => {
    setOpacityValues((prevState) => ({
      ...prevState,
      [id]: opacityChange,
    }));

    const markerLayer = getLayerById(id);
    if (markerLayer) {
      if (markerLayer.type === "polyline") {
        markerLayer.setStyle({ opacity: opacityChange });
      } else if (markerLayer.type === "polygon") {
        let fillOpacity;
        if (opacityChange > 0.2) {
          fillOpacity = 0.2;
        } else {
          fillOpacity = opacityChange;
        }
        markerLayer.setStyle({
          fillOpacity: fillOpacity,
          opacity: opacityChange,
        });
      } else if (markerLayer?.type === "marker") {
        markerLayer.setOpacity(opacityChange);
        // markerLayer?.eachLayer(function (layer) {
        //   if (layer instanceof L.Marker) {
        //     layer.setOpacity(opacityChange);
        //   }
        // });
      }
    }
  };

  const toggleMarkerVisibility = (marker) => {
    // Toggle the visibility of the marker
    const updatedObservations = observationsVisibilityStatus.map(
      (observation) => {
        if (observation.id === marker.id) {
          // If the observation ID matches the marker ID, toggle its visibility
          return {
            id: observation.id,
            visible: !observation.visible,
          };
        } else {
          // Otherwise, keep the observation unchanged
          return observation;
        }
      }
    );

    // Dispatch an action to update the observationsVisibilityStatus
    dispatch(
      gisLeafletActions.setObservationsVisibilityStatus(updatedObservations)
    );
  };
  const calLength = (marker) => {
    const latLngs = marker.markerPosition; // Get LatLng coordinates of the layer
    const latLngArray = latLngs.map((latlng) =>
      L.latLng(latlng.lat, latlng.lng)
    ); // Convert coordinates to L.LatLng objects
    const length = L.GeometryUtil.length(latLngArray);
    return length;
  };
  const calArea = (marker) => {
    const areaInSquareMeters = L.GeometryUtil.geodesicArea(
      marker.markerPosition[0]
    );
    return areaInSquareMeters;
  };
  const calPerimeter = (marker) => {
    const latLngs = marker.markerPosition; // Get LatLng coordinates of the layer
    const latLngArray = latLngs[0].map((latlng) =>
      L.latLng(latlng.lat, latlng.lng)
    ); // Convert coordinates to L.LatLng objects
    const length = L.GeometryUtil.length(latLngArray);
    // console.log("latlng og",latLngs,"latlng mapped",latLngArray);
    return length;
  };

  return (
    <>
      <div
        className={`${
          openObservation ? "block" : "hidden"
        } relative rounded-md min-w-[18rem]  !grow h-screen overflow-y-auto overflow-x-hidden mr-2 ml-2 mb-2 mt-2 border-solid border-[#d7d8d8] border-[1px] bg-white`}
      >
        <div className="w-full sticky rounded-tl-md rounded-tr-md flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]  ">
          <div className="grow flex items-center justify-center">
            Observations
          </div>
        </div>
        <div className="flex sticky gap-4 flex-col p-2  items-center overflow-hidden border-b border-gray-200">
          <div className="flex flex-col w-full relative">
            <label className="text-xs absolute -top-2 left-2 bg-white rounded-sm pl-1 pr-2 mb-1">
              Category
            </label>
            <select
              className="border border-gray-400 rounded text-xs"
              value={categoryFilter}
              onChange={(e) =>
                e.target.value === "All"
                  ? setCategoryFilter("")
                  : setCategoryFilter(e.target.value)
              }
            >
              <option key="all" value="All">
                All
              </option>
              {observationCategories?.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col w-full relative">
            <label className="text-xs absolute -top-2 left-2 bg-white rounded-sm  pl-1 pr-2">
              Severity
            </label>
            <select
              className="border border-gray-400 rounded text-xs"
              value={severityFilter}
              onChange={(e) => {
                e.target.value === "All"
                  ? setSeverityFilter("")
                  : setSeverityFilter(e.target.value);
              }}
            >
              {severities?.map((severity, index) => (
                <option key={index} value={severity}>
                  {severity}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* <Tooltip title="Generate Annotation Report" placement="left" arrow>
          <div className="h-5 flex w-full justify-end">
            <img
              className="h-5 mr-3"
              src={icon}
              alt="!"
              onClick={() => handleReportClick()}
            />
          </div>
        </Tooltip> */}
        {finalFilteredAnnotations?.length > 0 ? (
          <div className="flex-1 overflow-x-hidden">
            {finalFilteredAnnotations?.map((marker, index) => (
              <>
                <Accordion
                  className={`text-sm !my-0 !p-0 shadow-none ${
                    editMode ? "" : ""
                  }`}
                  key={index}
                  expanded={expanded === index}
                  onChange={handleAccordionToggle(index)}
                  disabled={editMode}
                  title={
                    editMode
                      ? "Save or cancel editing current marker first"
                      : ""
                  }
                  style={{ boxShadow: "none" }}
                >
                  <AccordionSummary
                    expandIcon={
                      observationsVisibilityStatus.find(
                        (observation) => observation.id === marker.id
                      )?.visible ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ExpandMoreIcon className=" invisible" />
                      )
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    onClick={(event) => event.stopPropagation()}
                    className="flex !items-center !justify-center min-h-max m-0 "
                  >
                    <button
                      className={`visibility-toggle-btn`}
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleMarkerVisibility(marker);
                      }}
                      disabled={showMeasureEditButtonsArray[index]}
                    >
                      {observationsVisibilityStatus.find(
                        (observation) => observation.id === marker.id
                      )?.visible ? (
                        <img
                          src={visibilityIco}
                          alt="Visible"
                          className="h-4"
                        />
                      ) : (
                        <img
                          src={visibilityOffIco}
                          alt="Hidden"
                          className="h-4"
                        />
                      )}
                    </button>

                    <div className="rounded-md ml-2 flex justify-between items-center gap-4 grow">
                      <p
                        className=""
                        key={index}
                        onClick={(event) => {
                          event.stopPropagation();

                          if (marker.markerPosition) {
                            const bounds = L.latLngBounds([
                              marker.markerPosition,
                            ]);

                            map?.flyToBounds(bounds, {
                              duration: 2,
                              easeLinearity: 0.5,
                              minZoom: 15,
                            });
                          } else {
                            console.error("Layer coordinates not available");
                          }
                        }}
                      >
                        {marker?.observationType}
                      </p>

                      <p
                        className={`text-[10px] px-2 rounded-md cursor-default w-max ${handleChange(
                          marker.severity
                        )} `}
                      >
                        {marker?.category}
                      </p>
                    </div>
                  </AccordionSummary>
                  {observationsVisibilityStatus.find(
                    (observation) => observation.id === marker.id
                  )?.visible ? (
                    <AccordionDetails
                      disabled={
                        !observationsVisibilityStatus.find(
                          (observation) => observation.id === marker.id
                        )?.visible
                      }
                      className="!mb-0 grow w-full"
                    >
                      <div className="flex flex-col text-sm">
                        <div
                          className="flex items-center justify-center
                      p-1"
                        >
                          <p className="w-[70px]">Opacity:</p>
                          <Slider
                            min={0}
                            max={1}
                            step={0.1}
                            value={opacityValues[marker.id] || 1}
                            valueLabelDisplay="auto"
                            onChange={(e) =>
                              handleOpacityChange(marker.id, e.target.value)
                            }
                            disabled={!marker.visible}
                            className="w-1/2 h-1"
                          />
                        </div>
                        <div className="flex">
                          <p className="w-[82px]">Type</p>
                          <div>: {marker.filter_type}</div>
                        </div>
                        <div className="flex">
                          <p className="w-[82px]">Severity</p>
                          <div>: {marker.severity}</div>
                        </div>
                        {marker.filter_type === "polyline" && (
                          <div className="flex">
                            <p className="w-[82px]">Length</p>
                            <div>
                              :{" "}
                              {calLength(marker) < 1000
                                ? `${calLength(marker).toFixed(2)} m`
                                : `${calLength(marker).toFixed(2)} km`}
                            </div>
                          </div>
                        )}
                        {marker.filter_type === "polygon" && (
                          <>
                            <div className="flex">
                              <p className="w-[82px]">Area</p>
                              <div>
                                :{" "}
                                {calArea(marker) < 1000000
                                  ? `${calArea(marker)?.toFixed(2)} m²`
                                  : `${(calArea(marker) / 1000000)?.toFixed(
                                      2
                                    )} km²`}
                              </div>
                            </div>
                            <div className="flex">
                              <p className="w-[82px]">Perimeter</p>
                              <div>
                                :{" "}
                                {calPerimeter(marker) < 1000
                                  ? `${calPerimeter(marker)?.toFixed(2)} m`
                                  : `${(calPerimeter(marker) / 1000)?.toFixed(
                                      2
                                    )} km`}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="flex">
                          <p className="w-[82px]">Created on</p>
                          <div>: {marker.createdOn}</div>
                        </div>
                      </div>
                      <div className="flex gap-2 items-center  justify-center mt-2">
                        {!showMeasureEditButtonsArray[index] && (
                          <button
                            className={`flex justify-center items-center text-xs  p-1 px-2  rounded-md w-auto  border my-auto cursor-pointer 
                          ${
                            showMeasureEditButtonsArray[index]
                              ? "bg-purple-400 text-white"
                              : "border-purple-400 text-purple-400 hover:bg-purple-400 hover:text-white"
                          }   ${
                              !marker.visible
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                            }`}
                            onClick={handleEditClick(marker, index)}
                            disabled={!marker.visible} // Disable slider if marker is not visible
                          >
                            <svg
                              className="w-5 h-[5.5] text-purple-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                strokeLinecap="round"
                                strokeWidth="2"
                                stroke="currentColor"
                              >
                                <path d="m20 20H4"></path>
                                <path
                                  clipRule="evenodd"
                                  fillRule="evenodd"
                                  d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                                ></path>
                              </g>
                            </svg>
                            <p className="ml-1"> Edit Shape</p>
                          </button>
                        )}

                        {showMeasureEditButtonsArray[index] && (
                          <div className="flex justify-center items-center gap-2">
                            <button
                              className="mr-[2px] text-xs  p-1 px-2  rounded-md btn btn-success btn-sm border text-white  border-green-800 bg-green-400  hover:bg-white hover:bg-opacity-50 hover:text-black"
                              onClick={() => {
                                handleSaveEdit(index);
                              }}
                            >
                              Save
                            </button>
                            <button
                              className="text-xs  p-1 px-2  rounded-md btn btn-danger btn-sm border   border-red-500 bg-white bg-opacity-50 hover:bg-opacity-100 hover:bg-red-400 hover:text-white"
                              onClick={() => {
                                handleCancelEdit(index);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  ) : null}
                </Accordion>
              </>
            ))}
          </div>
        ) : (
          <div className="text-center mb-0 text-[12px] pb-3">
            {markerData.length > 0
              ? "No observations to show"
              : finalFilteredAnnotations?.length > 0
              ? "No observations with Selected Filters"
              : "No observations to show"}
          </div>
        )}
        {/* <button
          className="border  mx-auto"
          onClick={() => onExportAnnotationToKML(exportEntityCollection)}
        >
          Export to KML
        </button> */}
        {finalFilteredAnnotations?.length !== 0 && (
          <div className=" bottom-0 text-center right-0 text-[12px] bg-gray-400 py-1">
            <p className="">
              Number of observations: {finalFilteredAnnotations?.length}
            </p>
          </div>
        )}
      </div>

      {openAnnoReport && (
        <AnnotationReport
          onClose={handleCloseModal}
          elementWidth={elementWidth}
          selectedAsset={assetFilter}
          selectedCategory={categoryFilter}
          selectedSeverity={severityFilter}
        />
      )}
    </>
  );
};

export default AnnotationsLeaflet;
