import React from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Popup,
} from "react-leaflet";
import NoBg from "../../Assets/images/my_project.png";
import L from "leaflet";
import { useSelector } from "react-redux";

const tileLayers = [
  {
    name: "Street",
    tile: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  },
  {
    name: "Satellite",
    tile: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
  },
];

const MapBox = (props) => {
  const isCompare = useSelector((state) => state.project.isCompare);

  const droneIcon = new L.Icon({
    iconUrl: NoBg,
    iconSize: [60, 60],
  });

  const latLngs = useSelector((state) => state.project.latLng);
  const latLngs2 = useSelector((state) => state.project.latLng2);

  const [loading, setLoading] = React.useState(false);
  const [markerLat, setMarkerLat] = React.useState();
  const [markerLng, setMarkerLng] = React.useState();
  const [center, setCenter] = React.useState([0, 0]);
  const [chainage, setChainage] = React.useState();
  const [currentTileLayer, setCurrentTileLayer] = React.useState(
    tileLayers[1].tile
  );
  const [buttonName, setButtonName] = React.useState(tileLayers[1].name);
  const mapRef = React.useRef();
  const currentTime = parseInt(props.currentTime);

  var path, path2;
  if (latLngs) {
    path = latLngs.map(({ lat, lon }) => [lat, lon]);
  }

  if (isCompare && latLngs2) {
    path2 = latLngs2.map(({ lat, lon }) => [lat, lon]);
  }

  React.useEffect(() => {
    if (latLngs) {
      setCenter([latLngs[0].lat, latLngs[0].lon]);
      setMarkerLat(latLngs[0].lat);
      setMarkerLng(latLngs[0].lon);
      setChainage(latLngs[0].chainage);
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [latLngs, latLngs2, isCompare]);

  React.useEffect(() => {
    var currentLatLng = latLngs?.find((latLng) => latLng.time === currentTime);
    if (currentLatLng && latLngs) {
      setMarkerLat(currentLatLng.lat);
      setMarkerLng(currentLatLng.lon);
      setChainage(currentLatLng.chainage);
    }
  }, [currentTime, latLngs]);

  React.useEffect(() => {
    const map = mapRef.current;
    if (map != null) {
      setCenter([markerLat, markerLng]);
      const bounds = map.getBounds();
      const markerPos = L.latLng(markerLat, markerLng);

      if (!bounds.contains(markerPos)) {
        map.flyTo(markerPos, map.getZoom(), { margin: [8000, 8000] });
      }
    }
  }, [markerLat, markerLng, latLngs]);

  const handleTileChange = () => {
    const newTileLayer =
      currentTileLayer === tileLayers[0].tile
        ? tileLayers[1].tile
        : tileLayers[0].tile;
    setCurrentTileLayer(newTileLayer);
    const newButtonName =
      buttonName === tileLayers[0].name
        ? tileLayers[1].name
        : tileLayers[0].name;
    setButtonName(newButtonName);
  };

  return (
    <div className="h-full w-full border-4 rounded-md relative">
      {loading && (
        <MapContainer
          ref={mapRef}
          center={center}
          zoom={15}
          scrollWheelZoom={false}
          tap={false}
          className="h-full border rounded-md"
        >
          <TileLayer
            className="h-full border rounded-md"
            url={currentTileLayer}
          />
          {isCompare && latLngs2 && (
            <Polyline
              className="h-full"
              positions={path2}
              color={"yellow"}
              weight={3}
              dashArray={[10, 10]}
            />
          )}
          <Polyline
            className="h-full"
            positions={path}
            color={"red"}
            weight={3}
          />
          <Marker icon={droneIcon} position={[markerLat, markerLng]}>
            <Popup
              autoPan={true}
              keepInView={true}
            >{`Chainage: ${chainage?.toFixed(1)}`}</Popup>
          </Marker>
        </MapContainer>
      )}
      <button
        className="absolute top-2 bg-white p-2 border-2 border-black/[.3] rounded-lg right-5 z-[999]"
        onClick={handleTileChange}
      >
        {buttonName}
      </button>
    </div>
  );
};

export default MapBox;
